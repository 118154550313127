import classNames from 'classnames/bind';
import styles from '../../Camping.module.scss';
const cx = classNames.bind(styles);
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTent } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ChoiceButton from '../ChoiceButton';
import Surcharge from './Surcharge';
function InfoItem({ datas, campIndex, adt, aris, chd }) {
    return (
        <>
            <div className={cx('section-contents')}>
                {datas?.map((item, itemIndex) => {
                    return (
                        <div key={`${campIndex}-${itemIndex}`} className={cx('section-item')}>
                            <div className={cx('head')}>
                                <strong className={cx('sub-title')}>{item.date}</strong>
                                <span>{moment(item.date).format('dd')}</span>
                                <ChoiceButton item={item.tent} campIndex={campIndex} itemIndex={itemIndex} />
                            </div>
                            <div className={cx('session-main')}>
                                {Object.keys(item.tent).length ? (
                                    <>
                                        <div className={cx('tent')}>
                                            <div className={cx('tent-row')}>
                                                <div className={cx('tent-item')}>
                                                    <div className={cx('tent-title')}>
                                                        <span className={cx('icon')}>
                                                            <FontAwesomeIcon icon={faTent} />
                                                        </span>
                                                        <span>{item.tent?.product_name}</span>
                                                    </div>
                                                    <strong className={cx('quantity')}>x {adt}</strong>
                                                    <strong className={cx('price')}>{item.tent?.display_price}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                                <Surcharge item={item} adt={adt} aris={aris} chd={chd} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
export default InfoItem;
