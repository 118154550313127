import classNames from 'classnames/bind';
import styles from '~/components/Order/Order.module.scss';
const cx = classNames.bind(styles);
import OrderItem from './OrderItem';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

function Order({ datas, method, onPayment }) {
    const { type, objId } = useParams();
    const [printKey, setPrintKey] = useState(0);
    const handleUpdate = (detailId) => {
        setOrderList((prevOrderList) => {
            const newOrderList = prevOrderList.map((obj) => {
                if (obj.detail_id === detailId) {
                    obj.status = 'done';
                }
                return obj;
            });
            return newOrderList;
        });
    };
    const handlePrint = () => {
        console.log('printer');
        const f = document.frames ? document.frames[id] : document.getElementById('iframe-ticket');
        const w = f.contentWindow || f;
        w.postMessage({ action: 'print-ticket' }, f.src);
    };

    const HandleClickPrint = (e) => {
        e.stopPropagation();
        //setPrintKey(printKey + 1);
        setTimeout(() => {
            console.log('printKey', printKey);
            handlePrint();
        }, 700);
    };
    return (
        <>
            {type === 'ticket' ? (
                <iframe
                    key={printKey}
                    id="iframe-ticket"
                    src={`/print-ticket/${objId}`}
                    style={{ display: 'none' }}
                    title="PRINT TICKET"
                />
            ) : (
                ''
            )}

            <div className={cx('orders')}>
                <div className={cx('order-row')}>
                    {datas.map((order, index) => {
                        return (
                            <OrderItem
                                key={index}
                                order={order}
                                onUpdate={handleUpdate}
                                onClickPrint={HandleClickPrint}
                                method={method}
                                onPayment={onPayment}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default Order;
