import classNames from 'classnames/bind';
import styles from '../Print.module.scss';
import { useEffect, useState } from 'react';
const cx = classNames.bind(styles);
import * as orderServices from '~/apiServices/orderServices';
import { useParams } from 'react-router-dom';
import { formatPrice, timeIn, timePrint } from '~/utils/filters';
import PrintHead from '../PrintHead';
import PrintFoot from '../PrintFoot';
function PrintBill() {
    const { tableId, type, objId } = useParams();
    const [print, setPrint] = useState({ orders: [] });
    useEffect(() => {
        const fetchApi = async () => {
            const response = await orderServices.printTable({
                params: {
                    type: type,
                    table_id: Number(tableId),
                    obj_id: Number(objId) || 0,
                },
            });
            setPrint(response);
        };
        fetchApi();
    }, []);

    const handleMessage = (event) => {
        if (event.data.action === 'print-bill') {
            window.focus();
            window.print();
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <>
            <PrintHead
                tableKey={print.table_key}
                orderId={print.code}
                time={print.created_at}
                title={'PHIẾU TÍNH TIỀN'}
            />
            <div className={cx('print-body')}>
                <table>
                    <thead>
                        <tr>
                            <th>Tên</th>
                            <th>Đ.Giá</th>
                            <th width={20} className="text-center">
                                SL
                            </th>
                            <th className="text-right">T.Tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {print.orders?.map((item, index) => {
                            const price =
                                type === 'ticket' && item.customer_phone === null && item.active
                                    ? item.ticket_price
                                    : item.product_price;
                            return (
                                <tr key={index}>
                                    <td>
                                        <span>{item.product_name}</span>
                                        {item.active ? <> + vé</> : ''}
                                        {item.customer_name ? (
                                            <>
                                                <br></br>
                                                <span className={cx('name small')}>{item.customer_name}</span>
                                                <span className={cx('phone small')}> {item.customer_phone}</span>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <br></br>
                                    </td>
                                    <td>
                                        <div className={cx('group-row')}>
                                            <span className={cx('price')}>{formatPrice(price)}</span>
                                            <span className={cx('mark')}>&nbsp; x</span>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <span className={cx('quantity')}>{item.quantity}</span>
                                    </td>
                                    <td className="text-right">
                                        <strong className={cx('price')}>{formatPrice(item.quantity * price)}</strong>
                                    </td>
                                </tr>
                            );
                        })}
                        <tr style={{ borderTop: '2px solid var(--primary)' }}>
                            <td colSpan={3}>Tổng tiền hàng:</td>
                            <td className="text-right">
                                <strong className={cx('total')}>{formatPrice(print.total)}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>Đã Thanh toán:</td>
                            <td className="text-right">
                                <strong className={cx('total')}>{formatPrice(print.paid)}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>Chiết khấu:</td>
                            <td className="text-right">
                                <strong className={cx('total')}>{formatPrice(print.discount)}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <b>Còn lại:</b>
                            </td>
                            <td className="text-right">
                                <strong className={cx('total')}>
                                    {formatPrice(print.total - print.paid - print.discount)}
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <PrintFoot />
        </>
    );
}

export default PrintBill;
