import classNames from 'classnames/bind';
import styles from '../../Camping.module.scss';
const cx = classNames.bind(styles);
import { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faChild, faPeopleGroup, faPhone, faSignature } from '@fortawesome/free-solid-svg-icons';
import { CampingContext } from '~/context/CampingContext';
function Contact({ item }) {
    const { camping, setSelect } = useContext(CampingContext);
    const { select } = camping;

    const [adt, setAdt] = useState(2);
    const [aris, setAris] = useState(0);
    const [chd, setChd] = useState(0);
    const [inf, setInf] = useState(0);
    const [note, setNote] = useState('');

    useEffect(() => {
        setAdt(item.adt);
        setAris(item.aris);
        setChd(item.chd);
        setInf(item.inf);
        setNote(item.note);
    }, []);

    const handleChangeAdt = (value) => {
        setAdt(Number(value));
        const newSelect = select.map((obj) => {
            if (obj.table_key === item.table_key) {
                return { ...obj, adt: Number(value), aris: aris, chd: chd, inf: inf, note: note };
            }
            return obj;
        });
        setSelect(newSelect);
    };

    const handleChangeAris = (value) => {
        setAris(Number(value));
        const newSelect = select.map((obj) => {
            if (obj.table_key === item.table_key) {
                return { ...obj, adt: adt, aris: Number(value), chd: chd, inf: inf, note: note };
            }
            return obj;
        });
        setSelect(newSelect);
    };

    const handleChangeChd = (value) => {
        setChd(Number(value));
        const newSelect = select.map((obj) => {
            if (obj.table_key === item.table_key) {
                return { ...obj, adt: adt, aris: aris, chd: Number(value), inf: inf, note: note };
            }
            return obj;
        });
        setSelect(newSelect);
    };

    const handleChangeInf = (value) => {
        setInf(Number(value));
        const newSelect = select.map((obj) => {
            if (obj.table_key === item.table_key) {
                return { ...obj, adt: adt, aris: aris, chd: chd, inf: Number(value), note: note };
            }
            return obj;
        });
        setSelect(newSelect);
    };

    const handleChangeNote = (value) => {
        setNote(value);
        const newSelect = select.map((obj) => {
            if (obj.table_key === item.table_key) {
                return { ...obj, adt: adt, aris: aris, chd: chd, inf: inf, note: value };
            }
            return obj;
        });
        setSelect(newSelect);
    };

    return (
        <>
            <div className={cx('contact-row')}>
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faPeopleGroup} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>Người lớn:</span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <select value={adt} onChange={(e) => handleChangeAdt(e.target.value)}>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                    </select>
                                </div>
                            </span>
                        </span>
                    </div>
                </label>
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faPeopleGroup} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>Phát sinh</span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <select value={aris} onChange={(e) => handleChangeAris(e.target.value)}>
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                    </select>
                                </div>
                            </span>
                        </span>
                    </div>
                </label>
            </div>
            <div className={cx('contact-row')}>
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faChild} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>Trẻ em: 6 - 12</span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <select value={chd} onChange={(e) => handleChangeChd(e.target.value)}>
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                    </select>
                                </div>
                            </span>
                        </span>
                    </div>
                </label>
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faChild} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>
                                Trẻ em: <FontAwesomeIcon icon={faAngleLeft} /> 6
                            </span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <select value={inf} onChange={(e) => handleChangeInf(e.target.value)}>
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                    </select>
                                </div>
                            </span>
                        </span>
                    </div>
                </label>
            </div>
            <div className={cx('contact-row')}>
                <label className="col">
                    <span className={cx('input-label')}>Ghi chú:</span>
                    <textarea
                        rows={2}
                        cols={4}
                        defaultValue={note}
                        onChange={(e) => handleChangeNote(e.target.value)}
                    />
                </label>
            </div>
        </>
    );
}

export default Contact;
