import classNames from 'classnames/bind';
import styles from '../../Ingredient.module.scss';
const cx = classNames.bind(styles);
import { useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1 } from '@fortawesome/free-solid-svg-icons';
function IngredientAdd({ onAddNew }) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [unit, setUnit] = useState('');
    const [part, setPart] = useState(1);

    const handleAddIngredient = () => {
        const Add = async () => {
            const res = await adminServices.addIngredient({
                name: name,
                description: description,
                unit: unit,
                part: part,
            });
            if (res.status) {
                const data = res.data;
                onAddNew(data);
                setName('');
                setDescription('');
                setUnit('');
                setPart(1);
            } else {
                alert(res.message);
            }
        };
        Add();
    };

    return (
        <div className={cx('form')}>
            <div className="form-row">
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-label')}>Tên nguyên liệu:</span>
                        <span className={cx('input-value')}>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        </span>
                    </div>
                </label>
            </div>
            <div className="form-row">
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-label')}>Đơn vị:</span>
                        <span className={cx('input-value')}>
                            <input type="text" value={unit} onChange={(e) => setUnit(e.target.value)} />
                        </span>
                    </div>
                </label>
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-label')}>Định lượng:</span>
                        <span className={cx('input-value')}>
                            <input type="text" value={part} onChange={(e) => setPart(e.target.value)} />
                        </span>
                    </div>
                </label>
                <button className="btn-primary" onClick={handleAddIngredient}>
                    Thêm
                </button>
            </div>
            <div className="form-row">
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-label')}>Mô tả:</span>
                        <span className={cx('input-value')}>
                            <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </span>
                    </div>
                </label>
            </div>
        </div>
    );
}

export default IngredientAdd;
