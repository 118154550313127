import classNames from 'classnames/bind';
import moment from 'moment';
import styles from '../Table.module.scss';
import { orderTag } from '~/utils/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTableCells, faTent } from '@fortawesome/free-solid-svg-icons';
const cx = classNames.bind(styles);
function TableItem({ item, onClick }) {
    const handleClick = (tableId) => {
        onClick(tableId);
    };
    return (
        <>
            <div className={cx('table-item')} onClick={() => handleClick(item)}>
                <div className={cx('table-item-box')}>
                    <div className={cx('table-head', `status-${item.table_status}`, item.table_type)}>
                        <span className={cx('table-icon')}>
                            {item.table_type === 'table' ? (
                                <FontAwesomeIcon icon={faTableCells} />
                            ) : (
                                <FontAwesomeIcon icon={faTent} />
                            )}
                        </span>
                        <span className={cx('table-name')}> {item.table_name}</span>
                    </div>
                    <div className={cx('table-body', item.table_type)}>
                        <ul className={cx('order-list')}>
                            {item.orders?.map((order, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={cx('customer-type', order.type, { active: order.active })}
                                    >
                                        <span className={cx('type')}>
                                            {orderTag(order.type)} {order.id}
                                        </span>
                                        <small className={cx('time')}>
                                            {moment(order.date).format('DD-MM-YY HH:mm')}
                                        </small>
                                        <span>{order.name}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className={cx('table-add', item.table_type)}>
                        <FontAwesomeIcon icon={faCirclePlus} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TableItem;
