import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
const cx = classNames.bind(styles);
import { useContext } from 'react';
import { ReceiptContext } from './Receipt';

function ReceiptStep() {
    const { step, setStep } = useContext(ReceiptContext);
    const handleChangeStep = (s) => {
        setStep(s);
    };
    return (
        <>
            <div className={cx('receipt-step')}>
                <div className={cx('step-row')}>
                    <button className={cx('step step-1', { active: step === 1 })} onClick={() => handleChangeStep(1)}>
                        <span className={cx('step-number')}>1</span>
                        <span className={cx('step-text')}>Chọn Nguyên Liệu</span>
                    </button>
                    <span className={cx('bar')}></span>
                    <button className={cx('step step-2', { active: step === 2 })} onClick={() => handleChangeStep(2)}>
                        <span className={cx('step-number')}>2</span>
                        <span className={cx('step-text')}>Nhập Số Lượng</span>
                    </button>
                    <span className={cx('bar')}></span>
                    <button className={cx('step step-3', { active: step === 3 })} onClick={() => handleChangeStep(3)}>
                        <span className={cx('step-number')}>3</span>
                        <span className={cx('step-text')}>Nhập thông tin</span>
                    </button>
                    <span className={cx('bar')}></span>
                    <button className={cx('step step-4', { active: step === 4 })} onClick={() => handleChangeStep(4)}>
                        <span className={cx('step-number')}>3</span>
                        <span className={cx('step-text')}>Xác nhận</span>
                    </button>
                </div>
                <br></br>
            </div>
        </>
    );
}

export default ReceiptStep;
