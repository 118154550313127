import classNames from 'classnames/bind';
import styles from './Image.module.scss';
import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
const cx = classNames.bind(styles);
const ImageUpload = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const fileInputRef = useRef(null);

    const handleClickBox = () => {
        fileInputRef.current.click();
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            // You can also handle file upload here
            console.log(URL.createObjectURL(file));

        }
    };

    return (
        <div className={cx('image-wraper')} onClick={handleClickBox}>
            <input ref={fileInputRef} type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
            {
                selectedImage
                    ?
                    <img src={selectedImage} alt="Selected" />
                    :
                    <div>
                        <span>
                            <FontAwesomeIcon icon={faPlus} size='lg' />
                        </span>
                        <div>Upload</div>
                    </div>
            }
        </div>
    );
};

export default ImageUpload;