import classNames from 'classnames/bind';
import styles from './ChoiceButton.module.scss';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faClose, faEdit } from '@fortawesome/free-solid-svg-icons';
import * as cartServices from '~/apiServices/cartServices';
const cx = classNames.bind(styles);
function ChoiceButton({ item, tents, onChange }) {
    const [show, setShow] = useState(false);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                event.stopPropagation();
                if (ref.current && !ref.current.contains(event.target)) {
                    setShow(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleClickButton = (e) => {
        e.stopPropagation();
        setShow(true);
    };
    const handleCloseButton = (e) => {
        e.stopPropagation();
        setShow(false);
    };

    const handleChoiseTent = (e, tent) => {
        e.stopPropagation();
        const apiUpdate = async () => {
            const res = await cartServices.updateTent({
                detail_id: item.detail_id,
                product_id: tent.product_id,
            });
            if (res.status) {
                const newDetailId = res.data;
                onChange(item.detail_id, {
                    detail_id: newDetailId,
                    product_name: tent.product_name,
                    product_price: tent.product_price,
                    display_price: tent.display_price,
                });
            } else {
                alert(res.message);
            }
        };
        apiUpdate();
        setShow(false);
    };
    return (
        <>
            <button className={cx('choice-button')} onClick={(e) => handleClickButton(e)}>
                <span>
                    <FontAwesomeIcon icon={faEdit} />
                </span>
                <span> </span>
                <strong>Thay đổi</strong>
            </button>
            <div className={cx('modal', { show: show })}>
                <div ref={wrapperRef} className={cx('modal-container')}>
                    <div className={cx('modal-head')}>
                        <h3>Chọn Loại Lều</h3>
                        <br></br>
                        <p className={cx('modal-sub-head')}>
                            {item.product_name} - {item.display_price}
                        </p>
                        <button onClick={(e) => handleCloseButton(e)} className={cx('btn-close')}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>
                    <div className={cx('modal-body')}>
                        <ul className={cx('row')}>
                            {tents?.map((tent, index) => {
                                return (
                                    <li key={index} onClick={(e) => handleChoiseTent(e, tent)}>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faCircleDot} />
                                        </span>
                                        <span className="text">
                                            <span className="name">{tent.product_name}</span>
                                            <span className="price">{tent.display_price}</span>
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChoiceButton;
