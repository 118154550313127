import classNames from 'classnames/bind';
import styles from '../ProductDetail.module.scss';
import * as adminServices from '~/apiServices/adminServices';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
const cx = classNames.bind(styles);
function FormAdd({ onSubmit }) {
    const { catId, type } = useParams();
    const [name, setName] = useState('');
    const [unit, setUnit] = useState('');
    const [order, setOrder] = useState(0);
    const [price, setPrice] = useState(0);
    const [displayPrice, setDisplayPrice] = useState('0k');

    const handleAddNewProduct = () => {
        const apiAdd = async () => {
            const res = await adminServices.addProduct({
                cat_id: catId,
                type: type,
                name: name,
                unit: unit,
                order: order,
                price: price,
                display_price: displayPrice,
            });
            if (res.status) {
                onSubmit(res.data);
                setName('');
                setUnit('');
                setOrder(0);
                setPrice(0);
                setDisplayPrice('0k');
            } else {
                alert(res.message);
            }
        };
        apiAdd();
    };
    return (
        <>
            <div className={cx('form')}>
                <div className="form-row">
                    <label className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('input-label none-icon')}>Tên sản phẩm:</span>
                            <span className={cx('input-value')}>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </span>
                        </div>
                    </label>
                </div>
                <div className="form-row">
                    <label className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('input-label none-icon')}>Giá:</span>
                            <span className={cx('input-value')}>
                                <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
                            </span>
                        </div>
                    </label>
                    <label className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('input-label none-icon')}>Giá hiển thị:</span>
                            <span className={cx('input-value')}>
                                <input
                                    type="text"
                                    value={displayPrice}
                                    onChange={(e) => setDisplayPrice(e.target.value)}
                                />
                            </span>
                        </div>
                    </label>
                </div>
                <div className="form-row">
                    <label className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('input-label none-icon')}>Sắp xếp:</span>
                            <span className={cx('input-value')}>
                                <input type="number" value={order} onChange={(e) => setOrder(e.target.value)} />
                            </span>
                        </div>
                    </label>
                    <label className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('input-label none-icon')}>Đơn vị:</span>
                            <span className={cx('input-value')}>
                                <input type="text" value={unit} onChange={(e) => setUnit(e.target.value)} />
                            </span>
                        </div>
                    </label>
                    <button className="btn-primary" onClick={handleAddNewProduct}>
                        Thêm
                    </button>
                </div>
            </div>
        </>
    );
}

export default FormAdd;
