import classNames from 'classnames/bind';
import styles from './Bill.module.scss';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import * as homeServices from '~/apiServices/homeServices';
import BillList from './BillList';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);

const cx = classNames.bind(styles);
function Bill() {
    const dateNow = new Date();
    const [datas, setDatas] = useState([]);

    const filterApi = async () => {
        const response = await homeServices.getBills();
        if (response) {
            setDatas(response);
        }
    };

    useEffect(() => {
        filterApi();
    }, [])
    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">
                    Hóa đơn
                    <span>{moment(dateNow).format('DD/MM/YY')}</span>
                </h2>
            </div>
            <div className={cx('admin-body')}>
                <BillList datas={datas} />
            </div>
        </>
    );
}

export default Bill;
