import classNames from 'classnames/bind';
import styles from './TableModal.module.scss';
import * as tableServices from '~/apiServices/tableServices';
import { useEffect, useRef, useState } from 'react';
import { orderTag } from '~/utils/filters';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faClose } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import FormBbq from './FormBbq';
import FormCamping from './FormCamping';
const cx = classNames.bind(styles);
function TableModal({ table, orders, show, setShow }) {
    const naviate = useNavigate();
    const [popup, setPopup] = useState(false);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShow(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleClickNewOrderFit = (table) => {
        const apiAdd = async () => {
            const res = await tableServices.addCustomerFit({
                table_id: Number(table.table_id),
            });
            if (res) {
                const fitId = res.data;
                if (fitId) naviate(`/table-detail/fit/${table.table_id}/${fitId}`);
            } else {
                alert(res.message);
            }
        };
        apiAdd();
    };
    const handleShowPopupBbq = () => {
        document.body.style.overflow = 'hidden';
        setPopup(true);
    };

    const handleClosePopup = () => {
        document.body.style.overflow = 'visible';
        setPopup(false);
    };

    const handleClickToOrder = (order) => {
        const apiUpdate = async () => {
            const response = await tableServices.updateStatus({
                table_id: Number(order.table_id),
                status: 1,
            });
            if (response) {
                if (order.type === 'fit') {
                    naviate(`/table-detail/fit/${order.table_id}/${order.id}`);
                } else if (order.type === 'bbq') {
                    naviate(`/table-detail/bbq/${order.table_id}/${order.id}`);
                } else if (order.type === 'ticket') {
                    naviate(`/table-detail/ticket/${order.table_id}/${order.id}`);
                } else if (order.type === 'camp') {
                    naviate(`/table-detail/camp/${order.table_id}/${order.id}`);
                }
            }
        };
        apiUpdate();
    };

    return (
        <>
            <div className={cx('modal', { show: show })}>
                <div ref={wrapperRef} className={cx('modal-container')}>
                    <div className={cx('modal-head')}>
                        <h3>{`Mở ${table.table_name}`}</h3>
                        <button onClick={() => setShow(false)} className={cx('btn-close')}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>
                    <div className={cx('modal-body')}>
                        <ul>
                            {['table', 'tent'].includes(table.table_type) ? (
                                <>
                                    <li className={cx('new-water')} onClick={() => handleClickNewOrderFit(table)}>
                                        <small>
                                            <FontAwesomeIcon icon={faAdd} />
                                        </small>
                                        <small> Khách mới - NƯỚC</small>
                                    </li>
                                    <li className={cx('new-bbq')} onClick={() => handleShowPopupBbq(table)}>
                                        <small>
                                            <FontAwesomeIcon icon={faAdd} />
                                        </small>
                                        <small> Khách mới - BBQ</small>
                                    </li>
                                </>
                            ) : (
                                ''
                            )}
                            {table.table_type === 'camp' ? (
                                <>
                                    <li className={cx('new-bbq')} onClick={() => handleShowPopupBbq(table)}>
                                        <small>
                                            <FontAwesomeIcon icon={faAdd} />
                                        </small>
                                        <small> Khách mới - Lều</small>
                                    </li>
                                </>
                            ) : (
                                ''
                            )}
                            {orders.map((order, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={cx(order.type, { active: order.active })}
                                        onClick={() => handleClickToOrder(order)}
                                    >
                                        <span className={cx('type')}>
                                            {orderTag(order.type)} {order.id}
                                        </span>
                                        <small className={cx('time')}>
                                            {moment(order.date).format('DD-MM-YY HH:mm')}
                                        </small>
                                        <small> {order.name}</small>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={cx('popup', { show: popup })}>
                <button onClick={handleClosePopup} className={cx('btn-close')}>
                    <FontAwesomeIcon icon={faClose} />
                </button>
                <div className={cx('popup-container')}>
                    {table.table_type === 'camping' ? (
                        <FormCamping table={table} onClose={handleClosePopup} />
                    ) : (
                        <FormBbq table={table} onClose={handleClosePopup} />
                    )}
                </div>
            </div>
        </>
    );
}

export default TableModal;
