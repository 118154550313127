import { useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../CampPlanDetail.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faArrowRight, faCalendarAlt, faPrint, faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { formatPrice } from '~/utils/filters';
const cx = classNames.bind(styles);

function CampOrderItem({ item, onClickPrint }) {
    const navigate = useNavigate();
    const { tableId } = useParams();
    const [loading, setLoading] = useState(false);
    const handleClickDetail = (detailId) => {
        navigate(`/table-detail/camp/${tableId}/${detailId}`);
    };

    const handleClickPrint = (e, campId) => {
        e.stopPropagation();
        setLoading(true);
        onClickPrint(campId);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    };

    return (
        <>
            <div className={cx('order-item')}>
                <div className={cx('order-row')}>
                    <strong>
                        {item.name} - {item.table_name}
                    </strong>
                    <p>{item.phone}</p>
                </div>
                <div className={cx('order-row')}>
                    <span>
                        Người Lớn: <strong>{item.adt}</strong>
                    </span>
                    <span>
                        Trẻ Em (6 - 12): <strong>{item.chd}</strong>
                    </span>
                </div>
                <div className={cx('order-row')}>
                    <span>
                        Phát Sinh: <strong>{item.aris}</strong>
                    </span>
                    <span>
                        Trẻ Em {'<'} 6: <strong>{item.inf}</strong>
                    </span>
                </div>
                <p>Chú thích: {item.note}</p>
                <div className={cx('details')}>
                    {item.details?.map((d, i) => {
                        return (
                            <div
                                key={i}
                                className={cx('detail-item', { active: d.active })}
                                onClick={() => handleClickDetail(d.detail_id)}
                            >
                                <div className={cx('detail-row')}>
                                    <div className={cx('date')}>
                                        <code>{moment(d.date).format('DD-MM-YYYY')}</code>
                                        <small>
                                            {' '}
                                            <FontAwesomeIcon icon={faAnglesRight} />{' '}
                                        </small>
                                        <code>{moment(d.date).add(1, 'day').format('DD-MM-YYYY')}</code>
                                    </div>
                                    <button className="btn-detail">
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </button>
                                </div>
                                <div className={cx('detail-row')}>
                                    <table>
                                        <tbody>
                                            {d.childs?.map((c, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {c.active ? (
                                                                <strong>{c.product_name}</strong>
                                                            ) : (
                                                                <span>{c.product_name}</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <span className="group-row">x {c.quantity}</span>
                                                        </td>
                                                        <td className="text-right">
                                                            <span className={cx('price')}>{c.display_price}</span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={cx('total-row')}>
                    Tổng: <span className={cx('price')}>{formatPrice(item.total)}</span>
                </div>
                <div className={cx('action-row')}>
                    <button className={cx('btn-print')} onClick={(e) => handleClickPrint(e, item.camp_id)}>
                        {loading ? (
                            <span>
                                <FontAwesomeIcon spin={true} icon={faSpinner} /> In Hóa Đơn Lẻ
                            </span>
                        ) : (
                            <span>
                                <FontAwesomeIcon icon={faPrint} /> In Hóa Đơn Lẻ
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </>
    );
}
export default CampOrderItem;
