import classNames from 'classnames/bind';
import styles from './ChoiceButton.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { CampingContext } from '~/context/CampingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faClose, faEdit, faTent } from '@fortawesome/free-solid-svg-icons';
const cx = classNames.bind(styles);
function ChoiceButton({ item, campIndex, itemIndex }) {
    const [show, setShow] = useState(false);
    const { camping, setTent } = useContext(CampingContext);
    const { tents } = camping;
    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShow(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleClickButton = () => {
        setShow(true);
    };

    const handleChoiseTent = (tent, indexTent) => {
        setTent({
            campIndex: campIndex,
            itemIndex: itemIndex,
            tent: tent,
            indexTent: indexTent,
        });
        setShow(false);
    };
    return (
        <>
            <button className={cx('choice-button')} onClick={handleClickButton}>
                <span>
                    <FontAwesomeIcon icon={faEdit} />
                </span>
                <span> </span>
                <strong>Thay đổi</strong>
            </button>
            <div className={cx('modal', { show: show })}>
                <div ref={wrapperRef} className={cx('modal-container')}>
                    <div className={cx('modal-head')}>
                        <h3>Chọn Loại Lều</h3>
                        <br></br>
                        <p className={cx('modal-sub-head')}>
                            {item.product_name} - {item.display_price}
                        </p>
                        <button onClick={() => setShow(false)} className={cx('btn-close')}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>
                    <div className={cx('modal-body')}>
                        <ul className={cx('row')}>
                            {tents.map((tent, index) => {
                                return (
                                    <li key={index} onClick={() => handleChoiseTent(tent, index)}>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faCircleDot} />
                                        </span>
                                        <span className="text">
                                            <span className="name">{tent.product_name}</span>
                                            <span className="price">{tent.display_price}</span>
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChoiceButton;
