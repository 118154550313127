import classNames from 'classnames/bind';
import styles from '../Admin.module.scss';
import { formatPrice } from '~/utils/filters';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import * as adminServices from '~/apiServices/adminServices';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
import WaterList from './WaterList';
import BbqList from './BbqList';
import TicketList from './TicketList';
import BreakFast from './BreakFast';
import StatisticList from './StatisticList';
import CampList from './CampList';
registerLocale('vi', vi);

const cx = classNames.bind(styles);
function Statistic() {
    const dateNow = new Date();
    const [catId, setCatId] = useState(0);
    const [startDate, setStartDate] = useState(dateNow);
    const [endDate, setEndDate] = useState(dateNow);
    const [orders, setOrders] = useState([]);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleFilter = (catId) => {
        setCatId(catId);
        const filterOrders = async () => {
            const response = await adminServices.getOrders({
                params: {
                    cat_id: Number(catId) || 0,
                    start: moment(startDate).format('YYYY-MM-DD'),
                    end: moment(endDate).format('YYYY-MM-DD'),
                },
            });
            if (response) {
                setOrders(response);
            }
        };
        filterOrders();
    };

    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">
                    Thống kê
                    <span>
                        {moment(startDate).format('DD/MM/YY')} - {moment(endDate).format('DD/MM/YY')}
                    </span>
                </h2>
            </div>
            <div className={cx('admin-head')}>
                <div className={cx('filter-row')}>
                    <div className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('filter-label')}>Từ ngày</span>
                            <div className={cx('input-value')}>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Ngày bắt đầu"
                                    locale="vi"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('filter-label')}>Tới ngày</span>
                            <div className={cx('input-value')}>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Ngày kết thúc"
                                    locale="vi"
                                />
                            </div>
                        </div>
                    </div>
                    <button className={cx('btn-filter', { active: catId === 0 })} onClick={() => handleFilter(0)}>
                        <FontAwesomeIcon icon={faFilter} />
                        <span> Lọc</span>
                    </button>
                </div>
                <div className={cx('filter-row')}>
                    <button className={cx('btn-sort', { active: catId === 1 })} onClick={() => handleFilter(1)}>
                        <span> Nước</span>
                    </button>
                    <button className={cx('btn-sort', { active: catId === 2 })} onClick={() => handleFilter(2)}>
                        <span> BBQ</span>
                    </button>
                    <button className={cx('btn-sort', { active: catId === 3 })} onClick={() => handleFilter(3)}>
                        <span> Vé</span>
                    </button>
                    <button className={cx('btn-sort', { active: catId === 5 })} onClick={() => handleFilter(5)}>
                        <span> Lều</span>
                    </button>
                </div>
            </div>
            <div className={cx('admin-body')}>
                {catId === 0 ? <StatisticList orders={orders} /> : ''}
                {catId === 1 ? <WaterList orders={orders} /> : ''}
                {catId === 2 ? <BbqList orders={orders} /> : ''}
                {catId === 3 ? <TicketList orders={orders} /> : ''}
                {catId === 4 ? <BreakFast orders={orders} /> : ''}
                {catId === 5 ? <CampList orders={orders} /> : ''}
            </div>
        </>
    );
}

export default Statistic;
