import classNames from 'classnames/bind';
import styles from '../../ProductList.module.scss';
import { useEffect } from 'react';
import * as productServices from '~/apiServices/productServices';
const cx = classNames.bind(styles);
function ProductOptions({ options, setOptions }) {
    useEffect(() => {
        const fetchApi = async () => {
            const result = await productServices.productOptions();
            setOptions(result);
        };
        if (options.length === 0) fetchApi();
    }, []);

    const handleChangeOption = (optionId) => {
        setOptions((prevOptions) => {
            const newOptions = prevOptions.map((obj) => {
                if (obj.option_id === optionId) {
                    return { ...obj, is_checked: !obj.is_checked };
                }
                return obj;
            });
            return newOptions;
        });
    };

    return (
        <div className={cx('options')}>
            {options.map((option, index) => {
                return (
                    <div key={index} className={cx('option-item')}>
                        <label>
                            <input
                                type="checkbox"
                                value={option.option_id}
                                onChange={() => handleChangeOption(option.option_id)}
                                checked={option.is_checked}
                            />
                            <span> {option.option_name}</span>
                        </label>
                    </div>
                );
            })}
        </div>
    );
}
export default ProductOptions;
