import classNames from 'classnames/bind';
import styles from './Store.module.scss';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
import StoreNav from './StoreNav';
import moment from 'moment';
registerLocale('vi', vi);
const cx = classNames.bind(styles);
function StockIn() {
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const fetchStores = async () => {
        const response = await adminServices.getStores();
        if (response) {
            const newStores = response.map(s => {
                return { ...s, date: '', quantity: 0 }
            })
            setStores(newStores);
        }
    };
    useEffect(() => {
        fetchStores();
    }, []);

    const handleDateChange = (date, ingredient_id) => {
        setStores((prevStores) => {
            const newStores = prevStores.map((obj) => {
                if (obj.ingredient_id === ingredient_id) {
                    return { ...obj, date: date };
                }
                return obj;
            });
            return newStores;
        });
    };

    const handleQuantityChange = (quantity, ingredient_id) => {
        setStores((prevStores) => {
            const newStores = prevStores.map((obj) => {
                if (obj.ingredient_id === ingredient_id) {
                    return { ...obj, quantity: Number(quantity) };
                }
                return obj;
            });
            return newStores;
        });
    };

    const handleSave = () => {
        const newStores = stores.filter(s => s.quantity !== 0);

        if (newStores.length == 0) {
            alert(`Chưa nhập dữ liệu`);
            return;
        }

        const newDatas = newStores.map(d => {
            return {
                ...d,
                date: d.date ? moment(d.date).format('YYYY-MM-DD') : ''
            }
        });
        console.log(newDatas);
        const AddApi = async () => {
            const res = await adminServices.addStockIn({
                datas: newDatas
            });
            if (res.status) {
                navigate('/super-admin/stock-history');
            } else {
                alert(res.message);
            }
        };
        AddApi();
    }
    return (
        <>
            <StoreNav title="Nhập kho" />
            <div className={cx('admin-body')}>
                <div className={cx('stock-in')}>
                    <div className={cx('header')}>
                        <h3>NHẬP KHO</h3>
                    </div>
                    <div className={cx('body')}>
                        <div className={cx('form')}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Tên Nguyên Liệu</th>
                                        <th className={cx('text-center')}>Tồn</th>
                                        <th>S.Lượng</th>
                                        <th className="text-right">Phần</th>
                                        <th>HSD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stores?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td width={50} className={cx('text-center')}>{index + 1}</td>
                                                <td width={200}>{item.ingredient_name}</td>
                                                <td width={100} className={cx('text-center')}>
                                                    <span className={cx('store')}>{item.stock}</span>
                                                </td>
                                                <td width={150}>
                                                    <div className="table-row">
                                                        <input
                                                            id={`quantity-${index}`}
                                                            type="number"
                                                            min={0}
                                                            value={item.quantity}
                                                            onChange={(e) => handleQuantityChange(e.target.value, item.ingredient_id)}
                                                            style={{ width: '100px' }}
                                                        />
                                                        <small className={cx('available')}>{item.ingredient_unit}</small>

                                                    </div>
                                                </td>
                                                <td width={100} className="text-right">
                                                    <span><strong>{item.quantity * item.ingredient_part} </strong> <small>phần</small></span>
                                                </td>
                                                <td>
                                                    <DatePicker
                                                        id={`expiry_date-${index}`}
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={item.date}
                                                        placeholderText="Hạn sử dụng"
                                                        locale="vi"
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        shouldCloseOnSelect={true}
                                                        enableTabLoop={true}
                                                        key={index}
                                                        onChange={(dateValue) => handleDateChange(dateValue, item.ingredient_id)}
                                                    />
                                                </td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={cx('footer')}>
                        <div className={cx('navbar')}>
                            <div className={cx('navbar-row')}>
                                <button className={cx('btn-back')} onClick={() => navigate(-1)}>
                                    <span>
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </span>
                                </button>
                                <button className={cx('btn-save')} onClick={handleSave}>
                                    <span>
                                        <FontAwesomeIcon icon={faSave} />
                                    </span>
                                    <span>NHẬP KHO</span>
                                </button>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StockIn;
