import { useEffect, useState } from 'react';
import * as campServices from '~/apiServices/campServices';
import classNames from 'classnames/bind';
import styles from './CampPlanDetail.module.scss';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import CampOrderItem from './CampOrderItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAnglesLeft, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
const cx = classNames.bind(styles);

function CampPlanDetail() {
    const { tableId } = useParams();
    const navigate = useNavigate();
    const [detail, setDetail] = useState({});
    const [campId, setCampId] = useState(0);

    useEffect(() => {
        const fetchCamp = async () => {
            const response = await campServices.getCamp({
                params: {
                    table_id: Number(tableId),
                },
            });
            if (response) setDetail(response);
        };
        fetchCamp();
    }, []);

    const handlePrint = () => {
        const f = document.getElementById('iframe-bill');
        const w = f.contentWindow;
        w.postMessage({ action: 'print-bill' }, f.src);
    };

    const handleClickPrint = (campId) => {
        setCampId(campId);
        setTimeout(() => {
            handlePrint();
        }, 900);
    };
    return (
        <>
            <div className="page-title">
                <div className={cx('group-title')}>
                    <h3 className={cx('title')}>{detail.table_key}</h3>
                    <span className={cx('sub-title')}>{detail.table_name}</span>
                </div>
                <div className={cx('time')}>
                    <span>{moment().format('DD/MM/YYYY')}</span>
                </div>
            </div>
            {campId !== 0 ? (
                <iframe
                    id="iframe-bill"
                    src={`/camp-bill/${campId}/${tableId}`}
                    style={{ display: 'none' }}
                    title="PRINT BILL"
                />
            ) : (
                ''
            )}
            <div className={cx('page-content')}>
                <div className={cx('orders')}>
                    {detail.orders?.map((item, index) => {
                        return <CampOrderItem key={index} item={item} onClickPrint={handleClickPrint} />;
                    })}
                </div>
                <div className={cx('navbar')}>
                    <div className={cx('navbar-row')}>
                        <button className={cx('btn-back')} onClick={() => navigate('/camp-plan')}>
                            <span>
                                <FontAwesomeIcon icon={faAnglesLeft} />
                            </span>
                            <span>Trở về</span>
                        </button>
                    </div>
                    <br></br>
                </div>
            </div>
        </>
    );
}

export default CampPlanDetail;
