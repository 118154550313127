import classNames from 'classnames/bind';
import styles from './Camping.module.scss';
import { dateNow } from '~/utils/filters';
const cx = classNames.bind(styles);
import { CampingProvider } from '~/context/CampingContext';
import Step from './Step';
import Content from './Content';
function Camping() {
    return (
        <>
            <div className={cx('campings')}>
                <div className="page-title">
                    <div>
                        <strong>Đặt lều trại</strong>
                    </div>
                    <span>{dateNow()}</span>
                </div>
                <CampingProvider>
                    <Step />
                    <Content />
                </CampingProvider>
            </div>
        </>
    );
}

export default Camping;
