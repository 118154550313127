import classNames from 'classnames/bind';
import styles from '../../ProductList.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPhone, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import * as ticketServices from '~/apiServices/ticketServices';
import { customerLabel } from '~/utils/filters';
const cx = classNames.bind(styles);
function LoyalCustomer({ customer, setCustomer }) {
    const [searching, setSearching] = useState(false);
    const [q, setQ] = useState('');
    const [customers, setCustomers] = useState([]);
    const [data, setData] = useState({});
    const filterApi = async (phone) => {
        const response = await ticketServices.getLoyalCustomers({
            params: {
                phone: phone,
            },
        });
        if (response) {
            setSearching(false);
            setCustomers(response);
        }
    };

    const handleSearch = () => {
        setSearching(true);
        filterApi(q);
    };

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setCustomers([]);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleResetSearch = () => {
        setQ('');
        setCustomers([]);
    };
    const handleSearchRun = useCallback(async (value) => {
        setQ(value);
        setSearching(true);
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(async () => {
            try {
                setSearching(false);
                filterApi(value);
            } catch (error) {
                console.error('Error during search:', error);
            }
        }, 700);
    }, []);
    let searchTimeout;

    const handleAddToLoyalCustomer = (e, item) => {
        e.stopPropagation();
        setCustomer(item);
        setQ('');
        setCustomers([]);
    };

    const handleRemoveLoyalCustomer = () => {
        setCustomer({});
    };
    return (
        <div className={cx('loyal-customer')}>
            <h3 className={cx('table-title')}>Khách hàng Thân Thiết</h3>
            <div className={cx('form')}>
                <div className={cx('form-row')}>
                    <div className={cx('col')}>
                        <div className={cx('input-field')}>
                            <span className={cx('input-icon')}>
                                <FontAwesomeIcon icon={faPhone} />
                            </span>
                            <span className={cx('input-label')}>SĐT:</span>
                            <span className={cx('input-value')}>
                                <input
                                    type="text"
                                    value={q}
                                    maxLength={10}
                                    onChange={(e) => setQ(e.target.value)}
                                    onKeyUp={(e) => handleSearchRun(e.target.value)}
                                />
                            </span>
                            <span className={cx('input-loader', { show: searching })}>
                                <FontAwesomeIcon icon={faSpinner} className="spinner" />
                            </span>
                            <span
                                className={cx('input-close', { show: q.length > 0 && !searching })}
                                onClick={handleResetSearch}
                            >
                                <FontAwesomeIcon icon={faClose} />
                            </span>
                            <div ref={wrapperRef} className={cx('box-customers')}>
                                {customers.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={cx('customer-item')}
                                            onClick={(e) => handleAddToLoyalCustomer(e, item)}
                                        >
                                            <strong>
                                                #{item.customer_id} - {item.customer_name}
                                            </strong>
                                            <p className={cx('phone')}>{item.customer_phone}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <button className="btn-primary" onClick={handleSearch}>
                        Tìm
                    </button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th className="text-center">ID</th>
                        <th>TÊN</th>
                        <th>SĐT</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={cx('tr-border')} hidden={Object.keys(customer).length === 0}>
                        <td className="text-center">{customer.customer_id}</td>
                        <td>
                            <strong className={cx('name')}>{customer.customer_name}</strong>

                            <p className={cx('type')}>{customerLabel(customer.customer_type)}</p>
                        </td>
                        <td>
                            <strong className={cx('phone')}>{customer.customer_phone}</strong>
                        </td>
                        <td>
                            <button className={cx('btn-remove')} onClick={() => handleRemoveLoyalCustomer()}>
                                <span className="icon">
                                    <FontAwesomeIcon icon={faXmark} />
                                </span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
export default LoyalCustomer;
