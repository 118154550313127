import classNames from 'classnames/bind';
import styles from '../Print.module.scss';
import { useEffect, useState } from 'react';
const cx = classNames.bind(styles);
import * as ticketServices from '~/apiServices/ticketServices';
import { useParams } from 'react-router-dom';
import PrintHead from '../PrintHead';
import PrintFoot from '../PrintFoot';
import { formatPrice } from '~/utils/filters';
function PrintTicket() {
    const { ticketId } = useParams();
    const [ticket, setTicket] = useState({});
    useEffect(() => {
        const fetchApi = async () => {
            const response = await ticketServices.printTicket({
                params: {
                    ticket_id: Number(ticketId),
                },
            });
            setTicket(response);
        };
        fetchApi();
    }, []);
    let totalPrice = ticket.ticket_number * ticket.ticket_price;
    let discount = ticket.discount;

    const handleMessage = (event) => {
        if (event.data.action === 'print-ticket') {
            console.log('print-ticket');
            window.focus();
            window.print();
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    return (
        <>
            <PrintHead tableKey={'VÉ'} orderId={ticket.code} time={ticket.created_at} title={'PHIẾU THÔNG TIN'} />
            <div className={cx('print-body')}>
                <table>
                    <thead>
                        <tr>
                            <th>Tên</th>
                            <th className="text-center">SL</th>
                            <th>Đ.Giá</th>
                            <th className="text-right">T.Tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ticket.orders?.length > 0 ? (
                            <>
                                {ticket.orders?.map((item, index) => {
                                    return (
                                        <tr key={index} className={cx('tr-border')}>
                                            <td>
                                                <span className={cx('name small')}>{item.product_name} + vé</span>
                                                {item.customer_name ? (
                                                    <>
                                                        <br></br>
                                                        <span className={cx('small')}>{item.customer_name}</span>{' '}
                                                        <span className={cx('phone small')}>{item.customer_phone}</span>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className="text-center">
                                                <span className={cx('quantity')}>1</span>
                                            </td>
                                            <td>
                                                <strong className={cx('price')}>{formatPrice(item.price)}</strong>
                                            </td>
                                            <td className="text-right">
                                                <strong className={cx('price')}>{formatPrice(item.price)}</strong>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </>
                        ) : (
                            ''
                        )}
                        <tr style={{ borderTop: '2px solid var(--primary)' }}>
                            <td colSpan={3}>Tổng tiền vé:</td>
                            <td className="text-right">
                                <strong className={cx('total')}>{formatPrice(totalPrice)}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>Chiết khấu:</td>
                            <td className="text-right">
                                <strong className={cx('discount')}>{formatPrice(discount)}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <b>Tổng Cộng:</b>
                            </td>
                            <td className="text-right">
                                <strong className={cx('total')}>{formatPrice(totalPrice - discount)}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <PrintFoot />
        </>
    );
}

export default PrintTicket;
