import classNames from 'classnames/bind';
import styles from './Store.module.scss';
import * as adminServices from '~/apiServices/adminServices';
import { useEffect, useState } from 'react';
import { dateFormat } from '~/utils/filters';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFileCircleCheck, faReceipt } from '@fortawesome/free-solid-svg-icons';
import StoreNav from './StoreNav';
import notify from '~/utils/notify';
const cx = classNames.bind(styles);
function ReceiptDetail() {
    const navigate = useNavigate();
    const { receiptId } = useParams();
    const [data, setData] = useState('');
    const [dataSource, setDataSource] = useState([]);

    const fetchApi = async () => {
        const response = await adminServices.getReceipt({
            params: {
                receipt_id: receiptId
            }
        });
        if (response) {
            setData(response);
            if (response.details) {
                setDataSource(response.details);
            }
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    const handleConfirmReceipt = () => {
        if (confirm(`Xác nhận nhập kho cho phiếu này?`)) {
            const acceptApi = async () => {
                const res = await adminServices.acceptReceipt({
                    receipt_id: Number(receiptId),
                });
                if (res.status) {
                    notify.success(res.message);
                    navigate(`/super-admin/receipts`);
                } else {
                    notify.error(res.message);
                }
            };
            acceptApi();
        }
    }

    return (
        <div className={cx('product')}>
            <StoreNav title="Phiếu nhập kho" />
            <div className={cx('admin-body')}>
                <div className={cx('header')}>
                    <h4>Phiếu nhập kho ngày {dateFormat(data?.date)}</h4>
                </div>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <label className="col">
                                <p>1. Người đề xuất: <strong>{data?.proposer}</strong></p>
                                <p>2. Bộ phận: <strong>{data?.department}</strong></p>
                                <p>3. Mục đích sử dụng trang thiết bị:</p>
                                <p>Phục vụ cho: <strong>{data?.purpose}</strong></p>
                            </label>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th>Tên Nguyên Liệu</th>
                                    <th>S.Lượng</th>
                                    <th>Phần</th>
                                    <th>HSD</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSource?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width={50} className="text-center">
                                                <span>{index + 1}</span>
                                            </td>
                                            <td width={250}>
                                                <span>{item.ingredient_name}</span>
                                            </td>
                                            <td width={150}>
                                                <span><strong>{item.quantity}</strong> <small>{item.ingredient_unit}</small></span>
                                            </td>
                                            <td width={150}>
                                                <span><strong>{item.part}</strong></span>
                                            </td>
                                            <td width={150}>{dateFormat(item.expiry_date)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={() => navigate(`/super-admin/receipts`)}>
                                <span>
                                    <FontAwesomeIcon icon={faReceipt} />
                                </span>
                                <span>PHIẾU</span>
                                <span>NHẬP KHO</span>
                            </button>
                            {
                                data?.status <= 2 ?
                                    <button className={cx('btn-save')} onClick={handleConfirmReceipt} style={{ color: "#ff5722" }}>
                                        <span>
                                            <FontAwesomeIcon icon={faFileCircleCheck} />
                                        </span>
                                        <span>XÁC NHẬN</span>
                                        <span>NHẬP KHO</span>
                                    </button>
                                    :
                                    <button className={cx('btn-save')} style={{ color: "#4caf50" }}>
                                        <span>
                                            <FontAwesomeIcon icon={faCheckCircle} />
                                        </span>
                                        <span>ĐÃ XÁC NHẬN</span>
                                        <span>NHẬP KHO</span>
                                    </button>
                            }

                        </div>
                        <br></br>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default ReceiptDetail;
