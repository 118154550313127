import classNames from 'classnames/bind';
import styles from '../CampList.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const cx = classNames.bind(styles);
import * as adminServices from '~/apiServices/adminServices';
import { formatPrice } from '~/utils/filters';
import { BBQ_STATUS, WATERS } from '~/utils/constants';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faCheck, faListCheck, faPenToSquare, faSave, faTent } from '@fortawesome/free-solid-svg-icons';

function CampDetail() {
    const navigate = useNavigate();
    const { campId } = useParams();
    const [info, setInfo] = useState({});
    const [datas, setDatas] = useState([]);
    const [editNote, setEditNote] = useState(false);
    const [note, setNote] = useState('');

    const [editDeposit, setEditDeposit] = useState(false);
    const [deposit, setDeposit] = useState(0);

    useEffect(() => {
        const fetchApi = async () => {
            const result = await adminServices.getCamping({
                params: {
                    camp_id: campId,
                },
            });
            if (result) {
                setInfo(result.info);
                setDatas(result.datas);
                setDeposit(result.info.deposit);
            }
        };
        if (Object.keys(info).length === 0) fetchApi();
    }, []);

    const updateCampStatus = (status) => {
        const apiUpdate = async () => {
            const res = await adminServices.updateCamping({
                action: 'status',
                camp_id: info.camp_id,
                status: status,
            });
            if (res.status) {
                setInfo({ ...info, status: res.data });
            } else {
                alert(res.message);
            }
        };
        apiUpdate();
    };

    const handleDeposit = () => {
        updateCampStatus(1);
    };

    const handleCancel = () => {
        if (confirm('Bạn có chắc muốn hủy bàn này?')) {
            updateCampStatus(3);
        }
    };

    const handleEditNote = () => {
        setEditNote(!editNote);
    };

    const handleUpdateNote = () => {
        const apiUpdate = async () => {
            const res = await adminServices.updateCamping({
                action: 'note',
                camp_id: info.camp_id,
                note: note,
            });
            if (res.status) {
                setInfo({ ...info, note: note });
            } else {
                alert(res.message);
            }
        };
        if (note !== '') apiUpdate();
        setEditNote(false);
    };

    const handleUpdateDeposit = () => {
        const apiUpdate = async () => {
            const res = await adminServices.updateCamping({
                action: 'deposit',
                camp_id: info.camp_id,
                deposit: deposit,
            });
            if (res.status) {
                setInfo({ ...info, deposit: res.data });
            } else {
                alert(res.message);
            }
        };
        apiUpdate();
        setEditDeposit(false);
    };

    const totalOrder = () => {
        let total = 0;
        for (let i in datas) {
            const item = datas[i];
            for (let o in item.services) {
                const serivce = item.services[o];
                total += Number(serivce.product_price) * Number(serivce.quantity);
            }
            for (let d in item.details) {
                const detail = item.details[d];
                const feeTent = Number(detail.product_price) * Number(item.adt);
                let feeExtra = 0;
                for (let e in detail.extras) {
                    const extra = detail.extras[e];
                    feeExtra += Number(extra.product_price) * Number(extra.quantity);
                }
                total += feeTent + feeExtra;
            }
        }
        return total;
    };
    const total = totalOrder();
    const remaining = total - deposit;
    return (
        <>
            <div className={cx('camps')}>
                <h3 className={cx('page-title')}>Chi tiết đặt Lều</h3>
                <div className={cx('camp-content')}>
                    <div className={cx('camp-info')}>
                        <p>
                            <span className={cx('camp-label')}>Trạng Thái:</span>
                            <strong className={cx('status', `status-${info.status}`)}>{BBQ_STATUS[info.status]}</strong>
                        </p>
                        <p>
                            <span className={cx('camp-label')}>Họ và Tên:</span> <strong>{info.name}</strong>
                        </p>
                        <p>
                            <span className={cx('camp-label')}>SĐT:</span> <strong>{info.phone}</strong>
                        </p>
                        <p>
                            <span className={cx('camp-label')}>Ngày nhận:</span>
                            <strong>{moment(info.check_in).format('DD-MM-YYYY')}</strong>
                        </p>
                        <p>
                            <span className={cx('camp-label')}>Ngày trả:</span>
                            <strong>{moment(info.check_out).format('DD-MM-YYYY')}</strong>
                        </p>
                        <p>
                            <span className={cx('camp-label')}>Đã Cọc:</span>
                            <strong className={cx('bbq-table', { hide: editDeposit })}>
                                <FontAwesomeIcon icon={faPenToSquare} onClick={() => setEditDeposit(!editDeposit)} />{' '}
                                {formatPrice(info.deposit)}
                            </strong>
                            <span className={cx('edit-row', { hide: !editDeposit })}>
                                <input value={deposit} onChange={(e) => setDeposit(e.target.value)} />
                                <button onClick={handleUpdateDeposit}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                            </span>
                        </p>
                        <p>
                            <span className={cx('camp-label')}>Ghi chú:</span>
                            <strong className={cx('camp-note', { hide: editNote })}>
                                <FontAwesomeIcon icon={faPenToSquare} onClick={handleEditNote} /> {info.note}
                            </strong>
                            <span className={cx('edit-row', { hide: !editNote })}>
                                <textarea rows={2} defaultValue={info.note} onChange={(e) => setNote(e.target.value)} />
                                <button onClick={handleUpdateNote}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                            </span>
                        </p>
                    </div>
                    <div className={cx('btn-group')}>
                        <button
                            className={cx('btn-deposit', { disable: info.status >= 1 })}
                            disabled={info.status >= 1}
                            onClick={handleDeposit}
                        >
                            Đã Cọc
                        </button>
                        <button
                            className={cx('btn-cancel', { disable: info.status >= 2 })}
                            disabled={info.status >= 2}
                            onClick={handleCancel}
                        >
                            Hủy Lều
                        </button>
                    </div>
                    <div className={cx('order-list')}>
                        {datas?.map((camp, campIndex) => {
                            return (
                                <div key={campIndex} className={cx('confirm-row')}>
                                    <h4 className={cx('title')}>
                                        <FontAwesomeIcon icon={faTent} /> <span>{camp.table_name}</span>
                                    </h4>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className={cx('info-label')}>Người lớn: </span>
                                                    <strong>{camp.adt}</strong>
                                                </td>
                                                <td>
                                                    <span className={cx('info-label')}>Phát sinh: </span>
                                                    <strong>{camp.aris}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className={cx('info-label')}>Trẻ em (6 - 120): </span>
                                                    <strong>{camp.chd}</strong>
                                                </td>
                                                <td>
                                                    <span className={cx('info-label')}>
                                                        Trẻ em (<span>{'<'}</span> 6):
                                                    </span>
                                                    <strong> {camp.inf}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table>
                                        <tbody>
                                            {camp.details?.map((item, itemIndex) => {
                                                return (
                                                    <Fragment key={`${campIndex}-${itemIndex}`}>
                                                        <tr>
                                                            <td>
                                                                <span>
                                                                    <strong>
                                                                        {moment(item.date).format('DD-MM-YYYY')}
                                                                    </strong>
                                                                    <span>
                                                                        {' '}
                                                                        <FontAwesomeIcon icon={faAnglesRight} />{' '}
                                                                    </span>
                                                                    <strong>
                                                                        {moment(item.date)
                                                                            .add(1, 'day')
                                                                            .format('DD-MM-YYYY')}
                                                                    </strong>
                                                                    <br></br>
                                                                    <span> {item.product_name}</span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className={cx('quantity')}>
                                                                    x {item.quantity}
                                                                </span>
                                                            </td>
                                                            <td className="text-right">
                                                                <strong>{item.display_price}</strong>
                                                            </td>
                                                        </tr>
                                                        {item.extras?.map((o, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <span>
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                            </span>
                                                                            <span> {o.product_name}</span>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className={cx('quantity')}>
                                                                            x {o.quantity}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <strong>{o.display_price}</strong>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <h3>
                                        <FontAwesomeIcon icon={faListCheck} />
                                        <span> Dịch vụ</span>
                                    </h3>
                                    <table>
                                        <tbody>
                                            {camp.services?.map((order, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className={cx('tent-title')}>
                                                                <span className={cx('icon')}>
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </span>
                                                                <span>{order.product_name} </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className={cx('quantity')}>x {order.quantity}</span>
                                                        </td>
                                                        <td className="text-right">
                                                            <div className={cx('service-group')}>
                                                                <strong className={cx('price')}>
                                                                    {order.display_price}
                                                                </strong>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <p>
                                        <span>Ghi chú:</span> <strong>{camp.note}</strong>
                                    </p>
                                </div>
                            );
                        })}
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <strong>Tổng: </strong>
                                    </td>
                                    <td width={150} className="text-right">
                                        <span className={cx('total')}>{formatPrice(total)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <strong>Cọc: </strong>
                                    </td>
                                    <td width={150} className="text-right">
                                        <span className={cx('total')}>{formatPrice(deposit)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <strong>Còn lại: </strong>
                                    </td>
                                    <td width={150} className="text-right">
                                        <span className={cx('total')}>{formatPrice(remaining)}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br></br>
                        <br></br>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CampDetail;
