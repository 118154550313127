import classNames from 'classnames/bind';
import styles from './CategoryList.module.scss';
import * as adminServices from '~/apiServices/adminServices';
import { useEffect, useState } from 'react';
import { faEdit, faEye, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from '~/utils/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);
function CategoryItem({ child, onUpdate, onRemove }) {
    const navigate = useNavigate();
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState('');
    const [key, setKey] = useState('');
    const [order, setOrder] = useState(0);
    useEffect(() => {
        setName(child.category_name);
        setKey(child.category_key);
        setOrder(child.category_order);
    }, []);
    const handleClickEdit = () => {
        setEdit(true);
    };
    const handleEditSave = () => {
        const apiSave = async () => {
            const res = await adminServices.updateCategory({
                action: 'category',
                id: child.category_id,
                name: name,
                key: key,
                order: order
            });
            if (res.status) {
                onUpdate({
                    category_parent: child.category_parent,
                    category_id: child.category_id,
                    category_name: name,
                    category_key: key,
                    category_order: order,
                });
                setEdit(false);
            } else {
                alert(res.message);
            }
        };
        apiSave();
    };
    const handleEditCancel = () => {
        setEdit(false);
    };

    const handleRemove = (child) => {
        if (confirm(`Bạn có chắc muốn xóa danh mục ${child.category_name} này?`)) {
            onRemove(child);
        }
    }

    const handleClickDetail = () => {
        navigate(
            `/super-admin/category/${child.category_parent}/${child.category_key}`,
        )
    }
    return (
        <>
            {edit === true ? (
                <tr>
                    <td colSpan={5}>
                        <div className="edit-row">
                            <span className="col-auto">
                                <span className="small-label">Tên danh mục:</span>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </span>
                            <span className="col-auto">
                                <span className="small-label">Key:</span>
                                <input type="text" value={key} onChange={(e) => setKey(e.target.value)} />
                            </span>
                        </div>
                        <div className="edit-row">
                            <span className="col-auto">
                                <span className="small-label">Sắp xếp:</span>
                                <input type="number" value={order} onChange={(e) => setOrder(e.target.value)} />
                            </span>
                            <button className="btn-save" onClick={handleEditSave}>
                                <span>Lưu</span>
                            </button>
                            <button className="btn-cancel" onClick={handleEditCancel}>
                                <span>Hủy</span>
                            </button>
                        </div>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td>— {child.category_name}</td>
                    <td>{child.category_key}</td>
                    <td className="text-center">
                        <span className={cx('store')}>{child.category_order}</span>
                    </td>
                    <td className="text-right">
                        <div className={cx('btn-wrapper')}>
                            <button
                                className={cx('see')}
                                onClick={handleClickDetail}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faEye} />
                                </span>
                                <span> Sản phẩm</span>
                            </button>
                            <button
                                className={cx('btn-edit')}
                                onClick={handleClickEdit}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faEdit} />
                                </span>
                                <span> Chỉnh sửa</span>
                            </button>
                            <button
                                className={cx('danger')}
                                onClick={() => handleRemove(child)}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </span>
                                <span> Xóa</span>
                            </button>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}

export default CategoryItem;
