import { useEffect, useState } from 'react';
import * as campServices from '~/apiServices/campServices';
import classNames from 'classnames/bind';
import styles from './CampOrder.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from '~/utils/filters';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Head from '../Head';
const cx = classNames.bind(styles);

function CampOrder() {
    const navigate = useNavigate();
    const [dataList, setDataList] = useState([]);
    useEffect(() => {
        document.body.style.overflow = 'visible';
        const fetchCamps = async () => {
            const response = await campServices.getOrders();
            if (response.length > 0) {
                setDataList(response);
            }
        };
        fetchCamps();
    }, []);

    const handleClickDetail = (campId) => {
        navigate(`/camp-order/${campId}`);
    };
    return (
        <>
            <div className={cx('camp')}>
                <h2 className="page-title">
                    Lều <span>{moment().format('DD/MM/YYYY')}</span>
                </h2>
                <Head />
                <div className={cx('camp-list')}>
                    <div className={cx('orders')}>
                        {dataList?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={cx('order-item')}
                                    onClick={() => handleClickDetail(item.camp_id)}
                                >
                                    <div className={cx('order-row')}>
                                        <div className={cx('name')}>
                                            <strong>
                                                #{item.camp_id} - {item.name}
                                            </strong>
                                            <br></br>
                                            <span>{item.phone}</span>
                                        </div>
                                        <div className={cx('datetime')}>
                                            <code>{moment(item.check_in).format('DD-MM-YYYY')}</code>
                                            <br></br>
                                            <code>{moment(item.check_out).format('DD-MM-YYYY')}</code>
                                        </div>
                                    </div>
                                    <div className={cx('order-row')}>
                                        <span>
                                            Cọc: <strong>{formatPrice(item.deposit)}</strong>
                                        </span>
                                        <button className="btn-detail">
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </button>
                                    </div>
                                    <p>Chú thích: {item.note}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CampOrder;
