import classNames from 'classnames/bind';
import styles from './Product.module.scss';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
const cx = classNames.bind(styles);
function ProductNav({ title = '' }) {
    const navigate = useNavigate();
    const productLink = `/super-admin/products`;
    const handleProduct = (e) => {
        e.preventDefault();
        navigate(productLink);
    }

    return (
        <div className={cx('product-nav')}>
            <h2 className="page-title">
                <div><a href={productLink} onClick={handleProduct}>Sản phẩm</a> {title ? `/ ${title}` : ''}</div>
            </h2>
        </div >
    );
}

export default ProductNav;