import classNames from 'classnames/bind';
import styles from './PrintReceipt.module.scss';
import { useEffect, useState } from 'react';
const cx = classNames.bind(styles);
import * as homeServices from '~/apiServices/homeServices';
import { useParams } from 'react-router-dom';
import logoVietUc from '~/assets/logo/vietuc.png';
import { yearNow } from '~/utils/filters';
function PrintReceipt() {
    const { receiptId } = useParams();
    const [data, setData] = useState({});
    const [total, setTotal] = useState(0);
    const fetchApi = async () => {
        const response = await homeServices.getReceipt({
            params: {
                receipt_id: receiptId
            }
        });
        if (response) {
            setData(response);
            if (response.details?.length > 0) {
                setTotal(response.details.length);
            }
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    const handleMessage = (event) => {
        if (event.data.action === 'print-receipt') {
            console.log('print-receipt');
            window.focus();
            window.print();
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const year = yearNow();
    return (
        <>
            <div className={cx('print-container')}>
                <div className={cx('print-header')}>
                    <div className={cx('head-left')}>
                        <img id="qrcode-payment" src={logoVietUc} width={120} />
                        <h1>VIỆT ÚC</h1>
                    </div>
                    <div className={cx('head-right')}>
                        <h3>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</h3>
                        <p>Độc lập - Tự  do - Hạnh phúc</p>
                        <p>Kon Plong, ngày .....tháng .....năm {year}</p>
                    </div>
                </div>
                <div className={cx('print-body')}>
                    <div className={cx('print-sub-head')}>
                        <h2 className={cx('text-center')}>
                            PHIẾU XUẤT KHO
                        </h2>
                        <p><strong>1. Người đề xuất:</strong> {data?.proposer}</p>
                        <p><strong>2. Bộ phận:</strong> {data?.department}</p>
                        <p><strong>3. Mục đích sử dụng trang thiết bị:</strong></p>
                        <p>Phục vụ cho:  {data?.purpose}</p>
                        <p><strong>4. Danh mục trang thiết bị:</strong> </p>
                    </div>
                    <div className={cx('table-wraper')}>
                        <table>
                            <thead>
                                <tr>
                                    <th width={50} className="text-center">STT</th>
                                    <th width={250}>SẢN PHẦM</th>
                                    <th className="text-center" width={150}>SỐ LƯỢNG</th>
                                    <th className="text-center">GHI CHÚ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.details?.length > 0 ? (
                                    <>
                                        {data.details?.map((item, index) => {
                                            return (
                                                <tr key={index} className={cx('tr-border')}>
                                                    <td className="text-center">
                                                        <span>{index + 1}</span>
                                                    </td>
                                                    <td>
                                                        <span>{item.ingredient_name}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span>{item.quantity} {item.ingredient_unit}</span>
                                                    </td>
                                                    <td><span>{item.note}</span></td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                ) : (
                                    null
                                )}
                                <tr>
                                    <td colSpan={4} className="text-center">TỔNG CÓ {total} SẢN PHẨM ĐỀ XUẤT</td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="text-center"><strong>*Lưu ý: Vui lòng xuất kho trước ……giờ...…phút, ngày ..…/..…/{year}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={cx('print-footer')}>
                    <div className={cx('footer-wraper')}>
                        <div className={cx('footer-col')}>
                            <p>Ngày …/…/{year}</p>
                            <p><strong>Người đề xuất</strong></p>
                        </div>
                        <div className={cx('footer-col')}>
                            <p>Ngày …/…/{year}</p>
                            <p><strong>Quản lý bộ phận</strong></p>
                        </div>
                        <div className={cx('footer-col')}>
                            <p>Ngày …/…/{year}</p>
                            <p><strong>Ban Giám đốc</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PrintReceipt;
