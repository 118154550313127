import classNames from 'classnames/bind';
import styles from './Store.module.scss';
import { useNavigate } from "react-router-dom";
const cx = classNames.bind(styles);
function StoreNav({ title = '' }) {
    const navigate = useNavigate();
    const current = location.pathname;
    const stockLink = `/super-admin/stores`;
    const handleReceipts = () => {
        navigate('/super-admin/receipts');
    }

    const handleStockIn = () => {
        navigate('/super-admin/stock-in');
    }

    const handleStockOut = () => {
        navigate('/super-admin/stock-out');
    }

    const handleStockHistory = () => {
        navigate('/super-admin/stock-history');
    }

    const handleChangeNav = (active) => {
        navigate(active);
    };
    return (
        <div className={cx('store-nav')}>
            <h2 className="page-title">
                <span><a href={stockLink} onClick={(e) => {
                    e.preventDefault();
                    navigate(stockLink);
                }}>Kho</a> {title ? `/ ${title}` : ''}</span>
                <div className={cx('nav-row')}>
                    <button
                        className={cx('btn', { active: current === '/super-admin/receipts' })}
                        onClick={() => handleChangeNav('/super-admin/receipts')}
                    >
                        <span> Phiếu nhập kho</span>
                    </button>
                    <button
                        className={cx('btn', { active: current === '/super-admin/stock-in' })}
                        onClick={() => handleChangeNav('/super-admin/stock-in')}
                    >
                        <span> Nhập kho</span>
                    </button>
                    <button
                        className={cx('btn', { active: current === '/super-admin/stock-out' })}
                        onClick={() => handleChangeNav('/super-admin/stock-out')}
                    >
                        <span> Xuất kho</span>
                    </button>
                    <button
                        className={cx('btn', { active: current === '/super-admin/stock-history' })}
                        onClick={() => handleChangeNav('/super-admin/stock-history')}
                    >
                        <span> Lịch sử nhập kho</span>
                    </button>
                </div>
            </h2>
        </div >
    );
}

export default StoreNav;