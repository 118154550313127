import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './TicketList.module.scss';
import * as ticketServices from '~/apiServices/ticketServices';
import { formatPrice, timeAgo } from '~/utils/filters';
import moment from 'moment';
import Modal from '~/components/Modal';
import { useNavigate } from 'react-router-dom';
import { TICKET_STATUS } from '~/utils/constants';
const cx = classNames.bind(styles);
function TicketList({ datas, onClickDetail, onClickPrint }) {
    const navigate = useNavigate();
    const [tableList, setTableList] = useState([]);
    const [show, setShow] = useState(false);
    const [ticket, setTicket] = useState({});

    const fetchTables = async () => {
        const response = await ticketServices.getTables();
        if (response.length > 0) {
            setTableList(response);
        }
    };
    useEffect(() => {}, []);

    const handleShowModal = (e, item) => {
        e.stopPropagation();
        if (item.ticket_status === 1) return navigate(`/table-detail/ticket/${item.table_id}/${item.ticket_id}`);
        setShow(true);
        setTicket(item);
        fetchTables();
    };
    const handleChoiseTable = (table) => {
        const apiUpdate = async () => {
            const response = await ticketServices.bookingBegin({
                table_id: Number(table.table_id),
                ticket_id: Number(ticket.ticket_id),
            });
            if (response) navigate(`/table-detail/ticket/${table.table_id}/${ticket.ticket_id}`);
        };
        if (table.table_status === 0) apiUpdate();
    };
    return (
        <>
            <div className={cx('tickets')}>
                <div className={cx('ticket')}>
                    {datas?.map((item, index) => {
                        return (
                            <div key={index} className={cx('ticket-item')} onClick={(e) => handleShowModal(e, item)}>
                                <div className={cx('ticket-row')}>
                                    <div className={cx('number')}>{item.ticket_number}</div>
                                    <div className={'ticket-group'}>
                                        <div className={cx('type')}>
                                            {item.ticket_name} - {item.ticket_id}
                                        </div>
                                        <div className={cx('phone')}>{item.ticket_phone}</div>
                                    </div>
                                    <div className={cx('date-group')}>
                                        <span className={cx('date')}>{moment(item.created_at).format('DD-MM')}</span>
                                        <span className={cx('time-ago')}>{timeAgo(item.created_at)}</span>
                                    </div>
                                </div>
                                <div className={cx('btn-group')}>
                                    <span className={cx('status', `status-${item.ticket_status}`)}>
                                        {TICKET_STATUS[item.ticket_status]}
                                    </span>
                                    {item.table_name !== '' ? (
                                        <span className={cx('table-name')}>{item.table_name}</span>
                                    ) : (
                                        ''
                                    )}

                                    <div className={cx('price-group')}>
                                        {item.discounts.length ? (
                                            <div className={cx('price-sale')}>
                                                {formatPrice(item.ticket_price * item.ticket_number - item.discount)}đ
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className={cx('price', { discount: item.discounts.length })}>
                                            {formatPrice(item.ticket_price * item.ticket_number)}đ
                                        </div>
                                    </div>
                                </div>
                                {item.discounts?.length > 0 ? (
                                    <div className={cx('loyal-customers')}>
                                        <table>
                                            <tbody>
                                                <tr className="trhead">
                                                    <td colSpan={3}>KH.Thân Thiết</td>
                                                </tr>
                                                {item.discounts?.map((item, index) => {
                                                    return (
                                                        <tr key={index} className={cx('tr-border')}>
                                                            <td className="text-center">{item.customer_id}</td>
                                                            <td>
                                                                <strong className={cx('name')}>
                                                                    {item.customer_name}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong className={cx('phone')}>
                                                                    {item.customer_phone}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <Modal title={`Chọn bàn - ${ticket.ticket_number} Khách`} show={show} setShow={setShow}>
                <div className={cx('row')}>
                    {tableList.map((table, index) => {
                        return (
                            <span className={cx('col')} key={index} onClick={() => handleChoiseTable(table)}>
                                <span className={cx('status', `status-${table.table_status}`)}>{table.table_key}</span>
                            </span>
                        );
                    })}
                </div>
            </Modal>
        </>
    );
}
export default TicketList;
