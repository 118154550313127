import classNames from 'classnames/bind';
import styles from './Water.module.scss';
import { dateNow, formatPrice } from '~/utils/filters';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as adminServices from '~/apiServices/adminServices';
import { useEffect, useState } from 'react';
const cx = classNames.bind(styles);
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);
function Water() {
    const d = new Date();
    const [date, setDate] = useState(d);
    const [dataList, setDataList] = useState([]);
    const fetchApi = async () => {
        const response = await adminServices.getWaters();
        if (response) {
            setDataList(response);
        }
    };

    useEffect(() => {
        //fetchApi();
    }, []);

    const handleDateChange = (date) => {
        setDate(date);
    };

    const handleSearch = () => {
        console.log(date);
        if (date === '' || null) return;
        const filterApi = async () => {
            const response = await adminServices.getWaters({
                params: {
                    date: moment(date).format('YYYY-MM-DD'),
                },
            });
            if (response) {
                setDataList(response);
            }
        };
        filterApi();
    };

    return (
        <>
            <div className={cx('bbqs')}>
                <h2 className="page-title">
                    Danh sách đơn nước <span>{dateNow()}</span>
                </h2>
                <div className={cx('bbq-content')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <div className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faCalendarDay} />
                                    </span>
                                    <span className={cx('input-label')}>Ngày sử dụng:</span>
                                    <span className={cx('input-value')}>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={date}
                                            onChange={handleDateChange}
                                            placeholderText="Ngày sử dụng"
                                            locale="vi"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            shouldCloseOnSelect={true}
                                        />
                                    </span>
                                </div>
                            </div>
                            <button className="btn-primary" onClick={handleSearch}>
                                Tìm
                            </button>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th className="text-center">Khách</th>
                                <th>Thông tin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList?.map((item, index) => {
                                return (
                                    <tr key={index} className={cx('tr-border')}>
                                        <td className="text-center">
                                            <strong>{item.fit_name}</strong>
                                            <br></br>
                                            <strong>{item.fit_id}</strong>
                                            <br></br>
                                            <span>{moment(item.created_at).format('DD-MM-YYYY')}</span>
                                        </td>
                                        <td>
                                            <div className={cx('info')}>
                                                <span className={cx('table')}>{item.table_name}</span>
                                            </div>
                                            <div className={cx('orders')}>
                                                {item.orders?.map((item, index) => {
                                                    return (
                                                        <div key={index} className={cx('order-item')}>
                                                            <span>{item.product_name}</span>
                                                            <div className={cx('price-group')}>
                                                                <span className={cx('quantity')}>
                                                                    x {item.quantity}
                                                                </span>
                                                                <strong className={cx('price')}>
                                                                    {formatPrice(item.display_price)}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className={cx('prices')}>
                                                <div className={cx('data-flex-end')}>
                                                    <strong className={cx('price')}>
                                                        {formatPrice(item.fit_price)}đ
                                                    </strong>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Water;
