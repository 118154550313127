import classNames from 'classnames/bind';
import styles from '../Receipt.module.scss';
import * as homeServices from '~/apiServices/homeServices';
import { Fragment, useEffect, useState } from 'react';
import { dateFormat } from '~/utils/filters';
import { useNavigate, useParams } from 'react-router-dom';
import ReceiptDetailItem from './ReceiptDetailItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFlag, faHourglass, faPrint, faReceipt, faSave } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ReceiptProcessing from './ReceiptProcessing';
import notify from '~/utils/notify';
const cx = classNames.bind(styles);
function ReceiptDetail() {
    const navigate = useNavigate();
    const { receiptId } = useParams();
    const [key, setKey] = useState(0);
    const [status, setStatus] = useState(0);
    const [data, setData] = useState('');
    const [dataSource, setDataSource] = useState([]);

    const fetchApi = async () => {
        const response = await homeServices.getReceipt({
            params: {
                receipt_id: receiptId
            }
        });
        if (response) {
            setData(response);
            setStatus(response.status);
            if (response.details) {
                setDataSource(response.details);
            }
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);


    const handlePrint = () => {
        const f = document.getElementById('iframe-receipt');
        const w = f.contentWindow;
        w.postMessage({ action: 'print-receipt' }, f.src);
    }

    const handleDateChange = (id, value) => {
        setDataSource(prevDatas => {
            const newDatas = prevDatas.map((obj) => {
                if (obj.id == id) {
                    return { ...obj, expiry_date: value }
                }
                return obj;
            })
            return newDatas;
        })
    }

    const handleUpdateExpiryDate = () => {
        if (status <= 1) {
            const checkExistDate = dataSource.every(d => d.expiry_date != null);
            if (!checkExistDate) {
                notify.error("Chưa nhập hạn sử dụng, hạn sử dụng phải được nhập đầy đủ!");
                return;
            }
            if (confirm(`Xác nhận cập nhật hạn sử dụng cho phiếu này?`)) {
                const newDatas = dataSource.map(d => {
                    return {
                        ingredient_id: d.ingredient_id,
                        quantity: d.quantity,
                        expiry_date: moment(d.expiry_date).format('YYYY-MM-DD')
                    }
                });
                const updateApi = async () => {
                    const res = await homeServices.updateReceipt({
                        action: 'expiry-date',
                        receipt_id: Number(receiptId),
                        datas: newDatas
                    });
                    if (res.status) {
                        setKey(key + 1);
                        setStatus(1);
                        notify.success(res.message);
                    } else {
                        notify.error(res.message);
                    }
                };
                updateApi();
            }
        }
    }

    const handleUpdateApprovalReport = () => {
        if (confirm(`Xác nhận báo cáo quản lý duyệt cho phiếu này?`)) {
            const updateApi = async () => {
                const res = await homeServices.updateReceipt({
                    action: 'approval-report',
                    receipt_id: Number(receiptId),
                });
                if (res.status) {
                    setStatus(2);
                    setKey(key + 1);
                    notify.success(res.message);
                } else {
                    notify.error(res.message);
                }
            };
            updateApi();
        }
    }
    return (
        <div className={cx('receipt')}>
            <iframe
                id="iframe-receipt"
                src={`/print-receipt/${receiptId}`}
                style={{ display: 'none' }}
                title="PRINT RECEIPT"
            />
            <h2 className="page-title">Phiếu nhập nguyên liệu <span>{dateFormat(data?.date)}</span></h2>
            <div className={cx('receipt-list')}>
                <div className={cx('receipt-body')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <label className="col">
                                <p>1. Người đề xuất: <strong>{data?.proposer}</strong></p>
                                <p>2. Bộ phận: <strong>{data?.department}</strong></p>
                                <p>3. Mục đích sử dụng trang thiết bị:</p>
                                <p>Phục vụ cho: <strong>{data?.purpose}</strong></p>
                            </label>
                        </div>
                        <ReceiptProcessing key={key} status={status} />
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th>Tên</th>
                                    <th>S.Lượng</th>
                                    <th>Phần</th>
                                    <th>Hạn Sử Dụng</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSource?.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <ReceiptDetailItem item={item} index={index} status={data?.status} onDateChange={handleDateChange} />
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={() => navigate(`/ingredient-list`)}>
                                <span>
                                    <FontAwesomeIcon icon={faReceipt} />
                                </span>
                                <span>KHO</span>
                            </button>
                            <button className={cx('btn-save')} onClick={handlePrint}>
                                <span>
                                    <FontAwesomeIcon icon={faPrint} />
                                </span>
                                <span>IN PHIẾU</span>
                            </button>

                            {
                                status >= 0 ?
                                    <>
                                        {
                                            status >= 1
                                                ?
                                                <button className={cx('btn-save')} onClick={handleUpdateExpiryDate} style={{ color: "#4caf50" }}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </span>
                                                    <span>ĐÃ CẬP NHẬT</span>
                                                    <span>HẠN SỬ DỤNG</span>
                                                </button>
                                                :
                                                <button className={cx('btn-save')} onClick={handleUpdateExpiryDate} style={{ color: "#ff5722" }}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faHourglass} />
                                                    </span>
                                                    <span>CẬP NHẬT</span>
                                                    <span>HẠN SỬ DỤNG</span>
                                                </button>
                                        }
                                    </>

                                    : ''
                            }
                            {
                                status >= 1 ?
                                    <>
                                        {
                                            status >= 2
                                                ?
                                                <button className={cx('btn-save')} style={{ color: "#4caf50" }}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </span>
                                                    <span>ĐÃ BÁO QUẢN</span>
                                                    <span>LÝ DUYỆT</span>
                                                </button>
                                                :
                                                <button className={cx('btn-save')} onClick={handleUpdateApprovalReport} style={{ color: "#ff5722" }}>
                                                    <span>
                                                        <FontAwesomeIcon icon={status >= 2 ? faCheck : faFlag} />
                                                    </span>
                                                    <span>BÁO QUẢN</span>
                                                    <span>LÝ DUYỆT</span>
                                                </button>
                                        }
                                    </>
                                    : ''
                            }
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReceiptDetail;
