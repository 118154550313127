import classNames from 'classnames/bind';
import styles from './ProductList.module.scss';
import { useContext, useEffect, useState } from 'react';
import * as productServices from '~/apiServices/productServices';
import { WATERS } from '~/utils/constants';
import ProductItem from './ProductItem';
import { BookContext } from '~/context/BookContext';
import Product from './Product/Product';
import { useParams } from 'react-router-dom';
const cx = classNames.bind(styles);
function ProductList({ onClick }) {
    const { type } = useParams();
    const [activeTab, setActiveTab] = useState('water');
    const { setShow, productList, setProductList } = useContext(BookContext);
    const [product, setProduct] = useState({});
    useEffect(() => {
        const fetchApi = async () => {
            const result = await productServices.products();
            setProductList(result);
        };
        if (productList.length === 0) fetchApi();
    }, []);

    const handleTabControl = (tab) => {
        setActiveTab(tab);
    };

    const tabControl = () => {
        const newTabs = [];
        for (const i in productList) {
            if (Object.hasOwnProperty.call(productList, i)) {
                const obj = productList[i];
                // if (type === 'camp' && obj.category_key === 'camp') {
                //     continue;
                // }
                newTabs.push({
                    key: obj.category_key,
                    label: obj.category_name,
                });
            }
        }
        return newTabs;
    };

    const handleClick = (product) => {
        setShow(true);
        setProduct(product);
    };

    const handleAddToCart = (product) => {
        onClick(product);
    };
    return (
        <>
            <div className={cx('product-list')}>
                <nav className={cx('head')}>
                    <div className={cx('tabs')}>
                        {tabControl().map((tab, index) => {
                            return (
                                <span
                                    key={index}
                                    className={cx('tab-item', { active: tab.key === activeTab })}
                                    onClick={(e) => {
                                        handleTabControl(tab.key);
                                    }}
                                >
                                    {tab.label}
                                </span>
                            );
                        })}
                    </div>
                </nav>
                {productList.map((obj) => {
                    return (
                        <div
                            key={obj.category_key}
                            className={cx('tab-content', { active: obj.category_key === activeTab })}
                        >
                            <div className={cx('product-section')}>
                                {obj.products?.map((product, index) => {
                                    if (product.key === 'camp') return false;
                                    return (
                                        <div key={product.key} className={cx('product-row')}>
                                            <h4>{product.title}</h4>
                                            {product.datas.map((item) => {
                                                return (
                                                    <ProductItem
                                                        key={item.product_id}
                                                        item={item}
                                                        onClick={() => handleClick(item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <Product item={product} onClick={handleAddToCart} />
            <div className={cx('fragment')}></div>
        </>
    );
}

export default ProductList;
