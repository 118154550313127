import * as adminServices from '~/apiServices/adminServices';
import { createContext, useEffect, useState } from 'react';
import { CampingReducer } from '~/reducer/CampingReducer';
export const CampingContext = createContext({});

export const CampingProvider = ({ children }) => {
    const [step, setStep] = useState(1);
    const [side, setSide] = useState(false);
    const [camping, campingDispatch] = CampingReducer();

    useEffect(() => {
        const fetchTent = async () => {
            const response = await adminServices.getCampingProducts();
            if (response) {
                campingDispatch({
                    type: 'FETCH_TENTS',
                    payload: response,
                });
            }
        };
        if (camping.tents.length === 0) {
            fetchTent();
        }
    }, []);

    useEffect(() => {
        const fetchService = async () => {
            const response = await adminServices.getServiceProducts();
            if (response) {
                campingDispatch({
                    type: 'FETCH_PRODUCTLIST',
                    payload: response,
                });
            }
        };
        fetchService();
    }, []);

    const setProductList = (campIndex) => {
        campingDispatch({
            type: 'SET_PRODUCTLIST',
            payload: campIndex,
        });
    };

    const setInfo = (data) => {
        campingDispatch({
            type: 'SET_INFO',
            payload: data,
        });
    };

    const setSelect = (camps) => {
        campingDispatch({
            type: 'SELECT_CAMPING',
            payload: camps,
        });
    };

    const setDates = (dates) => {
        campingDispatch({
            type: 'ADD_DATES',
            payload: dates,
        });
    };

    const setServices = (services) => {
        campingDispatch({
            type: 'ADD_SERVICES',
            payload: services,
        });
    };

    const checkIsHoliday = (position) => {
        campingDispatch({
            type: 'CHECK_IS_HOLIDAY',
            payload: position,
        });
    };

    const setTent = (position) => {
        campingDispatch({
            type: 'SET_TENT',
            payload: position,
        });
    };

    const value = {
        camping,
        setProductList,
        setInfo,
        setSelect,
        setDates,
        setServices,
        checkIsHoliday,
        setTent,
        step,
        setStep,
        side,
        setSide,
    };
    return <CampingContext.Provider value={value}>{children}</CampingContext.Provider>;
};
