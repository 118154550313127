import { useEffect, useState } from 'react';
import TableItem from './TableItem';
import * as tableServices from '~/apiServices/tableServices';
import classNames from 'classnames/bind';
import styles from './Table.module.scss';
import TableModal from './TableModal';
const cx = classNames.bind(styles);

function Table() {
    const [tableList, setTableList] = useState([]);
    const [show, setShow] = useState(false);
    const [table, setTable] = useState({});
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        document.body.style.overflow = 'visible';
        const fetchTables = async () => {
            const response = await tableServices.getTables();
            if (response.length > 0) {
                setTableList(response);
            }
        };
        fetchTables();
    }, []);

    const fetchOrders = async (tableId) => {
        const response = await tableServices.getOrderBbqToday({
            params: {
                table_id: Number(tableId),
            },
        });
        if (response) {
            setOrders(response);
        }
    };

    const handleClickDetail = (table) => {
        setShow(true);
        setTable(table);
        fetchOrders(table.table_id);
    };
    return (
        <>
            <div className={cx('table-row')}>
                {tableList.map((item, index) => {
                    return <TableItem key={index} item={item} onClick={handleClickDetail} />;
                })}
            </div>
            <TableModal table={table} orders={orders} show={show} setShow={setShow} />
        </>
    );
}

export default Table;
