import classNames from 'classnames/bind';
import styles from '../Camping.module.scss';
import { Fragment, useContext, useEffect, useState } from 'react';
import { CampingContext } from '~/context/CampingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faListCheck, faTent } from '@fortawesome/free-solid-svg-icons';
import OrderItem from '../OrderItem';
const cx = classNames.bind(styles);
import * as adminServices from '~/apiServices/adminServices';
import { formatPrice } from '~/utils/filters';
import { CAMPING_PAYMENT_PERCENT } from '~/utils/constants';
import { useParams } from 'react-router-dom';
function Complete() {
    const { campId } = useParams();
    const [info, setInfo] = useState({});
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        const fetchApi = async () => {
            const result = await adminServices.getCamping({
                params: {
                    camp_id: campId,
                },
            });
            if (result) {
                setInfo(result.info);
                setDatas(result.datas);
            }
        };
        if (Object.keys(info).length === 0) fetchApi();
    }, []);

    const totalOrder = () => {
        let total = 0;
        for (let i in datas) {
            const item = datas[i];
            for (let o in item.services) {
                const serivce = item.services[o];
                total += Number(serivce.product_price) * Number(serivce.quantity);
            }
            for (let d in item.details) {
                const detail = item.details[d];
                const feeTent = Number(detail.product_price) * Number(item.adt);
                let feeExtra = 0;
                for (let e in detail.extras) {
                    const extra = detail.extras[e];
                    feeExtra += Number(extra.product_price) * Number(extra.quantity);
                }
                total += feeTent + feeExtra;
            }
        }
        return total;
    };
    const total = totalOrder();
    const deposit = total * CAMPING_PAYMENT_PERCENT;
    const remaining = total - deposit;
    return (
        <>
            <div className={cx('camping-confirm')}>
                <div className={cx('section')}>
                    <div className={cx('title-success')}>Thành công</div>
                    <h3>
                        <FontAwesomeIcon icon={faIdCard} />
                        <span> Thông tin đặt lều</span>
                    </h3>
                    <p>
                        <span className={cx('info-label')}>Họ và tên:</span> <strong>{info.name}</strong>
                    </p>
                    <p>
                        <span className={cx('info-label')}>Số điện thoại:</span> <strong>{info.phone}</strong>
                    </p>
                    <hr></hr>
                    {datas?.map((camp, campIndex) => {
                        return (
                            <div key={campIndex} className={cx('confirm-row')}>
                                <h4 className={cx('title')}>
                                    <FontAwesomeIcon icon={faTent} /> <span>{camp.table_name}</span>
                                </h4>
                                <p>
                                    <span className={cx('info-label')}>Người lớn:</span> <strong>{camp.adt}</strong>
                                </p>
                                <p>
                                    <span className={cx('info-label')}>Phát sinh:</span> <strong>{camp.aris}</strong>
                                </p>
                                <p>
                                    <span className={cx('info-label')}>Trẻ em 6 - 12:</span> <strong>{camp.chd}</strong>
                                </p>
                                <p>
                                    <span className={cx('info-label')}>
                                        Trẻ em <span>{'<'}</span> 6:
                                    </span>
                                    <strong> {camp.inf}</strong>
                                </p>
                                {camp.details?.map((item, itemIndex) => {
                                    return (
                                        <Fragment key={`${campIndex}-${itemIndex}`}>
                                            <div className={cx('tent-item')}>
                                                <span>
                                                    <strong className={cx('tent-date')}>{item.date}</strong>
                                                    <span> {item.product_name}</span>
                                                </span>
                                                <span className={cx('quantity')}>x {item.quantity}</span>
                                                <strong>{item.display_price}</strong>
                                            </div>
                                            {item.extras?.map((o, i) => {
                                                return <OrderItem key={i} order={o} />;
                                            })}
                                        </Fragment>
                                    );
                                })}
                                <h3>
                                    <FontAwesomeIcon icon={faListCheck} />
                                    <span> Dịch vụ</span>
                                </h3>
                                {camp.services?.map((o, i) => {
                                    return <OrderItem key={i} order={o} />;
                                })}
                                <p>
                                    <span>Ghi chú:</span> <strong>{camp.note}</strong>
                                </p>
                            </div>
                        );
                    })}
                    <table>
                        <tfoot>
                            <tr>
                                <td colSpan={2}>
                                    <strong>Tổng: </strong>
                                </td>
                                <td width={150} className="text-right">
                                    <span className={cx('total')}>{formatPrice(total)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <strong>Cọc: </strong>
                                </td>
                                <td width={150} className="text-right">
                                    <span className={cx('total')}>{formatPrice(deposit)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <strong>Còn lại: </strong>
                                </td>
                                <td width={150} className="text-right">
                                    <span className={cx('total')}>{formatPrice(remaining)}</span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <div className="note">
                        <p>
                            <span>Ghi chú:</span> <strong>{info.note}</strong>
                        </p>
                    </div>
                    <br></br>
                </div>
            </div>
        </>
    );
}

export default Complete;
