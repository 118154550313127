import classNames from 'classnames/bind';
import styles from '../BbqList.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const cx = classNames.bind(styles);
import * as bbqServices from '~/apiServices/bbqServices';
import * as tableServices from '~/apiServices/tableServices';
import { formatPrice } from '~/utils/filters';
import { BBQ_STATUS, WATERS } from '~/utils/constants';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEdit, faPenToSquare, faPrint, faSave, faTableList } from '@fortawesome/free-solid-svg-icons';
import Modal from '~/components/Modal';

function BbqDetail() {
    const navigate = useNavigate();
    const { bbqId } = useParams();
    const [info, setInfo] = useState({});
    const [datas, setDatas] = useState([]);
    const [editNote, setEditNote] = useState(false);
    const [note, setNote] = useState('');

    const [editDeposit, setEditDeposit] = useState(false);
    const [deposit, setDeposit] = useState(0);

    const [tableList, setTableList] = useState([]);
    const [show, setShow] = useState(false);
    useEffect(() => {
        const fetchApi = async () => {
            const result = await bbqServices.getBbq({
                params: {
                    bbq_id: bbqId,
                },
            });
            if (result) {
                setInfo(result.info);
                setDatas(result.datas);
                setDeposit(result.info.deposit);
            }
        };
        if (Object.keys(info).length === 0) fetchApi();
    }, []);

    const fetchTables = async () => {
        const response = await tableServices.getTables();
        if (response.length > 0) {
            setTableList(response);
        }
    };

    const headTitle = (type) => {
        return WATERS.find((obj) => {
            return obj.key === type;
        })?.label;
    };

    const totalOrder = (orders) => {
        let total = 0;
        for (let keyName in orders) {
            const product = orders[keyName];
            for (let i in product.datas) {
                const item = product.datas[i];
                total += item.product_price * item.quantity;
            }
        }

        return total;
    };

    const updateBbqStatus = (status) => {
        const apiUpdate = async () => {
            const res = await bbqServices.update({
                action: 'status',
                bbq_id: info.bbq_id,
                status: status,
            });
            if (res.status) {
                setInfo({ ...info, status: res.data });
            } else {
                alert(res.message);
            }
        };
        apiUpdate();
    };

    const handleEdit = () => {
        navigate(`/super-admin/bbq-edit/${bbqId}`);
    };

    const handleDeposit = () => {
        updateBbqStatus(1);
    };

    const handlePayment = () => {
        updateBbqStatus(2);
    };

    const handleCancel = () => {
        if (confirm('Bạn có chắc muốn hủy bàn này?')) {
            updateBbqStatus(3);
        }
    };

    const handleBbqProcessing = () => {
        const f = document.frames ? document.frames[id] : document.getElementById('iframe-bbq-processing');
        const w = f.contentWindow || f;
        w.postMessage({ action: 'bbq-print-processing' }, f.src);
    };

    const handleEditNote = () => {
        setEditNote(!editNote);
    };

    const handleUpdateNote = () => {
        const apiUpdate = async () => {
            const res = await bbqServices.update({
                action: 'note',
                bbq_id: info.bbq_id,
                note: note,
            });
            if (res.status) {
                setInfo({ ...info, note: note });
            } else {
                alert(res.message);
            }
        };
        if (note !== '') apiUpdate();
        setEditNote(false);
    };

    const handleUpdateDeposit = () => {
        const apiUpdate = async () => {
            const res = await bbqServices.update({
                action: 'deposit',
                bbq_id: info.bbq_id,
                deposit: deposit,
            });
            if (res.status) {
                setInfo({ ...info, deposit: res.data });
            } else {
                alert(res.message);
            }
        };
        apiUpdate();
        setEditDeposit(false);
    };

    const handleShowModal = () => {
        setShow(true);
        fetchTables();
    };
    const handleChoiseTable = (table) => {
        const apiUpdate = async () => {
            const res = await bbqServices.update({
                action: 'table',
                bbq_id: info.bbq_id,
                table_id: table.table_id,
            });
            if (res.status) {
                const data = res.data;
                setInfo({ ...info, table: data.table_name, table_id: data.table_id });
            } else {
                alert(res.message);
            }
        };
        apiUpdate();
        setShow(false);
    };

    const total = totalOrder(datas[1]);
    const remaining = total - deposit;

    return (
        <>
            <div className={cx('bbqs')}>
                <h3 className={cx('page-title')}>Chi tiết đặt bàn BBQ</h3>
                <div className={cx('bbq-content')}>
                    <div className={cx('bbq-info')}>
                        <p>
                            <span className={cx('bbq-label')}>Trạng Thái:</span>
                            <strong className={cx('status', `status-${info.status}`)}>{BBQ_STATUS[info.status]}</strong>
                        </p>
                        <p>
                            <span className={cx('bbq-label')}>Họ và Tên:</span> <strong>{info.name}</strong>
                        </p>
                        <p>
                            <span className={cx('bbq-label')}>SĐT:</span> <strong>{info.phone}</strong>
                        </p>
                        <p>
                            <span className={cx('bbq-label')}>Người lớn:</span> <strong>{info.adt}</strong>
                        </p>
                        <p>
                            <span className={cx('bbq-label')}>Trẻ em:</span> <strong>{info.chd}</strong>
                        </p>
                        <p>
                            <span className={cx('bbq-label')}>Ngày:</span>
                            <strong>{moment(info.date).format('DD-MM-YYYY')}</strong>
                        </p>
                        <p>
                            <span className={cx('bbq-label')}>Thời gian:</span>
                            <strong>
                                {info.hour}:{info.minute}
                            </strong>
                        </p>
                        {info.table ? (
                            <p>
                                <span className={cx('bbq-label')}>Bàn:</span>
                                <strong>{info.table}</strong>
                            </p>
                        ) : (
                            ''
                        )}
                        <p>
                            <span className={cx('bbq-label')}>Đã Cọc:</span>
                            <strong className={cx('bbq-table', { hide: editDeposit })}>
                                <FontAwesomeIcon icon={faPenToSquare} onClick={() => setEditDeposit(!editDeposit)} />{' '}
                                {formatPrice(info.deposit)}
                            </strong>
                            <span className={cx('edit-row', { hide: !editDeposit })}>
                                <input value={deposit} onChange={(e) => setDeposit(e.target.value)} />
                                <button onClick={handleUpdateDeposit}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                            </span>
                        </p>
                        <p>
                            <span className={cx('bbq-label')}>Ghi chú:</span>
                            <strong className={cx('bbq-note', { hide: editNote })}>
                                <FontAwesomeIcon icon={faPenToSquare} onClick={handleEditNote} /> {info.note}
                            </strong>
                            <span className={cx('edit-row', { hide: !editNote })}>
                                <textarea rows={2} defaultValue={info.note} onChange={(e) => setNote(e.target.value)} />
                                <button onClick={handleUpdateNote}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                            </span>
                        </p>
                    </div>
                    <div className={cx('btn-group')}>
                        <button
                            className={cx('btn-deposit', { disable: info.status >= 1 })}
                            disabled={info.status >= 1}
                            onClick={handleDeposit}
                        >
                            Đã Cọc
                        </button>
                        <button
                            className={cx('btn-table', { hide: info.status !== 1 })}
                            disabled={info.status !== 1}
                            onClick={handleShowModal}
                        >
                            Chọn bàn
                        </button>
                        <button
                            className={cx('btn-cancel', { disable: info.status >= 2 })}
                            disabled={info.status >= 2}
                            onClick={handleCancel}
                        >
                            Hủy Bàn
                        </button>
                    </div>
                    <Modal title={`Chọn bàn BBQ`} show={show} setShow={setShow}>
                        <div className={cx('row')}>
                            {tableList.map((table, index) => {
                                return (
                                    <span className={cx('col')} key={index} onClick={() => handleChoiseTable(table)}>
                                        <span className={cx('status', { active: table.table_id === info.table_id })}>
                                            {table.table_key}
                                        </span>
                                    </span>
                                );
                            })}
                        </div>
                    </Modal>
                    <div className={cx('order-list')}>
                        {Object.keys(datas).map((i) => {
                            const orders = datas[i];
                            const title = i == 0 ? 'Danh sách cũ' : 'Danh sách hiện tại';
                            return (
                                <div key={i} className={cx('order-item')} hidden={orders.length === 0}>
                                    <h3>
                                        <FontAwesomeIcon icon={faTableList} />
                                        {title}
                                    </h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>TÊN</th>
                                                <th className="text-center">SL</th>
                                                <th className="text-right">GIÁ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(orders)?.map((keyName) => {
                                                const product = orders[keyName];
                                                return (
                                                    <Fragment key={keyName}>
                                                        <tr
                                                            className={cx('trhead')}
                                                            hidden={
                                                                product.datas.filter((p) => p.quantity !== 0).length ===
                                                                0
                                                            }
                                                        >
                                                            <td colSpan={3}>{headTitle(keyName)}</td>
                                                        </tr>
                                                        {product.datas?.map((item, index) => {
                                                            return (
                                                                <tr
                                                                    key={index}
                                                                    className={cx('tr')}
                                                                    hidden={item.quantity === 0}
                                                                >
                                                                    <td width={150}>{item.product_name}</td>
                                                                    <td width={50} className="text-center">
                                                                        <span
                                                                            className={cx('quantity', {
                                                                                show: item.quantity >= 0,
                                                                            })}
                                                                        >
                                                                            {item.quantity}
                                                                        </span>
                                                                    </td>
                                                                    <td width={150} className="text-right">
                                                                        <span className={cx('price')}>
                                                                            {item.display_price}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            );
                        })}
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <strong>Tổng: </strong>
                                    </td>
                                    <td width={150} className="text-right">
                                        <span className={cx('total')}>{formatPrice(total)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <strong>Cọc: </strong>
                                    </td>
                                    <td width={150} className="text-right">
                                        <span className={cx('total')}>{formatPrice(deposit)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <strong>Còn lại: </strong>
                                    </td>
                                    <td width={150} className="text-right">
                                        <span className={cx('total')}>{formatPrice(remaining)}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br></br>
                        <br></br>
                    </div>

                    <iframe
                        key={info.table || 0}
                        id="iframe-bbq-processing"
                        src={`/super-admin/bbq-print-processing/${bbqId}`}
                        style={{ display: 'none' }}
                        title="BBQ PRINT PROCESSING"
                    />
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={() => navigate('/super-admin/bbqs')}>
                                <span>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                            </button>
                            <button className={cx('btn-edit', { hide: info.status === 2 })} onClick={handleEdit}>
                                <span>
                                    <FontAwesomeIcon icon={faEdit} />
                                </span>
                                Chỉnh sửa
                            </button>
                            <button
                                className={cx('btn-print', { hide: info.status !== 1 })}
                                onClick={handleBbqProcessing}
                                disabled={info.table_id === 0}
                            >
                                <span>
                                    <FontAwesomeIcon icon={faPrint} />
                                </span>
                                <span>IN Chế Biến</span>
                            </button>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BbqDetail;
