import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faChevronLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ReceiptContext } from './Receipt';
const cx = classNames.bind(styles);
function EnterQuantity() {
    const { selected, setSelected, setStep } = useContext(ReceiptContext);

    const handleQuantityChange = (quantity, ingredient_id) => {
        setSelected((prevDatas) => {
            const newDatas = prevDatas.map((obj) => {
                if (obj.ingredient_id === ingredient_id) {
                    return { ...obj, quantity: Number(quantity) };
                }
                return obj;
            });
            return newDatas;
        });
    };

    const handleSave = () => {
        const newDatas = selected.filter(s => s.quantity !== 0);
        if (newDatas.length == 0) {
            alert(`Chưa nhập số lượng`);
            return;
        }
        setStep(3);
    }
    return (
        <div className={cx('product')}>
            <h2 className="page-title">Nhập số lượng</h2>
            <div className={cx('product-list')}>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Tên Nguyên Liệu</th>
                                    <th className={cx('text-center')}>Tồn</th>
                                    <th>S.Lượng</th>
                                    <th>Phần</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selected?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width={250}>
                                                <strong className="name">{item.ingredient_name}</strong><br></br>
                                                <small>{item.ingredient_description}</small>
                                            </td>
                                            <td width={100} className={cx('text-center')}>
                                                <span><strong>{item.stock}</strong> <small>phần</small></span>
                                            </td>
                                            <td width={150}>
                                                <div className="table-row">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        value={item.quantity}
                                                        onChange={(e) => handleQuantityChange(e.target.value, item.ingredient_id)}
                                                        style={{ width: '100px' }}
                                                    />
                                                    <small className={cx('available')}>{item.ingredient_unit}</small>

                                                </div>
                                            </td>
                                            <td>
                                                <span><strong>{item.quantity * item.ingredient_part} </strong> <small>phần</small></span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={() => setStep(1)}>
                                <span>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                            </button>
                            <button className={cx('btn-save')} onClick={handleSave}>
                                <span>
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </span>
                                <span>TIẾP TỤC</span>
                            </button>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnterQuantity;
