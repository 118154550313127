import React from 'react';
import { Navigate, Outlet, Route, useLocation } from 'react-router-dom';
import { useAuth } from '~/context/AuthContext';
const ProtectedRoute = ({ redirectTo = '/login' }) => {
    const { loggedIn } = useAuth();
    const location = useLocation();
    if (loggedIn === null) {
        return <div>Loading...</div>; // Or a loading spinner
    }

    return loggedIn ? <Outlet /> : <Navigate to={redirectTo} state={{ from: location }} replace />;
};

export default ProtectedRoute;