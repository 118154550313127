import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
import * as homeServices from '~/apiServices/homeServices';
import { useEffect, useState } from 'react';
import { dateFormat, statusReceipt } from '~/utils/filters';
import { useNavigate } from 'react-router-dom';
import ReceiptNav from './ReceiptNav';
const cx = classNames.bind(styles);
function ReceiptList() {
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState([]);

    const fetchApi = async () => {
        const response = await homeServices.getReceipts();
        if (response) {
            setDataSource(response);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    const handleDetail = (detailId) => {
        navigate(`/receipt-list/${detailId}`);
    }

    return (
        <div className={cx('product')}>
            <ReceiptNav />
            <div className={cx('product-list')}>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th>Ngày</th>
                                    <th>Người đề xuất</th>
                                    <th className="text-center">T.Thái</th>
                                    <th>S.Lượng</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSource?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width={50} className="text-center">
                                                <span>{index + 1}</span>
                                            </td>
                                            <td width={150}>
                                                <span className="date">{dateFormat(item.date)}</span>
                                            </td>
                                            <td width={150}>
                                                <span>{item.proposer}</span>
                                            </td>
                                            <td width={150} className="text-center">
                                                <span>{statusReceipt(item.status)}</span>
                                            </td>
                                            <td width={150}>
                                                <span>{item.quantity}</span>
                                            </td>
                                            <td>
                                                <div className="nav-row">
                                                    <button className="btn btn-see-detail" onClick={() => handleDetail(item.receipt_id)}><span> Chi tiết</span></button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReceiptList;
