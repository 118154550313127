import * as request from '~/utils/request';

export const getBbqs = async (params) => {
    try {
        const res = await request.get('admin/bbq/get_bbqs.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getOrders = async (params) => {
    try {
        const res = await request.get('admin/statistic/get_orders.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getIngredients = async (params) => {
    try {
        const res = await request.get('admin/ingredient/get_ingredients.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addIngredient = async (data) => {
    try {
        const res = await request.post('admin/ingredient/add_ingredient.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const updateIngredient = async (data) => {
    try {
        const res = await request.post('admin/ingredient/update_ingredient.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const removeIngredient = async (data) => {
    try {
        const res = await request.post('admin/ingredient/remove.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getIngredientProducts = async (params) => {
    try {
        const res = await request.get('admin/ingredient/get_ingredient_products.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addProductIngredient = async (data) => {
    try {
        const res = await request.post('admin/ingredient/add_product_ingredient.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const removeProductIngredient = async (data) => {
    try {
        const res = await request.post('admin/ingredient/remove_product_ingredient.php', data);
        return res;
    } catch (error) {
        return error;
    }
};



export const getStores = async (params) => {
    try {
        const res = await request.get('admin/store/get_stores.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getStore = async (params) => {
    try {
        const res = await request.get('admin/store/get_store.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addStockIn = async (data) => {
    try {
        const res = await request.post('admin/store/add_stock_in.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const addStockOut = async (data) => {
    try {
        const res = await request.post('admin/store/add_stock_out.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getStockHistory = async (params) => {
    try {
        const res = await request.get('admin/store/get_stock_history.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getStockHistoryDetails = async (params) => {
    try {
        const res = await request.get('admin/store/get_stock_history_details.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getReceipts = async () => {
    try {
        const res = await request.get('admin/receipt/get_receipts.php');
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getReceipt = async (params) => {
    try {
        const res = await request.get('admin/receipt/get_receipt.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const acceptReceipt = async (data) => {
    try {
        const res = await request.post('admin/receipt/accept_receipt.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getBills = async (params) => {
    try {
        const res = await request.get('admin/bill/get_bills.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getBillDetail = async (params) => {
    try {
        const res = await request.get('admin/bill/get_detail.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getTickets = async (params) => {
    try {
        const res = await request.get('admin/ticket/get_tickets.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const removeTicket = async (data) => {
    try {
        const res = await request.post('admin/ticket/remove.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getLoyalCustomers = async (params) => {
    try {
        const res = await request.get('admin/customer/get_loyal_customers.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const removeCustomer = async (data) => {
    try {
        const res = await request.post('admin/customer/remove.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getCustomers = async (params) => {
    try {
        const res = await request.get('admin/customer/get_customers.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addCustomer = async (data) => {
    try {
        const res = await request.post('admin/customer/add.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

// CAMPING
export const getCampings = async (params) => {
    try {
        const res = await request.get('admin/camping/get_campings.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const getCamping = async (params) => {
    try {
        const res = await request.get('admin/camping/get_camping.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const getCampingProducts = async (params) => {
    try {
        const res = await request.get('admin/camping/get_camping_products.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const getServiceProducts = async (params) => {
    try {
        const res = await request.get('admin/camping/get_service_products.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const bookingCamping = async (data) => {
    try {
        const res = await request.post('admin/camping/booking.php', data);
        return res;
    } catch (error) {
        return error;
    }
};
export const updateCamping = async (data) => {
    try {
        const res = await request.post('admin/camping/update.php', data);
        return res;
    } catch (error) {
        return error;
    }
};
export const getCamps = async (params) => {
    try {
        const res = await request.get('admin/camp/get_camps.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

// WATER
export const getWaters = async (params) => {
    try {
        const res = await request.get('admin/water/get_waters.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

// PRODUCT
export const getCategories = async (params) => {
    try {
        const res = await request.get('admin/product/get_categories.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const getCategoryProducts = async (params) => {
    try {
        const res = await request.get('admin/product/get_category_products.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getProducts = async (params) => {
    try {
        const res = await request.get('admin/product/get_products.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const addProduct = async (data) => {
    try {
        const res = await request.post('admin/product/add.php', data);
        return res;
    } catch (error) {
        return error;
    }
};
export const updateProduct = async (data) => {
    try {
        const res = await request.post('admin/product/update.php', data);
        return res;
    } catch (error) {
        return error;
    }
};
export const removeProduct = async (data) => {
    try {
        const res = await request.post('admin/product/remove.php', data);
        return res;
    } catch (error) {
        return error;
    }
};
export const addCategory = async (data) => {
    try {
        const res = await request.post('admin/product/add_category.php', data);
        return res;
    } catch (error) {
        return error;
    }
};
export const updateCategory = async (data) => {
    try {
        const res = await request.post('admin/product/update_category.php', data);
        return res;
    } catch (error) {
        return error;
    }
};
export const removeCategory = async (data) => {
    try {
        const res = await request.post('admin/product/remove_category.php', data);
        return res;
    } catch (error) {
        return error;
    }
};