import classNames from 'classnames/bind';
import styles from './Product.module.scss';
import { dateNow, formatPrice } from '~/utils/filters';
import { Fragment, useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowRight, faChevronLeft, faLayerGroup, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ProductNav from './ProductNav';
import ProductItem from './ProductItem';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
const cx = classNames.bind(styles);
function Product() {
    const navigate = useNavigate();
    const [productList, setProductList] = useState([]);
    const fetchApi = async () => {
        const response = await adminServices.getProducts();
        if (response) {
            setProductList(response);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    const handleClickAddNew = () => { };

    const handleEditSave = (productId, data) => {
        setProductList((prevProductList) => {
            const newProductList = prevProductList?.map((obj) => {
                if (obj.product_id === productId) {
                    return {
                        ...obj,
                        product_id: data.product_id,
                        product_name: data.product_name,
                        product_unit: data.product_unit,
                        product_order: data.product_order,
                        product_price: data.product_price,
                        display_price: data.display_price,
                    };
                }
                return obj;
            });
            return newProductList;
        });
    };

    const handleRemove = (productId) => {
        const removeApi = async () => {
            const res = await adminServices.removeProduct({
                product_id: productId,
            });
            if (res.status) {
                const newDataList = productList.filter((obj) => {
                    return obj.product_id !== productId;
                });
                setProductList(newDataList);
            } else {
                alert(res.message);
            }
        };
        removeApi();
    }

    return (
        <>
            <ProductNav />
            <div className={cx('admin-body')}>
                <div className={cx('stores')}>
                    <table>
                        <thead>
                            <tr>
                                <th>Tên Sản Phẩm</th>
                                <th>Danh Mục</th>
                                <th>Giá</th>
                                <th>Giá Hiển Thị</th>
                                <th>Đơn Vị</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productList?.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <ProductItem item={item} onSave={handleEditSave} onRemove={handleRemove} />
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Product;
