import classNames from 'classnames/bind';
import styles from './NavMenu.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBurger, faFileInvoice, faMugHot, faTent, faTicket, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { dateNow } from '~/utils/filters';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
const cx = classNames.bind(styles);
function NavMenu() {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState('/settings');

    useEffect(() => {
        setActive(location.pathname);
    }, ['/']);

    const handleChangeNav = (active) => {
        setActive(active);
        navigate(active);
    };
    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">
                    Đơn hàng <span>{dateNow()}</span>
                </h2>
            </div>
            <div className={cx('admin-body')}>
                <div className={cx('row')}>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-bbq', { active: active === '/super-admin/bbq' })}
                            onClick={() => handleChangeNav('/super-admin/bbq')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faBurger} />
                            </span>
                            <span>BBQ</span>
                        </button>
                    </div>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-waters', { active: active === '/super-admin/waters' })}
                            onClick={() => handleChangeNav('/super-admin/waters')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faMugHot} />
                            </span>
                            <span>NƯỚC</span>
                        </button>
                    </div>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-camp', { active: active === '/super-admin/camp' })}
                            onClick={() => handleChangeNav('/super-admin/camp')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faTent} />
                            </span>
                            <span>LỀU TRẠI</span>
                        </button>
                    </div>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-tickets', { active: active === '/super-admin/tickets' })}
                            onClick={() => handleChangeNav('/super-admin/tickets')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faTicket} />
                            </span>
                            <span>VÉ</span>
                        </button>
                    </div>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-bills', { active: active === '/super-admin/bills' })}
                            onClick={() => handleChangeNav('/super-admin/bills')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faFileInvoice} />
                            </span>
                            <span>HÓA ĐƠN</span>
                        </button>
                    </div>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-loyal-customers', { active: active === '/super-admin/loyal-customers' })}
                            onClick={() => handleChangeNav('/super-admin/loyal-customers')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faUserAlt} />
                            </span>
                            <span>KH. THÂN THIẾT</span>
                        </button>
                    </div>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-seller-customers', {
                                active: active === '/super-admin/seller-customers',
                            })}
                            onClick={() => handleChangeNav('/super-admin/seller-customers')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faUserAlt} />
                            </span>
                            <span>NHÂN VIÊN SELLER</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavMenu;
