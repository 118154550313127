import moment from 'moment';
import { useReducer } from 'react';
import { getTentFordate } from '~/utils/filters';
const initialState = {
    info: {
        name: '',
        phone: '',
        check_in: '',
        check_out: '',
    },
    select: [],
    dates: [],
    tents: [],
    surcharges: [],
    productList: [],
};

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'FETCH_TENTS':
            return {
                ...state,
                tents: payload.tents,
                surcharges: payload.surcharge,
            };
        case 'FETCH_PRODUCTLIST':
            return {
                ...state,
                productList: payload,
            };
        case 'SET_PRODUCTLIST':
            return {
                ...state,
                productList: payload,
            };
        case 'SELECT_CAMPING':
            return {
                ...state,
                select: payload,
            };
        case 'SET_INFO':
            return {
                ...state,
                info: payload,
            };
        case 'ADD_DATES':
            return {
                ...state,
                dates: payload,
            };

        case 'ADD_SERVICES': {
            const { campIndex, services } = payload;
            const newSelect = state.select.map((obj, c) => {
                if (c === campIndex) {
                    const newServices = [...services];
                    return { ...obj, orders: newServices };
                }
                return obj;
            });
            return {
                ...state,
                select: newSelect,
            };
        }
        case 'CHECK_IS_HOLIDAY': {
            const { campIndex, itemIndex, isHoliday, tent } = payload;

            const newSelect = state.select.map((obj, c) => {
                if (c === campIndex) {
                    const newDatas = obj.datas.map((item, i) => {
                        if (i === itemIndex) {
                            const tent = getTentFordate(state.tents, item.date, isHoliday);
                            return { ...item, isHoliday: isHoliday, tent: tent };
                        }
                        return item;
                    });
                    return { ...obj, datas: newDatas };
                }
                return obj;
            });
            return {
                ...state,
                select: newSelect,
            };
        }
        case 'SET_TENT': {
            const { campIndex, itemIndex, tent, indexTent } = payload;
            const newSelect = state.select.map((obj, c) => {
                if (c === campIndex) {
                    const newDatas = obj.datas.map((item, i) => {
                        if (i === itemIndex) {
                            const surcharge = state.surcharges[indexTent];
                            return { ...item, tent: tent, surcharge: surcharge };
                        }
                        return item;
                    });
                    return { ...obj, datas: newDatas };
                }
                return obj;
            });
            return {
                ...state,
                select: newSelect,
            };
        }
        default:
            return state;
    }
};

export const CampingReducer = () => {
    return useReducer(reducer, initialState);
};
