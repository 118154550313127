import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');
import { CUSTOMER_TYPE, ORDER_TAGS, PAYMENT_METHOD } from './constants';
import { faCircleCheck, faRoadCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const customerLabel = (type) => {
    return CUSTOMER_TYPE.find((t) => t.key === type)?.label || '';
};

export const orderTag = (type) => {
    return ORDER_TAGS.find((t) => t.key === type)?.label || '';
};

export const formatPrice = (value) => {
    if (!value) {
        return 0;
    }
    return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
};

export const dateNow = () => {
    return moment().format('DD/MM/YYYY');
};

export const yearNow = () => {
    return moment().format('YYYY');
};

export const dateFormat = (value) => {
    if (!value) {
        return '-';
    }
    return moment(value).format('DD/MM/YY');
};

export const timeAgo = (value) => {
    if (!value) {
        return '-';
    }
    return moment(value).fromNow();
};

export const timeIn = (value) => {
    if (!value) {
        return '-';
    }
    return moment(value).format('DD/MM/YY hh:mm');
};

export const timePrint = () => {
    return moment().format('hh:mm');
};

export const mergeCustomers = (arr1, arr2) => {
    let combinedArr = [];

    arr1.forEach((item) => {
        if (!arr2.some((obj) => obj.customer_id === item.customer_id)) {
            combinedArr.push(item);
        }
    });

    return combinedArr.concat(arr2);
};

export const mergeOrders = (arr1, arr2) => {
    let combinedArr = [];

    arr1.forEach((item) => {
        if (!arr2.some((obj) => obj.order_id === item.order_id)) {
            combinedArr.push(item);
        }
    });

    return combinedArr.concat(arr2);
};

export const paymentName = (value) => {
    return PAYMENT_METHOD.find((obj) => obj.value === value)?.label || '';
};

export const rangeInDates = (startDate, endDate) => {
    const end = new Date(endDate);
    //end.setDate(end.getDate() + 1);
    const dates = [];
    for (let d = new Date(startDate); d < end; d.setDate(d.getDate() + 1)) {
        dates.push(moment(d).format('YYYY-MM-DD'));
    }
    return dates;
};

export const getTentFordate = (tents, date, isHoliday = false) => {
    const dayofweek = moment(date).format('d');
    if (isHoliday) {
        return tents[2];
    } else if ([0, 1, 2, 3, 4].includes(Number(dayofweek)) === true) {
        return tents[0];
    } else {
        return tents[1];
    }
};

export const getSurchargeFordate = (surcharge, date) => {
    const dayofweek = moment(date).format('d');
    if ([0, 1, 2, 3, 4].includes(Number(dayofweek)) === true) {
        return surcharge[0];
    } else {
        return surcharge[1];
    }
};

export const inventoryStock = (inventory) => {
    if (inventory > 0 && inventory <= 10) {
        return <span className="inventory almost-outofstock">Sắp hết hàng</span>;
    } else if (inventory <= 0) {
        return <span className="inventory outofstock">Hết hàng</span>;
    } else {
        return <span className="inventory stocking">Còn hàng</span>;
    }
}

export const statusStock = (type) => {
    if (type == 'in') {
        return <span className="stock stock-in">Nhập kho</span>;
    } else {
        return <span className="stock stock-out">Xuất kho</span>;
    }
}

export const statusExpiry = (date) => {
    const today = moment().format("YYYY-MM-DD");
    const expirationDate = moment(date, "YYYY-MM-DD");
    const daysUntilExpiration = expirationDate.diff(today, 'days');
    if (daysUntilExpiration <= 10 && daysUntilExpiration > 0) {
        return <span className="expiry date-soon">Còn {daysUntilExpiration} ngày sử dụng</span>;
    } else if (daysUntilExpiration <= 0) {
        return <span className="expiry out-ofdate">Hết hạn sử dụng</span>
    }
}

export const statusReceipt = (status) => {
    if (status == 0) {
        return <span className="status color-blue">
            <span>Đã tạo</span>
            <span>phiếu mới</span>
        </span>
    } else if (status == 1) {
        return <span className="status color-red">
            <span>Đã cập nhật</span>
            <span>hạn sử dụng</span>
        </span>
    } else if (status == 2) {
        return <span className="status color-orange">
            <span>Báo quản lý</span>
            <span>duyệt</span>
        </span>
    } else if (status == 3) {
        return <span className="status color-green">
            <span>Đã duyệt</span>
            <span>nhập kho</span>
        </span>
    }
}