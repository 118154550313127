import classNames from 'classnames/bind';
import styles from '../ProductList.module.scss';
import { BookContext } from '~/context/BookContext';
import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faClose, faMinus } from '@fortawesome/free-solid-svg-icons';
import ProductOptions from './ProductOptions';
import LoyalCustomer from './LoyalCustomer';
import { useParams } from 'react-router-dom';
const cx = classNames.bind(styles);
function Product({ item, onClick }) {
    const { type } = useParams();
    const { show, setShow } = useContext(BookContext);
    const [note, setNote] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [options, setOptions] = useState([]);
    const [customer, setCustomer] = useState({});

    const handleClose = () => {
        setShow(false);
    };

    const handleNoteChange = (note) => {
        setNote(note);
    };

    const handleMinus = () => {
        if (quantity === 1) {
            setQuantity(0);
        } else {
            let newQuantity = quantity - 1;
            setQuantity(newQuantity);
        }
    };
    const handleAdd = () => {
        let newQuantity = quantity + 1;
        setQuantity(newQuantity);
    };

    const handleSubmit = () => {
        const datas = options.filter((o) => o.is_checked === true);
        const newItem = { ...item };
        newItem.quantity = quantity;
        newItem.options = datas;
        newItem.note = note;
        newItem.discount = customer;

        onClick(newItem);

        setQuantity(1);
        setNote('');
        setOptions((prevOptions) => {
            const newOoptions = prevOptions.map((obj) => {
                return { ...obj, is_checked: false };
            });
            return newOoptions;
        });
        setCustomer({});
        setShow(false);
    };

    return (
        <>
            <div className={cx('product-side', { show: show })}>
                <div className={cx('thumbnail')}>
                    <button onClick={handleClose} className={cx('btn-close')}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                    <img src={`/assets/images/${item.thumbnail}`} alt={'thumnail'} />
                </div>
                <div className={cx('header')}>
                    <h3>
                        <span className={cx('name')}>{item.product_name}</span>
                        <span className={cx('price')}>{item.display_price}</span>
                    </h3>
                </div>
                <div className={cx('body')}>
                    <div className={cx('product')}>
                        {item.category_id === 1 ? (
                            <>
                                <ProductOptions options={options} setOptions={setOptions} />
                            </>
                        ) : (
                            ''
                        )}

                        {type === 'ticket' ? <LoyalCustomer customer={customer} setCustomer={setCustomer} /> : ''}

                        <div className={cx('note')}>
                            <label className="col">
                                <span className={cx('input-label')}>Ghi chú:</span>
                                <textarea
                                    rows={3}
                                    cols={4}
                                    value={note}
                                    onChange={(e) => handleNoteChange(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className={cx('quantity-row')}>
                            <div className={cx('btn-group')}>
                                <button
                                    onClick={handleMinus}
                                    className={cx('btn-minus', { show: quantity >= 0 })}
                                    disabled={quantity === 0}
                                >
                                    <FontAwesomeIcon icon={faMinus} />
                                </button>
                                <span className={cx('quantity', { show: quantity >= 0 })}>{quantity}</span>
                                <button onClick={handleAdd} className={cx('btn-add', { show: quantity >= 0 })}>
                                    <FontAwesomeIcon icon={faAdd} />
                                </button>
                            </div>
                        </div>
                        <div className={cx('submit')}>
                            <button onClick={handleSubmit} disabled={quantity === 0}>
                                Thêm Vào Giỏ Hàng
                            </button>
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Product;
