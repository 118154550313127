import classNames from 'classnames/bind';
import styles from '../Ticket.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faClose, faPhone, faSave, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as ticketServices from '~/apiServices/ticketServices';
import { customerLabel } from '~/utils/filters';

const cx = classNames.bind(styles);
function TicketDetail({ item, side, setSide, onUpdate }) {
    const [ticket, setTicket] = useState({});
    const [searching, setSearching] = useState(false);
    const [q, setQ] = useState('');
    const [customers, setCustomers] = useState([]);
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        if (side === true) {
            setTicket(item);
            setDataList(item.discounts);
        }
    }, [side]);

    const handleCloseSide = () => {
        setSide(false);
    };

    const filterApi = async (phone) => {
        const response = await ticketServices.getLoyalCustomers({
            params: {
                phone: phone,
            },
        });
        if (response) {
            setSearching(false);
            setCustomers(response);
        }
    };

    const saveApi = async () => {
        const datas = dataList.map((obj) => {
            return { action: obj.action, customer_id: obj.customer_id };
        });
        const response = await ticketServices.addDiscountToCustomer({
            ticket_id: ticket.ticket_id,
            datas: datas,
        });
        if (response) {
            const newDataList = dataList.filter((obj) => obj.action !== 'del');
            onUpdate(ticket.ticket_id, newDataList);
            setSide(false);
        }
    };

    const handleSearch = () => {
        setSearching(true);
        filterApi(q);
    };

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setCustomers([]);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleResetSearch = () => {
        setQ('');
        setCustomers([]);
    };
    const handleSearchRun = useCallback(async (value) => {
        setQ(value);
        setSearching(true);
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(async () => {
            try {
                setSearching(false);
                filterApi(value);
            } catch (error) {
                console.error('Error during search:', error);
            }
        }, 700);
    }, []);
    let searchTimeout;

    const handleAddToLoyalCustomer = (e, item) => {
        e.stopPropagation();
        const exist = dataList.find((obj) => obj.customer_id === item.customer_id);
        if (!exist) {
            const newData = { ...item, action: 'add' };
            setDataList([...dataList, newData]);
        } else {
            if (exist.action === 'del') {
                console.log(exist);
                setDataList((prevDataList) => {
                    const newDataList = prevDataList.map((obj) => {
                        if (obj.customer_id === exist.customer_id) {
                            return { ...obj, action: 'add' };
                        }
                        return obj;
                    });

                    return newDataList;
                });
            }
        }
        setQ('');
        setCustomers([]);
    };

    const handleRemoveLoyalCustomer = (cusitomerId) => {
        setDataList((prevDataList) => {
            const newDataList = prevDataList.map((obj) => {
                if (obj.customer_id === cusitomerId) {
                    return { ...obj, action: 'del' };
                }
                return obj;
            });

            return newDataList;
        });
    };

    const handleBack = () => {
        setSide(false);
    };

    const handleSave = () => {
        saveApi();
        setSide(false);
    };
    return (
        <>
            <div className={cx('right-sides', { show: side })}>
                <div className={cx('header')}>
                    <h3>
                        {ticket.ticket_name} - {ticket.ticket_id}
                    </h3>
                    <p>Số Điện Thoại: {ticket.ticket_phone}</p>
                    <p>Số khách: {ticket.ticket_number}</p>
                    <button onClick={handleCloseSide} className={cx('btn-close')}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>
                <div className={cx('body')}>
                    <div className={cx('loyal-customer')}>
                        <h3 className={cx('table-title')}>Danh sách khách hàng Thân Thiết</h3>
                        <div className={cx('form')}>
                            <div className="form-row">
                                <div className="col">
                                    <div className={cx('input-field')}>
                                        <span className={cx('input-icon')}>
                                            <FontAwesomeIcon icon={faPhone} />
                                        </span>
                                        <span className={cx('input-label')}>SĐT:</span>
                                        <span className={cx('input-value')}>
                                            <input
                                                type="text"
                                                value={q}
                                                maxLength={10}
                                                onChange={(e) => setQ(e.target.value)}
                                                onKeyUp={(e) => handleSearchRun(e.target.value)}
                                            />
                                        </span>
                                        <span className={cx('input-loader', { show: searching })}>
                                            <FontAwesomeIcon icon={faSpinner} className="spinner" />
                                        </span>
                                        <span
                                            className={cx('input-close', { show: q.length > 0 && !searching })}
                                            onClick={handleResetSearch}
                                        >
                                            <FontAwesomeIcon icon={faClose} />
                                        </span>
                                        <div ref={wrapperRef} className={cx('box-customers')}>
                                            {customers.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={cx('customer-item')}
                                                        onClick={(e) => handleAddToLoyalCustomer(e, item)}
                                                    >
                                                        <strong>
                                                            #{item.customer_id} - {item.customer_name}
                                                        </strong>
                                                        <p className={cx('phone')}>{item.customer_phone}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <button className="btn-primary" onClick={handleSearch}>
                                    Tìm
                                </button>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th>TÊN</th>
                                    <th>SĐT</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataList?.map((item, index) => {
                                    return (
                                        <tr key={index} className={cx('tr-border')} hidden={item.action === 'del'}>
                                            <td className="text-center">{item.customer_id}</td>
                                            <td>
                                                <strong className={cx('name')}>{item.customer_name}</strong>

                                                <p className={cx('type')}>{customerLabel(item.customer_type)}</p>
                                            </td>
                                            <td>
                                                <strong className={cx('phone')}>{item.customer_phone}</strong>
                                            </td>
                                            <td>
                                                <button
                                                    className={cx('btn-remove')}
                                                    onClick={() => handleRemoveLoyalCustomer(item.customer_id)}
                                                >
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faXmark} />
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={handleBack}>
                                <span>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                            </button>
                            <button className={cx('btn-save')} onClick={handleSave}>
                                <span>
                                    <FontAwesomeIcon icon={faSave} />
                                </span>
                                <span>SAVE</span>
                            </button>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TicketDetail;
