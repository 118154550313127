import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
import { useNavigate } from "react-router-dom";
import { faHistory, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const cx = classNames.bind(styles);
function ReceiptNav() {
    const navigate = useNavigate();
    const current = location.pathname;
    var title = '';
    if (current == '/ingredient-list') {
        title = 'Danh sách nguyên liệu';
    } else if (current == '/receipt-list') {
        title = 'Danh sách phiếu nhập';
    } else if (current == '/receipt') {
        title = 'Phiếu nhập nguyên liệu';
    }
    console.log(current);
    const handleChangeNav = (active) => {
        navigate(active);
    };
    return (
        <h2 className="page-title">
            <div class="group-title">
                <h3 class="title">KHO </h3>
                <span class="sub-title">{title}</span>
            </div>

            <div className="btn-wrap">
                <button
                    className={cx('btn btn-add-new ', { active: current === '/receipt' })}
                    onClick={() => handleChangeNav('/receipt')}
                ><FontAwesomeIcon icon={faPlus} /> Phiếu nhập</button>
                <button
                    className={cx('btn btn-history ', { active: current === '/receipt-list' })}
                    onClick={() => handleChangeNav('/receipt-list')}
                ><FontAwesomeIcon icon={faHistory} /> Lịch sử</button>
            </div>
        </h2>
    );
}

export default ReceiptNav;