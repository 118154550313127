import { useEffect, useState } from 'react';
import * as campServices from '~/apiServices/campServices';
import classNames from 'classnames/bind';
import styles from './CampCalendar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTent } from '@fortawesome/free-solid-svg-icons';
import { orderTag } from '~/utils/filters';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Head from '../Head';
const cx = classNames.bind(styles);

function CampCalendar() {
    const navigate = useNavigate();
    const [tableList, setTableList] = useState([]);

    useEffect(() => {
        document.body.style.overflow = 'visible';
        const fetchCamps = async () => {
            const response = await campServices.getCalendars();
            if (response.length > 0) {
                setTableList(response);
            }
        };
        fetchCamps();
    }, []);

    return (
        <>
            <div className={cx('camp')}>
                <h2 className="page-title">
                    Lịch đặt Lều <span>{moment().format('DD/MM/YYYY')}</span>
                </h2>
                <Head />
                <div className={cx('camp-calendar')}>
                    <div className={cx('table-row')}>
                        {tableList.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={cx('table-item')}
                                    onClick={() => handleClickDetail(item.table_id)}
                                >
                                    <div className={cx('table-item-box')}>
                                        <div className={cx('table-head', `status-${item.table_status}`)}>
                                            <span className={cx('table-icon', item.table_type)}>
                                                <FontAwesomeIcon icon={faTent} />
                                            </span>
                                            <span className={cx('table-name')}> {item.table_name}</span>
                                        </div>
                                        <div className={cx('table-body')}>
                                            <ul className={cx('order-list')}>
                                                {item.orders?.map((order, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className={cx('customer-type', order.type, {
                                                                active: order.active,
                                                            })}
                                                        >
                                                            <span className={cx('type')}>
                                                                {orderTag(order.type)} {order.id}
                                                            </span>

                                                            <div className={cx('time')}>
                                                                <span>{order.name}</span>
                                                                <span>
                                                                    {moment(order.check_in).format('DD-MM-YY')} -{' '}
                                                                    {moment(order.check_out).format('DD-MM-YY')}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        <div className={cx('table-add')}>
                                            <FontAwesomeIcon icon={faCirclePlus} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CampCalendar;
