import classNames from 'classnames/bind';
import styles from './ProductDetail.module.scss';
import { Fragment, useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { useParams } from 'react-router-dom';
import ProductItem from './ProductItem';
import FormAdd from './FormAdd';
const cx = classNames.bind(styles);
function ProductDetail() {
    const { catId, type } = useParams();
    const [product, setProduct] = useState({});
    const fetchApi = async () => {
        const response = await adminServices.getCategoryProducts({
            params: {
                cat_id: Number(catId),
                type: type,
            },
        });
        if (response) {
            setProduct(response);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    const handleSubmitAdd = (newData) => {
        setProduct((prevProduct) => {
            const newProductData = [...prevProduct.datas, newData];
            return { ...prevProduct, datas: newProductData };
        });
    };
    const handleEditSave = (productId, data) => {
        setProduct((prevProduct) => {
            const newProductData = prevProduct.datas?.map((obj) => {
                if (obj.product_id === productId) {
                    return {
                        ...obj,
                        product_id: data.product_id,
                        product_name: data.product_name,
                        product_unit: data.product_unit,
                        product_order: data.product_order,
                        product_price: data.product_price,
                        display_price: data.display_price,
                    };
                }
                return obj;
            });
            return { ...prevProduct, datas: newProductData };
        });
    };

    const handleRemove = (productId) => {
        const removeApi = async () => {
            const res = await adminServices.removeProduct({
                product_id: productId,
            });
            if (res.status) {
                const newDataList = product.datas.filter((obj) => {
                    return obj.product_id !== productId;
                });
                setProduct({
                    ...product,
                    datas: newDataList
                });
            } else {
                alert(res.message);
            }
        };
        removeApi();
    }

    return (
        <>
            <div className={cx('product-detail')}>
                <h2 className="page-title">
                    {product.title} - {product.sub_title}
                </h2>
            </div>
            <div className={cx('admin-body')}>
                <FormAdd onSubmit={handleSubmitAdd} />
                <div className={cx('products')}>
                    <table>
                        <thead>
                            <tr>
                                <th>T.Sản phẩm</th>
                                <th className="text-center">S.X</th>
                                <th>Giá</th>
                                <th>HT</th>
                                <th className="text-center">Đ.Vị</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {product.datas?.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <ProductItem item={item} onSave={handleEditSave} onRemove={handleRemove} />
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default ProductDetail;
