import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faCheck, faChevronLeft, faPlus, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ReceiptContext } from './Receipt';
import { inventoryStock } from '~/utils/filters';
const cx = classNames.bind(styles);
function SelectIngredient() {
    const navigate = useNavigate();
    const { dataSource, setDataSource, setSelected, setStep } = useContext(ReceiptContext);

    const handleAdd = (ingredientId) => {
        setDataSource((prevDatas) => {
            const newDatas = prevDatas.map((obj) => {
                if (obj.ingredient_id === ingredientId) {
                    return { ...obj, active: !obj.active };
                }
                return obj;
            });
            return newDatas;
        });
    }

    const handleNext = () => {
        const newDatas = dataSource.filter(d => d.active == true);
        if (newDatas.length == 0) {
            alert(`Chưa chọn nguyên liệu`);
            return;
        }
        setSelected(newDatas);
        setStep(2);
    }
    return (
        <div className={cx('product')}>
            <h2 className="page-title">Chọn nguyên liệu</h2>
            <div className={cx('product-list')}>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <table>
                            <thead>
                                <tr>
                                    <th className={cx('text-center')}>STT</th>
                                    <th>Tên Nguyên Liệu</th>
                                    <th className={cx('text-center')}>Tồn</th>
                                    <th className={cx('text-center')}>Trạng thái</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSource?.map((item, index) => {
                                    const inventory = item.stock;
                                    var stockClass = '';
                                    if (inventory <= 10 && inventory > 0) {
                                        stockClass = 'almost-stock';
                                    } else if (inventory <= 0) {
                                        stockClass = 'out-stock';
                                    }
                                    return (
                                        <tr key={index}>
                                            <td width={50} className={cx('text-center')}>
                                                {index + 1}
                                            </td>
                                            <td width={250}>
                                                <strong className="name">{item.ingredient_name}</strong><br></br>
                                                <small>{item.ingredient_description}</small>
                                            </td>
                                            <td width={100} className={cx('text-center')}>
                                                <span><strong>{item.stock}</strong></span>
                                            </td>
                                            <td width={100} className="text-center">
                                                <div>{inventoryStock(inventory)}</div>
                                            </td>
                                            <td>
                                                <div className={cx('btn-group')}>
                                                    <button className={cx('plus', item.active ? 'active' : '')} onClick={() => handleAdd(item.ingredient_id)}>
                                                        <FontAwesomeIcon icon={item.active ? faCheck : faPlus} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={() => navigate(`/ingredient-list`)}>
                                <span>
                                    <FontAwesomeIcon icon={faReceipt} />
                                </span>
                                <span>KHO</span>
                            </button>
                            <button className={cx('btn-save')} onClick={() => handleNext()}>
                                <span>
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </span>
                                <span>TIẾP TỤC</span>
                            </button>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectIngredient;
