import classNames from 'classnames/bind';
import styles from '../Camping.module.scss';
import { Fragment, useContext, useState } from 'react';
import { CampingContext } from '~/context/CampingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faIdCard, faListCheck, faTent } from '@fortawesome/free-solid-svg-icons';
import OrderItem from '../OrderItem';
const cx = classNames.bind(styles);
import * as adminServices from '~/apiServices/adminServices';
import { formatPrice } from '~/utils/filters';
import { CAMPING_PAYMENT_PERCENT } from '~/utils/constants';
import { useNavigate } from 'react-router-dom';
function Confirm() {
    const navigate = useNavigate();
    const { camping } = useContext(CampingContext);
    const { info, select, surcharges } = camping;
    const [note, setNote] = useState('');
    const extraAdt = surcharges[6];
    const extraChd = surcharges[7];

    const totalOrder = () => {
        let total = 0;
        for (let s in select) {
            const item = select[s];
            for (let o in item.orders) {
                const order = item.orders[o];
                total += Number(order.product_price) * Number(order.quantity);
            }
            for (let d in item.datas) {
                const data = item.datas[d];
                const priceTent = Number(data.tent?.product_price) * item.adt;
                const feeTent = item.adt === 1 ? Number(data.surcharge?.product_price) * item.adt : 0;
                const feeExtraAdt = Number(extraAdt.product_price) * item.aris;
                const feeExtraChd = Number(extraChd.product_price) * item.chd;
                total += priceTent + feeTent + feeExtraAdt + feeExtraChd;
            }
        }
        return total;
    };
    const total = totalOrder();
    const deposit = total * CAMPING_PAYMENT_PERCENT;
    const remaining = total - deposit;
    console.log(camping);
    const handleSubmit = () => {
        const input = {};

        input.info = {
            name: info.name,
            phone: info.phone,
            deposit: deposit,
            check_in: info.check_in,
            check_out: info.check_out,
            note: note,
        };

        const datas = [];
        for (const s in select) {
            if (Object.hasOwnProperty.call(select, s)) {
                const item = select[s];
                const adtId = item.aris > 0 ? extraAdt.product_id : 0;
                const chdId = item.chd > 0 ? extraChd.product_id : 0;
                const details = [];
                for (const d in item.datas) {
                    const detail = item.datas[d];
                    const productId = item.adt > 0 ? detail.tent.product_id : 0;
                    const tentId = item.adt === 1 ? detail.surcharge.product_id : 0;
                    details.push({
                        date: detail.date,
                        product_id: productId,
                        tent_id: tentId,
                        adt_id: adtId,
                        chd_id: chdId,
                    });
                }
                datas.push({
                    adt: item.adt,
                    aris: item.aris,
                    chd: item.chd,
                    inf: item.inf,
                    table_id: item.table_id,
                    services: item.orders,
                    details: details,
                    note: item.note,
                });
            }
        }

        input.datas = datas;
        console.log(input);
        const apiBooking = async () => {
            const res = await adminServices.bookingCamping(input);
            if (res.status) {
                navigate(`/super-admin/camping/${res.data}`);
            } else {
                alert(res.message);
            }
        };
        apiBooking();
    };

    return (
        <>
            <div className={cx('camping-confirm')}>
                <div className={cx('section')}>
                    <h3>
                        <FontAwesomeIcon icon={faIdCard} />
                        <span> Thông tin đặt lều</span>
                    </h3>
                    <p>
                        <span className={cx('info-label')}>Họ và tên:</span> <strong>{info.name}</strong>
                    </p>
                    <p>
                        <span className={cx('info-label')}>Số điện thoại:</span> <strong>{info.phone}</strong>
                    </p>
                    <hr></hr>
                    {select?.map((camp, campIndex) => {
                        return (
                            <div key={campIndex} className={cx('confirm-row')}>
                                <h4 className={cx('title')}>
                                    <FontAwesomeIcon icon={faTent} /> <span>{camp.table_name}</span>
                                </h4>
                                <p>
                                    <span className={cx('info-label')}>Người lớn:</span> <strong>{camp.adt}</strong>
                                </p>
                                <p>
                                    <span className={cx('info-label')}>Phát sinh:</span> <strong>{camp.aris}</strong>
                                </p>
                                <p>
                                    <span className={cx('info-label')}>Trẻ em 6 - 12:</span> <strong>{camp.chd}</strong>
                                </p>
                                <p>
                                    <span className={cx('info-label')}>
                                        Trẻ em <span>{'<'}</span> 6:
                                    </span>
                                    <strong> {camp.inf}</strong>
                                </p>
                                {camp.datas?.map((item, itemIndex) => {
                                    return (
                                        <Fragment key={`${campIndex}-${itemIndex}`}>
                                            <div className={cx('tent-box')}>
                                                <div className={cx('tent-item')}>
                                                    <span>
                                                        <strong className={cx('tent-date')}>{item.date}</strong>
                                                        <span> {item.tent?.product_name}</span>
                                                    </span>
                                                    <span className={cx('tent-right')}>
                                                        <span>x {camp.adt}</span>
                                                        <strong>{item.tent?.display_price}</strong>
                                                    </span>
                                                </div>
                                                {camp.adt === 1 ? (
                                                    <div className={cx('extra-item')}>
                                                        <span>
                                                            <span className={cx('icon')}>
                                                                <FontAwesomeIcon icon={faCheck} />
                                                            </span>
                                                            <span> {item.surcharge?.product_name}</span>
                                                        </span>
                                                        <span className={cx('tent-right')}>
                                                            <span>x {camp.adt}</span>
                                                            <strong>{item.surcharge?.display_price}</strong>
                                                        </span>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {camp.aris > 0 ? (
                                                    <div className={cx('extra-item')}>
                                                        <span>
                                                            <span className={cx('icon')}>
                                                                <FontAwesomeIcon icon={faCheck} />
                                                            </span>
                                                            <span> {extraAdt?.product_name}</span>
                                                        </span>
                                                        <span className={cx('tent-right')}>
                                                            <span>x {camp.aris}</span>
                                                            <strong>{extraAdt?.display_price}</strong>
                                                        </span>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {camp.chd > 0 ? (
                                                    <div className={cx('extra-item')}>
                                                        <span>
                                                            <span className={cx('icon')}>
                                                                <FontAwesomeIcon icon={faCheck} />
                                                            </span>
                                                            <span> {extraChd?.product_name}</span>
                                                        </span>
                                                        <span className={cx('tent-right')}>
                                                            <span>x {camp.chd}</span>
                                                            <strong>{extraChd?.display_price}</strong>
                                                        </span>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                                <h3>
                                    <FontAwesomeIcon icon={faListCheck} />
                                    <span> Dịch vụ</span>
                                </h3>
                                {camp.orders?.map((o, i) => {
                                    return <OrderItem key={i} order={o} />;
                                })}
                                <p>
                                    <span>Ghi chú:</span> <strong>{camp.note}</strong>
                                </p>
                            </div>
                        );
                    })}
                    <table>
                        <tfoot>
                            <tr>
                                <td colSpan={2}>
                                    <strong>Tổng: </strong>
                                </td>
                                <td width={150} className="text-right">
                                    <span className={cx('total')}>{formatPrice(total)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <strong>Cọc: </strong>
                                </td>
                                <td width={150} className="text-right">
                                    <span className={cx('total')}>{formatPrice(deposit)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <strong>Còn lại: </strong>
                                </td>
                                <td width={150} className="text-right">
                                    <span className={cx('total')}>{formatPrice(remaining)}</span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <div className="note">
                        <label className="col">
                            <span className={cx('input-label')}>Ghi chú:</span>
                            <textarea rows={3} cols={4} defaultValue={note} onChange={(e) => setNote(e.target.value)} />
                        </label>
                    </div>
                    <br></br>
                </div>
                <div className="form-row">
                    <div className="col">
                        <button className="btn-primary" onClick={handleSubmit}>
                            Đặt lều
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Confirm;
