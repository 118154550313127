import { createContext, useContext, useEffect, useState } from 'react';
import * as homeServices from '~/apiServices/homeServices';

export const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(() => {
        // Check localStorage for existing login state
        const saved = localStorage.getItem('loggedIn');
        return saved === 'true';
    });

    const checkLoginStatus = async () => {
        const apiCheckLogin = async () => {
            const res = await homeServices.checkLogin();
            setLoggedIn(res.logged_in);
        };
        apiCheckLogin();
    };

    useEffect(() => {
        //checkLoginStatus();
        localStorage.setItem('loggedIn', loggedIn);
    }, [loggedIn]);

    const login = () => setLoggedIn(true);
    const logout = () => setLoggedIn(false);

    const value = {
        loggedIn,
        login,
        logout
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);