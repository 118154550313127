import classNames from 'classnames/bind';
import styles from './CampBill.module.scss';
import { Fragment, useEffect, useState } from 'react';
const cx = classNames.bind(styles);
import * as orderServices from '~/apiServices/orderServices';
import { useParams } from 'react-router-dom';
import { formatPrice, timePrint } from '~/utils/filters';
import PrintFoot from '~/pages/Print/PrintFoot';
import moment from 'moment';
function CampBill() {
    const { campId, tableId } = useParams();
    const [print, setPrint] = useState({ orders: [] });
    useEffect(() => {
        const fetchApi = async () => {
            const response = await orderServices.printTable({
                params: {
                    type: 'camp',
                    table_id: Number(tableId),
                    obj_id: Number(campId),
                },
            });
            setPrint(response);
        };
        fetchApi();
    }, []);

    const handleMessage = (event) => {
        if (event.data.action === 'print-bill') {
            window.focus();
            window.print();
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <>
            <div className={cx('print-header')}>
                <h1>
                    VIET UC <br></br>FLOWER GARDEN
                </h1>
                <p>08 Huỳnh Thúc Kháng, Măng Đen, Kon Plông, Kon Tum.</p>
                <div className={cx('print-info')}>
                    <span className={cx('table-number')}>{print.key}</span>
                    <span className={cx('table-title')}>
                        <h3>{'PHIẾU TÍNH TIỀN'}</h3>
                        {print.code}
                    </span>
                </div>
                <div className={cx('print-row')}>
                    <span>
                        {' '}
                        {print.name} - {print.phone}
                    </span>{' '}
                    <span>Giờ in: {timePrint()}</span>
                </div>
                <div className={cx('print-row')}>
                    <span> Ngày Nhận: {moment(print.check_in).format('DD-MM-YYYY')}</span>
                    <span> Ngày Nhận: {moment(print.check_out).format('DD-MM-YYYY')}</span>
                </div>
            </div>
            <div className={cx('print-body')}>
                <table>
                    <thead>
                        <tr>
                            <th>Tên</th>
                            <th>Đ.Giá</th>
                            <th width={20} className="text-center">
                                SL
                            </th>
                            <th className="text-right">T.Tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {print.orders?.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <tr>
                                        <td colSpan={4}>
                                            <strong>- {item.table_name}</strong>
                                        </td>
                                    </tr>
                                    {item.details?.map((d, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <tr key={index}>
                                                    <td>
                                                        <span>
                                                            {d.active ? (
                                                                <>
                                                                    <strong>{d.product_name}</strong>
                                                                    <span>
                                                                        {' '}
                                                                        ({moment(d.date).format('DD-MM-YYYY')})
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <span> {d.product_name}</span>
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className={cx('group-row')}>
                                                            <span className={cx('price')}>
                                                                {formatPrice(d.product_price)}
                                                            </span>
                                                            <span className={cx('mark')}>&nbsp; x</span>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className={cx('quantity')}>{d.quantity}</span>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong className={cx('price')}>
                                                            {formatPrice(d.quantity * d.product_price)}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        );
                                    })}
                                </Fragment>
                            );
                        })}
                        <tr style={{ borderTop: '2px solid var(--primary)' }}>
                            <td colSpan={3}>Tổng tiền hàng:</td>
                            <td className="text-right">
                                <strong className={cx('total')}>{formatPrice(print.total)}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>Đã Thanh toán:</td>
                            <td className="text-right">
                                <strong className={cx('total')}>{formatPrice(print.paid)}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>Chiết khấu:</td>
                            <td className="text-right">
                                <strong className={cx('total')}>{formatPrice(print.discount)}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <b>Còn lại:</b>
                            </td>
                            <td className="text-right">
                                <strong className={cx('total')}>
                                    {formatPrice(print.total - print.paid - print.discount)}
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <PrintFoot />
        </>
    );
}

export default CampBill;
