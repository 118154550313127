import * as request from '~/utils/request';

export const getTickets = async (params) => {
    try {
        const res = await request.get('tickets/get_tickets.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addTicket = async (data) => {
    try {
        const res = await request.post('tickets/add_ticket.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const update = async (datas) => {
    try {
        const res = await request.post('tickets/update.php', datas);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getLoyalCustomers = async (params) => {
    try {
        const res = await request.get('tickets/get_loyal_customers.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addDiscountToCustomer = async (data) => {
    try {
        const res = await request.post('tickets/add_discount_to_customer.php', data);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getTicketProducts = async (params) => {
    try {
        const res = await request.get('tickets/get_ticket_products.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const printTicket = async (params) => {
    try {
        const res = await request.get('tickets/get_print.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const printTicketWater = async (params) => {
    try {
        const res = await request.get('tickets/get_print_ticket_water.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getTables = async (params) => {
    try {
        const res = await request.get('tickets/get_tables.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const bookingBegin = async (data) => {
    try {
        const res = await request.post('tickets/booking_begin.php', data);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
