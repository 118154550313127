import classNames from 'classnames/bind';
import styles from './Product.module.scss';
import * as productServices from '~/apiServices/productServices';
import { useEffect, useState } from 'react';
import Image from '~/components/Image';

const cx = classNames.bind(styles);
function Product() {
    const [activeTab, setActiveTab] = useState('water');
    const [productList, setProductList] = useState([]);
    useEffect(() => {
        const fetchApi = async () => {
            const result = await productServices.products();
            setProductList(result);
        };
        if (productList.length === 0) fetchApi();
    }, []);

    const handleTabControl = (tab) => {
        setActiveTab(tab);
    };

    const tabControl = () => {
        const newTabs =
            productList?.map((obj) => {
                return {
                    key: obj.category_key,
                    label: obj.category_name,
                };
            }) || [];
        return newTabs;
    };
    return (
        <div className={cx('product')}>
            <h2 className="page-title">Sản phẩm</h2>
            <div className={cx('product-list')}>
                <nav className={cx('head')}>
                    <div className={cx('tabs')}>
                        {tabControl().map((tab, index) => {
                            return (
                                <span
                                    key={index}
                                    className={cx('tab-item', { active: tab.key === activeTab })}
                                    onClick={(e) => {
                                        handleTabControl(tab.key);
                                    }}
                                >
                                    {tab.label}
                                </span>
                            );
                        })}
                    </div>
                </nav>
                {productList.map((obj) => {
                    return (
                        <div
                            key={obj.category_key}
                            className={cx('tab-content', { active: obj.category_key === activeTab })}
                        >
                            <div className={cx('product-section')}>
                                {Object.keys(obj.products).map((keyName) => {
                                    const subProduct = obj.products[keyName];
                                    return (
                                        <div key={subProduct.key} className={cx('product-row')}>
                                            <h4>{subProduct.title}</h4>
                                            {subProduct.datas.map((item) => {
                                                return (
                                                    <div key={item.product_id} className={cx('product-item')}>
                                                        <div className={cx('thumbnail')}>
                                                            <Image
                                                                url={`/assets/images/${item.thumbnail}`}
                                                                alt={'thumnail'}
                                                            />
                                                            <span className={cx('name')}>{item.product_name}</span>
                                                            <span className={cx('price')}>{item.display_price}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Product;
