import classNames from 'classnames/bind';
import styles from './PaymentModal.module.scss';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faMoneyBill1, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import BoxLoading from '../BoxLoading';
const cx = classNames.bind(styles);
function PaymentModal({ open, processing, setProcessing, onSubmit, onClose }) {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(open);
    }, [open]);

    const handlePayment = async (e, type) => {
        e.stopPropagation();
        setProcessing(true);
        onSubmit(type);
    };

    const handleClose = () => {
        setShow(false);
        onClose(true);
    };

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleClose();
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <>
            <BoxLoading loading={processing} />
            <div className={cx('modal', { show: show })}>
                <div ref={wrapperRef} className={cx('modal-container')}>
                    <div className={cx('modal-head')}>
                        <h3>{`Chọn phương thức thanh toán`}</h3>
                        <button onClick={handleClose} className={cx('btn-close')}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>
                    <div className={cx('modal-body')}>
                        <ul>
                            <li className="payment tranfer" onClick={(e) => handlePayment(e, 'transfer')}>
                                <span className="icon">
                                    <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                </span>
                                <span className="text">CHUYỂN KHOẢN</span>
                            </li>
                            <li className="payment cash" onClick={(e) => handlePayment(e, 'cash')}>
                                <span className="icon">
                                    <FontAwesomeIcon icon={faMoneyBill1} />
                                </span>
                                <span className="text">TIỀN MẶT</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentModal;
