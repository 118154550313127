import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
import * as homeServices from '~/apiServices/homeServices';
import { createContext, useEffect, useState } from 'react';
import Image from '~/components/Image';
import { formatPrice } from '~/utils/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEdit, faPlus, faPlusMinus, faSave } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import { useNavigate } from 'react-router-dom';
import ReceiptStep from './ReceiptStep';
import ReceiptContent from './ReceiptContent';
registerLocale('vi', vi);
export const ReceiptContext = createContext({});
const cx = classNames.bind(styles);
function Receipt() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [dataSource, setDataSource] = useState([]);
    const [selected, setSelected] = useState([]);
    const [info, setInfo] = useState({
        proposer: '',
        department: '',
        purpose: ''
    });
    const fetchApi = async () => {
        const response = await homeServices.getStores();
        if (response) {
            const newDataSource = response.map(d => {
                return { ...d, active: false, quantity: 0, date: '' }
            })
            setDataSource(newDataSource);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    const handleQuantityChange = (quantity, ingredient_id) => {
        setDataSource((prevDatas) => {
            const newDatas = prevDatas.map((obj) => {
                if (obj.ingredient_id === ingredient_id) {
                    return { ...obj, quantity: Number(quantity) };
                }
                return obj;
            });
            return newDatas;
        });
    };

    const handleSave = () => {
        const newDatas = dataSource.filter(s => s.quantity !== 0);

        if (dataSource.length == 0) {
            alert(`Chưa nhập dữ liệu`);
            return;
        }
        console.log(newDatas);

        // const AddApi = async () => {
        //     const res = await adminServices.addStockIn({
        //         datas: newDatas
        //     });
        //     if (res.status) {
        //         navigate('/super-admin/stock-history');
        //     } else {
        //         alert(res.message);
        //     }
        // };
        // AddApi();
    }


    return (
        <div className={cx('product')}>
            <h2 className="page-title">
                <div class="group-title">
                    <h3 class="title">KHO </h3>
                    <span class="sub-title">Phiếu nhập nguyên liệu</span>
                </div>
            </h2>
            <div className={cx('product-list')}>
                <ReceiptContext.Provider value={{
                    step, setStep,
                    dataSource, setDataSource,
                    selected, setSelected,
                    info, setInfo
                }}>
                    <ReceiptStep />
                    <ReceiptContent />
                </ReceiptContext.Provider>
            </div>
        </div>
    );
}

export default Receipt;
