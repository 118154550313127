import classNames from 'classnames/bind';
import styles from '../Camping.module.scss';
const cx = classNames.bind(styles);
import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { CampingContext } from '~/context/CampingContext';
import CampingAdd from '../CampingAdd';
import Contact from './Contact';
import OrderItem from '../OrderItem';
import InfoItem from './InfoItem/InfoItem';
function EnterInfo() {
    const { camping, setStep, setSide } = useContext(CampingContext);
    const { select } = camping;
    const [campIndex, setCampIndex] = useState(0);
    const handleContinues = () => {
        setStep(3);
    };
    const handleAddServiceForTent = (campIndex) => {
        setSide(true);
        setCampIndex(campIndex);
    };
    return (
        <>
            <div className={cx('form')}>
                <div className="form-row">
                    <div className={cx('section')}>
                        {select?.map((camp, campIndex) => {
                            return (
                                <div key={campIndex} className={cx('section-row')}>
                                    <h4 className={cx('title')}>{camp.table_name}</h4>
                                    <div className={cx('section-box')}>
                                        <InfoItem
                                            datas={camp.datas}
                                            campIndex={campIndex}
                                            adt={camp.adt}
                                            aris={camp.aris}
                                            chd={camp.chd}
                                        />
                                        <div className={cx('btn-row')}>
                                            <button
                                                className={cx('btn-add')}
                                                onClick={() => handleAddServiceForTent(campIndex)}
                                            >
                                                <FontAwesomeIcon icon={faAdd} />
                                                <span> Dịch vụ</span>
                                            </button>
                                        </div>
                                        {camp.orders?.map((o, i) => {
                                            return <OrderItem key={i} order={o} />;
                                        })}
                                        <Contact item={camp} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <button className="btn-primary" onClick={handleContinues}>
                            Tiếp Tục
                        </button>
                    </div>
                </div>
            </div>
            <CampingAdd campIndex={campIndex} />
        </>
    );
}
export default EnterInfo;
