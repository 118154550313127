import classNames from 'classnames/bind';
import styles from '../../Bill.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faClose, faPrint, faSave } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import * as orderServices from '~/apiServices/orderServices';
import { formatPrice } from '~/utils/filters';
import BillOrderCamp from './BillOrderCamp';
import BillOrder from './BillOrder';

const cx = classNames.bind(styles);
function BillDetail({ item, side, setSide }) {
    const [detail, setDetail] = useState({ orders: [] });

    const fetchApi = async () => {
        const response = await orderServices.printTable({
            params: {
                type: item.bill_type,
                table_id: Number(item.table_id),
                obj_id: Number(item.obj_id),
            },
        });
        setDetail(response);
    };
    useEffect(() => {
        if (side === true) {
            fetchApi();
        }
    }, [side]);

    const handleCloseSide = () => {
        setSide(false);
    };

    const handleBack = () => {
        setSide(false);
    };

    const handlePrintTicket = () => {
        const f = document.frames ? document.frames[id] : document.getElementById('iframe-ticket');
        const w = f.contentWindow || f;
        w.postMessage({ action: 'print-ticket' }, f.src);
    };

    const handlePrintBill = () => {
        const f = document.getElementById('iframe-bill');
        const w = f.contentWindow;
        w.postMessage({ action: 'print-bill' }, f.src);
    };

    return (
        <>
            <div className={cx('right-sides', { show: side })}>
                {Object.keys(item).length ? (
                    <>
                        {item.bill_type === 'ticket' ? (
                            <iframe
                                id="iframe-ticket"
                                src={`/print-ticket/${item.obj_id}`}
                                style={{ display: 'none' }}
                                title="PRINT TICKET"
                            />
                        ) : (
                            ''
                        )}

                        {item.bill_type === 'camp' ? (
                            <iframe
                                id="iframe-bill"
                                src={`/camp-bill/${item.obj_id}/0`}
                                style={{ display: 'none' }}
                                title="PRINT BILL"
                            />
                        ) : (
                            <iframe
                                id="iframe-bill"
                                src={`/print-bill/${item.bill_type}/${item.table_id}/${item.obj_id}`}
                                style={{ display: 'none' }}
                                title="PRINT BILL"
                            />
                        )}

                        <div className={cx('header')}>
                            <h3>
                                {detail.code} - {detail.table_name}
                            </h3>
                            <button onClick={handleCloseSide} className={cx('btn-close')}>
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </div>
                        <div className={cx('body')}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Tên</th>
                                        <th>Đ.Giá</th>
                                        <th width={20} className="text-center">
                                            SL
                                        </th>
                                        <th className="text-right">T.Tiền</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.bill_type === 'camp' ? (
                                        <BillOrderCamp orders={detail.orders} />
                                    ) : (
                                        <BillOrder orders={detail.orders} />
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={3}>Tổng tiền hàng:</td>
                                        <td className="text-right">
                                            <strong className={cx('total')}>{formatPrice(detail.total)}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>Đã Thanh toán:</td>
                                        <td className="text-right">
                                            <strong className={cx('total')}>{formatPrice(detail.paid)}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>Chiết khấu:</td>
                                        <td className="text-right">
                                            <strong className={cx('total')}>{formatPrice(detail.discount)}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <b>Còn lại:</b>
                                        </td>
                                        <td className="text-right">
                                            <strong className={cx('total')}>
                                                {formatPrice(detail.total - detail.paid - detail.discount)}
                                            </strong>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className={cx('footer')}>
                            <div className={cx('navbar')}>
                                <div className={cx('navbar-row')}>
                                    <button className={cx('btn-back')} onClick={handleBack}>
                                        <span>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                        </span>
                                    </button>
                                    {item.bill_type === 'ticket' ? (
                                        <button className={cx('btn-print')} onClick={handlePrintTicket}>
                                            <span>
                                                <FontAwesomeIcon icon={faPrint} />
                                            </span>
                                            <span>IN VÉ</span>
                                        </button>
                                    ) : (
                                        ''
                                    )}

                                    <button className={cx('btn-print')} onClick={handlePrintBill}>
                                        <span>
                                            <FontAwesomeIcon icon={faPrint} />
                                        </span>
                                        <span>IN BILL</span>
                                    </button>
                                </div>
                                <br></br>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
            </div>
        </>
    );
}

export default BillDetail;
