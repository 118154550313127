import classNames from 'classnames/bind';
import styles from '../../Admin.module.scss';
import { formatPrice } from '~/utils/filters';
import { Fragment } from 'react';
const cx = classNames.bind(styles);
function TicketList({ orders }) {
    const totalPrice = () => {
        let total = 0;
        for (let keyName in orders) {
            const product = orders[keyName];
            for (let i in product.datas) {
                const item = product.datas[i];
                total += item.price * item.quantity;
            }
        }
        return total;
    };

    const totalQuantity = () => {
        let total = 0;
        for (let keyName in orders) {
            const product = orders[keyName];
            for (let i in product.datas) {
                const item = product.datas[i];
                total += item.quantity;
            }
        }
        return total;
    };
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>Tên</th>
                        <th>G.Nước</th>
                        <th>G.Vé</th>
                        <th width={20} className="text-center">
                            SL
                        </th>
                        <th className="text-right">T.Tiền</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(orders)?.map((keyName) => {
                        const product = orders[keyName];
                        return (
                            <Fragment key={product.key}>
                                <tr className={cx('trhead')}>
                                    <td colSpan={5}>{product.title}</td>
                                </tr>
                                {product.datas?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <span className={cx('name small')}>{item.product_name}</span>
                                                {item.customer_name ? (
                                                    <>
                                                        <br></br>
                                                        <p className={cx('x-small')}>
                                                            <span className={cx('name')}>{item.customer_name}</span>{' '}
                                                            <span className={cx('phone')}>{item.customer_phone}</span>
                                                        </p>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>
                                                <span className={cx('price')}>{formatPrice(item.product_price)}</span>
                                            </td>
                                            <td>
                                                <span className={cx('price')}>{formatPrice(item.price)}</span>
                                            </td>
                                            <td className="text-center">
                                                <span className={cx('quantity')}>{item.quantity}</span>
                                            </td>
                                            <td className="text-right">
                                                <strong className={cx('price')}>
                                                    {formatPrice(item.quantity * item.price)}
                                                </strong>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </Fragment>
                        );
                    })}
                    <tr>
                        <td colSpan={3}>
                            <b>Tổng Cộng:</b>
                        </td>
                        <td className="text-center">
                            <strong className={cx('total')}>{totalQuantity()}</strong>
                        </td>
                        <td className="text-right">
                            <strong className={cx('total')}>{formatPrice(totalPrice())}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default TicketList;
