import classNames from 'classnames/bind';
import styles from './Login.module.scss';
import { useState } from 'react';
import * as homeServices from '~/apiServices/homeServices';
import { useAuth } from '~/context/AuthContext';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

function Login() {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [error, setError] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');


    const handleUsernameChange = (value) => {
        setUsername(value);
    }

    const handlePasswordChange = (value) => {
        setPassword(value);
    }

    const resetData = () => {
        setUsername('');
        setPassword('');
    }

    const handleLogin = () => {
        //console.log(username, password);
        if (username.length === 0) {
            setError(true);
            return;
        }

        if (password.length === 0) {
            setError(true);
            return;
        }

        try {
            const apiLogin = async () => {
                const res = await homeServices.login({
                    username: username,
                    password: password
                });
                if (res.status) {
                    localStorage.setItem('loggedIn', 'true'); // Save login state
                    login();
                    navigate(`/super-admin`);
                } else {
                    setMessage(res.message);
                    setPassword('');
                }
            };
            apiLogin();
        } catch (error) {
            setMessage('An error occurred');
            resetData();
        }
    }

    return (
        <div className={cx('login-wrapper')}>
            <div className={cx('box')}>
                <div className={cx('head-title')}>
                    <h3>Đăng Nhập</h3>
                    <p className={cx('message', 'message-error')}>{message}</p>
                </div>
                <div className={cx('form-row')}>
                    <div className={cx('col')}>
                        <div className={cx('field', { unvalid: username.length === 0 })}>
                            <label>Tên đăng nhập</label>
                            <input
                                type="text"
                                value={username}
                                maxLength={10}
                                onChange={(e) => handleUsernameChange(e.target.value)}
                            />
                        </div>
                        <small className={cx('message', 'message-error')}>{error && username.length == 0 ? 'Chưa nhập tên đăng nhập' : ''}</small>
                    </div>
                </div>
                <div className={cx('form-row')}>
                    <div className={cx('col')}>
                        <div className={cx('field', { unvalid: password.length === 0 })}>
                            <span>Mật khẩu</span>
                            <input
                                type="password"
                                value={password}
                                maxLength={10}
                                onChange={(e) => handlePasswordChange(e.target.value)}
                            />
                        </div>
                        <small className={cx('message', 'message-error')}>{error && password.length == 0 ? 'Chưa nhập mật khẩu' : ''}</small>
                    </div>
                </div>
                <div className={cx('form-row')}>
                    <div className={cx('col')}>
                        <button className="btn-primary" onClick={handleLogin}>
                            Đăng Nhập
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;