import NavAdmin from '~/components/NavAdmin';
import AdminHeader from '../components/AdminHeader';
import { ToastContainer } from 'react-toastify';
function AdminLayout({ children }) {
    return (
        <div className="wrapper">
            <AdminHeader />
            <div className="container">
                <div className="content">{children}</div>
            </div>
            <NavAdmin />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default AdminLayout;
