import classNames from 'classnames/bind';
import styles from '../ProductIngredient.module.scss';
import { useState } from 'react';
import { Fragment } from 'react';
import ProductDetail from './ProductDetail';

const cx = classNames.bind(styles);
function ProductList({ datas, onUpdate }) {
    const [side, setSide] = useState(false);
    const [detail, setDetail] = useState({});

    const handleShowSide = (item) => {
        setSide(true);
        setDetail(item);
    };

    const handleUpdateDetail = (item) => {
        onUpdate(item);
        setSide(false);
    }
    return (
        <>
            <div className={cx('product-list')}>
                <div className={cx('product-section')}>
                    {Object.keys(datas).map((keyName) => {
                        const subProduct = datas[keyName];
                        return (
                            <div key={subProduct.key} className={cx('product-row')}>
                                <h4>{subProduct.title}</h4>
                                <table>
                                    <tbody>
                                        {subProduct.datas.map((item) => {
                                            return (
                                                <Fragment key={item.product_id}>
                                                    <tr onClick={() => handleShowSide(item)}>
                                                        <td colSpan={3} style={{ color: 'red' }}>
                                                            {item.product_name}
                                                        </td>
                                                    </tr>
                                                    {item.ingredients?.map((ingredient, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>- {ingredient.ingredient_name}</td>
                                                                <td className="text-right">
                                                                    <span className={cx('part')}>
                                                                        {ingredient.part}{' '}
                                                                    </span>
                                                                </td>
                                                                <td width={30}>Phần</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </Fragment>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ProductDetail item={detail} side={side} setSide={setSide} onUpdate={handleUpdateDetail} />
        </>
    );
}

export default ProductList;
