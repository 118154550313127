import classNames from 'classnames/bind';
import styles from '../Cart.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faMinus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '~/context/AppContext';
const cx = classNames.bind(styles);
function CartItem({ item, onChange, onRemove }) {
    const [quantity, setQuantity] = useState(0);
    const { cartSide } = useContext(AppContext);

    useEffect(() => {
        if (cartSide === true) {
            setQuantity(item.quantity);
        }
    }, [cartSide, quantity]);

    const handleMinus = (item) => {
        if (quantity === 1) {
            setQuantity(0);
            onRemove(item.id);
        } else {
            let newQuantity = quantity - 1;
            setQuantity(newQuantity);
            onChange(item.id, newQuantity);
        }
    };
    const handleAdd = (item) => {
        let newQuantity = quantity + 1;
        setQuantity(newQuantity);
        onChange(item.id, newQuantity);
    };

    const handleRemoveCartItem = (item) => {
        setQuantity(0);
        onRemove(item.id);
    };
    return (
        <>
            <tr className={cx('tr')} hidden={quantity === 0} data-id={item.id}>
                <td>
                    {item.product_name}
                    <p className={cx('text-xsmall')}>
                        {item.discount?.customer_name}
                        <br></br>
                        {item.discount?.customer_phone}
                    </p>
                </td>
                <td className="text-center">
                    <div className={cx('btn-group')}>
                        <button
                            onClick={() => handleMinus(item)}
                            className={cx('btn-minus', { show: quantity >= 0 })}
                            disabled={quantity === 0}
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <span className={cx('quantity', { show: quantity >= 0 })}>{quantity}</span>
                        <button onClick={() => handleAdd(item)} className={cx('btn-add', { show: quantity >= 0 })}>
                            <FontAwesomeIcon icon={faAdd} />
                        </button>
                    </div>
                </td>
                <td>
                    {item.options?.map((option, index) => {
                        return (
                            <p className={cx('text-small')} key={index}>
                                {option.option_name}
                            </p>
                        );
                    })}
                    <p className={cx('text-small')}>{item.note}</p>
                </td>
                <td>
                    <div className={cx('box-flex')}>
                        <span className={cx('price')}>{item.display_price}</span>
                        <button
                            onClick={() => handleRemoveCartItem(item)}
                            className={cx('btn-remove')}
                            // disabled={item.status !== 0}
                        >
                            <FontAwesomeIcon icon={faRemove} />
                        </button>
                    </div>
                </td>
            </tr>
        </>
    );
}

export default CartItem;
