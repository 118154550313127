import * as request from '~/utils/request';

export const login = async (data) => {
    try {
        const res = await request.post('users/login.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const checkLogin = async () => {
    try {
        const res = await request.get('users/check_login.php');
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getBills = async () => {
    try {
        const res = await request.get('bills/get_bills.php');
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getReceipts = async () => {
    try {
        const res = await request.get('receipts/get_receipts.php');
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getReceipt = async (params) => {
    try {
        const res = await request.get('receipts/get_receipt.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getStores = async (params) => {
    try {
        const res = await request.get('receipts/get_stores.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};


export const addReceiptIn = async (data) => {
    try {
        const res = await request.post('receipts/add_receipt_in.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const updateReceipt = async (data) => {
    try {
        const res = await request.post('receipts/update.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

