import classNames from 'classnames/bind';
import styles from './IngredientList.module.scss';
import * as adminServices from '~/apiServices/adminServices';
import { useEffect, useState } from 'react';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from '~/utils/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const cx = classNames.bind(styles);
function IngredientItem({ item, onUpdateItem, onRemoveItem }) {
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [unit, setUnit] = useState('');
    const [part, setPart] = useState(1);
    useEffect(() => {
        setName(item.ingredient_name);
        setDescription(item.ingredient_description);
        setUnit(item.ingredient_unit);
        setPart(item.ingredient_part);
    }, []);
    const handleClickEdit = () => {
        setEdit(true);
    };
    const handleEditSave = () => {
        const apiSave = async () => {
            const res = await adminServices.updateIngredient({
                action: 'ingredient',
                id: item.ingredient_id,
                name: name,
                description: description,
                unit: unit,
                part: part,
            });
            if (res.status) {
                const data = res.data;
                onUpdateItem({
                    ingredient_id: item.ingredient_id,
                    ingredient_name: name,
                    ingredient_description: description,
                    ingredient_unit: unit,
                    ingredient_part: part,
                });
                setEdit(false);
            } else {
                alert(res.message);
            }
        };
        apiSave();
    };
    const handleEditCancel = () => {
        setEdit(false);
    };

    const handleRemove = (item) => {
        if (confirm(`Bạn có chắc muốn xóa nguyên liệu ${item.ingredient_name} này?`)) {
            onRemoveItem(item.ingredient_id);
        }
    }
    return (
        <>
            {edit === true ? (
                <tr>
                    <td colSpan={5}>
                        <div className="edit-row">
                            <span className="col-auto">
                                <span className="small-label">Tên nguyên liệu:</span>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </span>
                            <span className="col-auto">
                                <span className="small-label">Đơn vị:</span>
                                <input type="text" value={unit} onChange={(e) => setUnit(e.target.value)} />
                            </span>
                            <span className="col-auto">
                                <span className="small-label">Định lượng:</span>
                                <input type="number" value={part} onChange={(e) => setPart(e.target.value)} />
                            </span>

                        </div>
                        <div className="edit-row">
                            <span className="col-auto">
                                <span className="small-label">Mô tả:</span>
                                <textarea type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </span>
                            <button className="btn-save" onClick={handleEditSave}>
                                <span>Lưu</span>
                            </button>
                            <button className="btn-cancel" onClick={handleEditCancel}>
                                <span>Hủy</span>
                            </button>
                        </div>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td><strong className="name">{item.ingredient_name}</strong></td>
                    <td>{item.ingredient_description}</td>
                    <td><span className={cx('unit')}>1 <small>{item.ingredient_unit}</small></span></td>
                    <td><span className={cx('part')}>{item.ingredient_part}</span></td>
                    <td>
                        <div className={cx('btn-wrapper')}>
                            <button
                                className={cx('btn-edit')}
                                onClick={handleClickEdit}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faEdit} />
                                </span>
                                <span> Chỉnh sửa</span>
                            </button>
                            <button
                                className={cx('danger')}
                                onClick={() => handleRemove(item)}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </span>
                                <span> Xóa</span>
                            </button>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}

export default IngredientItem;
