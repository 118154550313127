import classNames from 'classnames/bind';
import styles from '../../../Camping.module.scss';
const cx = classNames.bind(styles);
import { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CampingContext } from '~/context/CampingContext';
function Surcharge({ item, adt, aris, chd }) {
    const { camping } = useContext(CampingContext);
    const { surcharges } = camping;
    const arisItem = surcharges[6];
    const chdItem = surcharges[7];

    return (
        <>
            {adt === 1 ? (
                <>
                    <div className={cx('tent')}>
                        <div className={cx('tent-row')}>
                            <div className={cx('tent-item')}>
                                <div className={cx('tent-title')}>
                                    <span className={cx('icon')}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                    <span>{item.surcharge?.product_name}</span>
                                </div>
                                <strong className={cx('quantity')}>x {adt}</strong>
                                <strong className={cx('price')}>{item.surcharge?.display_price}</strong>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
            {aris > 0 ? (
                <>
                    <div className={cx('tent')}>
                        <div className={cx('tent-row')}>
                            <div className={cx('tent-item')}>
                                <div className={cx('tent-title')}>
                                    <span className={cx('icon')}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                    <span>{arisItem?.product_name}</span>
                                </div>
                                <strong className={cx('quantity')}>x {aris}</strong>
                                <strong className={cx('price')}>{arisItem?.display_price}</strong>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
            {chd > 0 ? (
                <>
                    <div className={cx('tent')}>
                        <div className={cx('tent-row')}>
                            <div className={cx('tent-item')}>
                                <div className={cx('tent-title')}>
                                    <span className={cx('icon')}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                    <span>{chdItem?.product_name}</span>
                                </div>
                                <strong className={cx('quantity')}>x {chd}</strong>
                                <strong className={cx('price')}>{chdItem?.display_price}</strong>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    );
}
export default Surcharge;
