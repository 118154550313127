import classNames from 'classnames/bind';
import styles from '../../../ProductIngredient.module.scss';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPenToSquare, faSave, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from '~/utils/filters';
import * as adminServices from '~/apiServices/adminServices';

const cx = classNames.bind(styles);
function IngredientItem({ item, onUpdateItem, onRemoveItem }) {
    const [edit, setEdit] = useState(false);
    const [q, setQ] = useState(0);

    useEffect(() => {
        setQ(item.part);
    }, []);

    const handleClickEdit = () => {
        setEdit(true);
    };

    const handleEditSave = (item) => {
        if (item.part === q) return setEdit(false);
        const apiUpdate = async () => {
            const res = await adminServices.updateIngredient({
                action: 'product-ingredient',
                id: item.id,
                part: q,
            });
            if (res.status) {
                onUpdateItem(item.id, q);
            } else {
                alert(res.message);
            }
        };
        apiUpdate();
        setEdit(false);
    };

    const handleEditCancel = () => {
        setEdit(false);
    };

    const handleRemove = (item) => {
        if (confirm(`Bạn có chắc muốn xóa liên kết ${item.ingredient_name} này?`)) {
            onRemoveItem(item.id);
        }
    }
    return (
        <>
            <tr key={item.ingredient_id}>
                <td>{item.ingredient_name}</td>
                <td width={150}>
                    {
                        edit == true ?
                            (
                                <span className={cx('edit-quantity')}>
                                    <input type="number" min={1} value={q} onChange={(e) => setQ(e.target.value)} />
                                </span>
                            )
                            :
                            (
                                <strong className={cx('quantity')}>
                                    {formatPrice(item.part)}
                                </strong>
                            )
                    }
                </td>
                <td width={50}>
                    <span className={cx('unit')}>Phần</span>
                </td>
                <td>
                    {
                        edit == true ? (
                            <div className={cx('btn-wrapper')}>
                                <button onClick={() => handleEditSave(item)}>
                                    <span>Lưu</span>
                                </button>
                                <button className="btn-cancel" onClick={handleEditCancel}>
                                    <span>Hủy</span>
                                </button>
                            </div>
                        ) :
                            (
                                <div className={cx('btn-wrapper')}>
                                    <button
                                        className={cx('btn-edit')}
                                        onClick={handleClickEdit}
                                    >
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </span>
                                        <span> Chỉnh sửa</span>
                                    </button>
                                    <button
                                        className={cx('danger')}
                                        onClick={() => handleRemove(item)}
                                    >
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </span>
                                        <span> Xóa</span>
                                    </button>
                                </div>
                            )
                    }

                </td>
            </tr>
        </>
    );
}

export default IngredientItem;
