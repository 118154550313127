import classNames from 'classnames/bind';
import styles from './CategoryList.module.scss';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
const cx = classNames.bind(styles);
function CategoryNav({ title = '' }) {
    const navigate = useNavigate();
    const categoryLink = `/super-admin/categories`;
    const handleCategory = (e) => {
        e.preventDefault();
        navigate(categoryLink);
    }
    const handleAdd = () => {
        navigate('/super-admin/category-add');
    }
    return (
        <div className={cx('product-nav')}>
            <h2 className="page-title">
                <div><a href={categoryLink} onClick={handleCategory}>Danh mục</a> {title ? `/ ${title}` : ''}</div>
                <div className={cx('nav-row')}>
                    <button className={cx('btn')} onClick={() => handleAdd()}>
                        <FontAwesomeIcon icon={faPlus} /><span> Thêm danh mục </span>
                    </button>
                </div>
            </h2>
        </div >
    );
}

export default CategoryNav;