import classNames from 'classnames/bind';
import styles from '../Camping.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
const cx = classNames.bind(styles);
function OrderItem({ order }) {
    return (
        <div className={cx('tent-service')}>
            <div className={cx('tent-title')}>
                <span className={cx('icon')}>
                    <FontAwesomeIcon icon={faCheck} />
                </span>
                <span>{order.product_name}</span>
            </div>
            <div className={cx('service-group')}>
                <span className={cx('quantity')}>x {order.quantity}</span>
                <strong className={cx('price')}>{order.display_price}</strong>
            </div>
        </div>
    );
}

export default OrderItem;
