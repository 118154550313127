import classNames from 'classnames/bind';
import styles from '../Print.module.scss';
import qrcodePayment from '~/assets/qrcode/qrcode-bidv.png';
const cx = classNames.bind(styles);
function PrintFoot({ tableKey, orderId, time }) {
    return (
        <>
            <div className={cx('print-qrcode')}>
                <img id="qrcode-payment" src={qrcodePayment} width={300} />
            </div>
            <div className={cx('print-footer')}>
                <p>
                    <b>Việt Úc Flower Garden</b>
                </p>
                <p>
                    <b>Chi Nhánh Công Ty Cổ Phần Đầu Tư</b>
                </p>
                <p>
                    <b>Du Lịch Việt Úc</b>
                </p>
                <p>
                    Tài khoản <b>8600 12 7979</b> - BIDV
                </p>
                <p>
                    Wifi: <b>Việt Úc Flower Garden</b>
                </p>
                <p>
                    Mật khẩu: <b>0907891414</b>
                </p>
                <p>
                    <b>Cảm ơn quý khách và hẹn gặp lại !!!</b>
                </p>
            </div>
        </>
    );
}

export default PrintFoot;
