import classNames from 'classnames/bind';
import styles from './ProductIngredient.module.scss';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import ProductList from './ProductList';
const cx = classNames.bind(styles);
function ProductIngredient() {
    const [productList, setProductList] = useState([]);

    const fetchApi = async () => {
        const response = await adminServices.getIngredientProducts();
        if (response) {
            console.log(response);
            setProductList(response);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    const handleUpdate = (item) => {
        const categoryItem = productList[item.product_type];
        const productDatas = categoryItem.datas.map(obj => {
            if (obj.product_id == item.product_id) {
                return item;
            }
            return obj;
        });
        const newCateogryItem = { ...categoryItem, datas: productDatas };
        productList[item.product_type] = newCateogryItem;
    }

    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">Sản Phẩm</h2>
            </div>
            <div className={cx('admin-body')}>
                <ProductList datas={productList} onUpdate={handleUpdate} />
            </div>
        </>
    );
}

export default ProductIngredient;
