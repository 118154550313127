import classNames from 'classnames/bind';
import styles from './NavAdmin.module.scss';
const cx = classNames.bind(styles);
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faGears, faList, faStore, faTent } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function NavAdmin() {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState('/super-admin');

    useEffect(() => {
        setActive(location.pathname);
    }, ['/']);

    const handleChangeNav = (active) => {
        setActive(active);
        navigate(active);
    };

    return (
        <>
            <div className={cx('navadmin')}>
                <div className={cx('navadmin-row')}>
                    <button
                        className={cx('btn-nav-menu', { active: active === '/super-admin/nav-menu' })}
                        onClick={() => handleChangeNav('/super-admin/nav-menu')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faList} />
                        </span>
                        <span>MENU</span>
                    </button>
                    <button
                        className={cx('btn-admin', { active: active === '/super-admin' })}
                        onClick={() => handleChangeNav('/super-admin')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faChartLine} />
                        </span>
                        <span>THỐNG KÊ</span>
                    </button>
                    <button
                        className={cx('btn-stores', { active: active === '/super-admin/stores' })}
                        onClick={() => handleChangeNav('/super-admin/stores')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faStore} />
                        </span>
                        <span>KHO</span>
                    </button>
                    <button
                        className={cx('btn-setting', { active: active === '/super-admin/settings' })}
                        onClick={() => handleChangeNav('/super-admin/settings')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faGears} />
                        </span>
                        <span>CÀI ĐẶT</span>
                    </button>
                </div>
                <br></br>
            </div>
        </>
    );
}

export default NavAdmin;
