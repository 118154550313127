import classNames from 'classnames/bind';
import styles from '../Camping.module.scss';
const cx = classNames.bind(styles);
import { useContext } from 'react';
import { CampingContext } from '~/context/CampingContext';
import SelectCamping from '../SelectCamping';
import EnterInfo from '../EnterInfo';
import Confirm from '../Confirm';

function Content() {
    const { step } = useContext(CampingContext);

    const renderHtml = () => {
        if (step === 1) {
            return <SelectCamping />;
        } else if (step === 2) {
            return <EnterInfo />;
        } else if (step === 3) {
            return <Confirm />;
        } else {
            return '';
        }
    };
    return (
        <>
            <div className={cx('camping-content')}>{renderHtml()}</div>
        </>
    );
}

export default Content;
