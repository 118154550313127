import classNames from 'classnames/bind';
import styles from './BbqList.module.scss';
import { dateNow, formatPrice } from '~/utils/filters';
import { faCalendarDay, faCancel, faComment, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as adminServices from '~/apiServices/adminServices';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BBQ_STATUS } from '~/utils/constants';
const cx = classNames.bind(styles);
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);
function BbqList() {
    const navigate = useNavigate();
    const [date, setDate] = useState('');
    const [dataList, setDataList] = useState([]);
    const fetchBbqs = async () => {
        const response = await adminServices.getBbqs();
        if (response) {
            setDataList([...dataList, ...response]);
        }
    };

    useEffect(() => {
        fetchBbqs();
    }, []);

    const handleGoDetail = (bbqId) => {
        navigate(`/super-admin/bbq/${bbqId}`);
    };

    const handleDateChange = (date) => {
        setDate(date);
    };

    const handleSearch = () => {
        console.log(date);
        if (date === '' || null) return;
        const filterBbqs = async () => {
            const response = await adminServices.getBbqs({
                params: {
                    date: moment(date).format('YYYY-MM-DD'),
                },
            });
            if (response) {
                setDataList(response);
            }
        };
        filterBbqs();
    };
    return (
        <>
            <div className={cx('bbqs')}>
                <h2 className="page-title">
                    Danh sách đặt bàn BBQ <span>{dateNow()}</span>
                </h2>
                <div className={cx('bbq-content')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <div className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faCalendarDay} />
                                    </span>
                                    <span className={cx('input-label')}>Ngày đặt:</span>
                                    <span className={cx('input-value')}>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={date}
                                            onChange={handleDateChange}
                                            placeholderText="Ngày đặt BBQ"
                                            locale="vi"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            shouldCloseOnSelect={true}
                                        />
                                    </span>
                                </div>
                            </div>
                            <button className="btn-primary" onClick={handleSearch}>
                                Tìm
                            </button>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Thông tin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList?.map((item, index) => {
                                return (
                                    <tr
                                        key={index}
                                        className={cx('tr-border')}
                                        onClick={() => handleGoDetail(item.bbq_id)}
                                    >
                                        <td className="text-center">#{item.bbq_id}</td>
                                        <td>
                                            <div className={cx('info')}>
                                                <div className={cx('data-group')}>
                                                    <div>
                                                        <strong>{item.table_name}</strong>
                                                        <br></br>
                                                        <strong style={{ color: 'var(--bg-primary)' }}>
                                                            {item.name}
                                                        </strong>
                                                        <p hidden={item.status === 0}>
                                                            <span className="label">
                                                                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                                            </span>
                                                            <span style={{ color: '#ff5722' }}>
                                                                {formatPrice(item.deposit)}đ
                                                            </span>
                                                        </p>
                                                        <p hidden={item.status !== 2}>
                                                            <span className="label">
                                                                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                                            </span>
                                                            <span style={{ color: '#ff5722' }}>
                                                                {formatPrice(item.total)}đ
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <span className={cx('datetime')}>
                                                        <span>{moment(item.date).format('DD-MM-YYYY')}</span>
                                                        <i style={{ color: '#ff5722' }}>{item.time}</i>
                                                        <strong className={cx('status', `status-${item.status}`)}>
                                                            {BBQ_STATUS[item.status]}
                                                        </strong>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={cx('note')}>
                                                <span className="label">
                                                    <FontAwesomeIcon icon={faComment} />
                                                </span>
                                                <span>{item.note}</span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default BbqList;
