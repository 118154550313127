import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
import * as homeServices from '~/apiServices/homeServices';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ReceiptContext } from './Receipt';
const cx = classNames.bind(styles);
function ReceiptConfirm() {
    const navigate = useNavigate();
    const { selected, info, setStep } = useContext(ReceiptContext);

    const handleSave = () => {
        if (selected.length == 0) {
            alert(`Chưa chọn nguyên liệu`);
            return;
        }
        const values = selected.map(s => {
            return {
                ingredient_id: s.ingredient_id,
                quantity: s.quantity,
                ingredient_part: s.ingredient_part
            }
        })
        const AddApi = async () => {
            const res = await homeServices.addReceiptIn({
                info: info,
                datas: values
            });
            if (res.status) {
                const receiptId = res.data;
                if (receiptId) navigate(`/receipt-list/${receiptId}`);
            } else {
                alert(res.message);
            }
        };
        AddApi();
    }
    return (
        <div className={cx('product')}>
            <h2 className="page-title">Xác nhận</h2>
            <div className={cx('product-list')}>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Tên Nguyên Liệu</th>
                                    <th>S.Lượng</th>
                                    <th>Phần</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selected?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width={250}>
                                                <strong className="name">{item.ingredient_name}</strong><br></br>
                                                <small>{item.ingredient_description}</small>
                                            </td>
                                            <td width={150}>
                                                <span><strong>{item.quantity}</strong> <small>{item.ingredient_unit}</small></span>
                                            </td>
                                            <td>
                                                <span><strong>{item.quantity * item.ingredient_part} </strong> <small>phần</small></span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={() => setStep(2)}>
                                <span>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                            </button>
                            <button className={cx('btn-save')} onClick={handleSave}>
                                <span>
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                                <span>TẠO PHIẾU</span>
                            </button>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReceiptConfirm;
