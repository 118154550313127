import classNames from 'classnames/bind';
import styles from './CategoryList.module.scss';
import { Fragment, useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { useNavigate } from 'react-router-dom';
import CategoryNav from './CategoryNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronLeft, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import CategoryItem from './CategoryItem';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
const cx = classNames.bind(styles);
function CategoryList() {
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState([]);
    const fetchApi = async () => {
        const response = await adminServices.getCategories();
        if (response) {
            setCategoryList(response);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    const handleUpdate = (item) => {
        const category = categoryList.find(c => c.category_id == item.category_parent);
        if (category) {

            const newItem = {
                ...category,
                childs: category.childs?.map(obj => {
                    if (obj.category_id == item.category_id) {
                        return item;
                    }
                    return obj;
                })
            }
            const newCategoryList = categoryList.map(obj => {
                if (obj.category_id == item.category_parent) {
                    return newItem;
                }
                return obj;
            })

            setCategoryList(newCategoryList);
        }
    }

    const handleRemove = (item) => {
        const removeApi = async () => {
            const res = await adminServices.removeCategory({
                category_id: item.category_id,
            });
            if (res.status) {
                const category = categoryList.find(c => c.category_id == item.category_parent);
                if (category) {
                    const newItem = {
                        ...category,
                        childs: category.childs?.filter(obj => {
                            return obj.category_id !== item.category_id;
                        })
                    }
                    const newCategoryList = categoryList.map(obj => {
                        if (obj.category_id == item.category_parent) {
                            return newItem;
                        }
                        return obj;
                    })
                    setCategoryList(newCategoryList);
                }

            } else {
                alert(res.message);
            }
        };
        removeApi();
    }

    return (
        <>
            <CategoryNav />
            <div className={cx('admin-body')}>
                <div className={cx('stores')}>
                    <table>
                        <thead>
                            <tr>
                                <th>Tên Danh Mục</th>
                                <th>Key</th>
                                <th className="text-center">Sắp Xếp</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categoryList?.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <tr className="trhead">
                                            <td><strong>{item.category_name}</strong></td>
                                            <td>{item.category_key}</td>
                                            <td className="text-center">
                                                <span className={cx('store')}>{item.category_order}</span>
                                            </td>
                                            <td></td>
                                        </tr>
                                        {item.childs?.map((child, i) => {
                                            return (
                                                <Fragment key={`${index}-${i}`}>
                                                    <CategoryItem child={child} onUpdate={handleUpdate} onRemove={handleRemove} />
                                                </Fragment>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default CategoryList;
