import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
import * as homeServices from '~/apiServices/homeServices';
import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faChevronLeft, faPlus, faSignature } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ReceiptContext } from './Receipt';
const cx = classNames.bind(styles);
function EnterInfo() {
    const navigate = useNavigate();
    const { selected, setStep, setInfo } = useContext(ReceiptContext);
    const [proposer, setProposer] = useState('');
    const [department, setDepartment] = useState('');
    const [purpose, setPurpose] = useState('');
    const handleNext = () => {
        if (proposer.length == 0) {
            alert(`Chưa chọn nguyên liệu`);
            return;
        }
        setInfo({
            proposer: proposer,
            department: department,
            purpose: purpose
        });
        setStep(4);
    }



    return (
        <div className={cx('product')}>
            <h2 className="page-title">Nhập thông tin</h2>
            <div className={cx('product-list')}>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field', { unvalid: proposer.length === 0 })}>
                                    <span className={cx('input-label')}>Người đề xuất:</span>
                                    <span className={cx('input-value')}>
                                        <input type="text" value={proposer} onChange={(e) => setProposer(e.target.value)} />
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Bộ phận:</span>
                                    <span className={cx('input-value')}>
                                        <input type="text" value={department} onChange={(e) => setDepartment(e.target.value)} />
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Mục đích sử dụng trang thiết bị:</span>
                                    <span className={cx('input-value')}>
                                        <input type="text" value={purpose} onChange={(e) => setPurpose(e.target.value)} />
                                    </span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={() => setStep(2)}>
                                <span>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                            </button>
                            <button className={cx('btn-save')} onClick={handleNext}>
                                <span>
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </span>
                                <span>TIẾP TỤC</span>
                            </button>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnterInfo;
