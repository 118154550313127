import classNames from 'classnames/bind';
import styles from '../Camping.module.scss';
const cx = classNames.bind(styles);
import { Fragment, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faClose, faSave } from '@fortawesome/free-solid-svg-icons';
import * as adminServices from '~/apiServices/adminServices';
import { CampingContext } from '~/context/CampingContext';
import ServiceItem from './ServiceItem';
function CampingAdd({ campIndex }) {
    const { camping, side, setSide, setServices, setProductList } = useContext(CampingContext);
    const { select, orders, productList } = camping;
    const [activeTab, setActiveTab] = useState('food');

    useEffect(() => {
        const newProductList = productList.map((obj) => {
            const newProducts = Object.keys(obj.products).map((keyName) => {
                const product = obj.products[keyName];
                const newDatas = product.datas.map((d) => {
                    if (select[campIndex]['orders'].some((f) => f.product_id === d.product_id)) {
                        const item = select[campIndex]['orders'].find((f) => f.product_id === d.product_id);
                        return { ...d, quantity: item.quantity };
                    }
                    return { ...d, quantity: 0 };
                });
                product.datas = newDatas;
                return product;
            });
            return { ...obj, products: newProducts };
        });
        setProductList(newProductList);
    }, [side]);

    const handleCloseSide = () => {
        setSide(false);
    };

    const handleBack = () => {
        setSide(false);
    };

    const handleSave = () => {
        var datas = [];
        for (let i in productList) {
            let obj = productList[i];
            for (let keyName in obj.products) {
                const product = obj.products[keyName];
                for (let i in product.datas) {
                    const p = product.datas[i];
                    if (p.quantity !== 0) {
                        datas.push(p);
                    }
                }
            }
        }
        setServices({
            campIndex: campIndex,
            services: datas,
        });
        setSide(false);
    };

    const handleTabControl = (tab) => {
        setActiveTab(tab);
    };

    const tabControl = () => {
        const newTabs =
            productList?.map((obj) => {
                return {
                    key: obj.category_key,
                    label: obj.category_name,
                };
            }) || [];
        return newTabs;
    };

    const handleUpdate = (productId, quantity) => {
        const newProductList = productList.map((obj) => {
            const newProducts = Object.keys(obj.products).map((keyName) => {
                const product = obj.products[keyName];

                const newDatas = product.datas.map((d) => {
                    if (d.product_id === productId) {
                        return { ...d, quantity: quantity };
                    }
                    return d;
                });
                product.datas = newDatas;
                return product;
            });
            return { ...obj, products: newProducts };
        });
        setProductList(newProductList);
    };
    return (
        <div className={cx('right-sides', { show: side })}>
            <div className={cx('header')}>
                <h3>Đặt lều</h3>
                <button onClick={handleCloseSide} className={cx('btn-close')}>
                    <FontAwesomeIcon icon={faClose} />
                </button>
            </div>
            <div className={cx('body')}>
                <div className={cx('product-list')}>
                    <nav className={cx('head')}>
                        <div className={cx('tabs')}>
                            {tabControl().map((tab, index) => {
                                return (
                                    <span
                                        key={index}
                                        className={cx(
                                            'tab-item',
                                            { active: tab.key === activeTab },
                                            { hide: tab.key === 'ticket' },
                                        )}
                                        onClick={(e) => {
                                            handleTabControl(tab.key);
                                        }}
                                    >
                                        {tab.label}
                                    </span>
                                );
                            })}
                        </div>
                    </nav>
                    {productList.map((obj) => {
                        return (
                            <div
                                key={obj.category_key}
                                className={cx('tab-content', { active: obj.category_key === activeTab })}
                            >
                                <div className={cx('product-section')}>
                                    <div id="order-list" className={cx('order-list')}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>TÊN</th>
                                                    <th className="text-center">SL</th>
                                                    <th className="text-right">GIÁ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {obj.products?.map((product, index) => {
                                                    return (
                                                        <Fragment key={product.key}>
                                                            <tr className={cx('trhead')}>
                                                                <td colSpan={3}>{product.title}</td>
                                                            </tr>
                                                            {product.datas?.map((item, index) => {
                                                                return (
                                                                    <ServiceItem
                                                                        key={`${index}`}
                                                                        campIndex={campIndex}
                                                                        item={item}
                                                                        onChange={handleUpdate}
                                                                    />
                                                                );
                                                            })}
                                                        </Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <br></br>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={cx('footer')}>
                <div className={cx('navbar')}>
                    <div className={cx('navbar-row')}>
                        <button className={cx('btn-back')} onClick={handleBack}>
                            <span>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </span>
                        </button>
                        <button className={cx('btn-save')} onClick={handleSave}>
                            <span>
                                <FontAwesomeIcon icon={faSave} />
                            </span>
                            <span>SAVE</span>
                        </button>
                    </div>
                    <br></br>
                </div>
            </div>
        </div>
    );
}

export default CampingAdd;
