import classNames from 'classnames/bind';
import styles from '../Receipt.module.scss';
import { dateFormat } from '~/utils/filters';
import DatePicker, { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
registerLocale('vi', vi);
const cx = classNames.bind(styles);
function ReceiptDetailItem({ item, index, status, onDateChange }) {
    const [startDate, setStartDate] = useState(null);
    const handleDateChange = (value) => {
        setStartDate(value);
        onDateChange(item.id, value);
    }
    const clearDate = () => {
        setStartDate(null);
    };
    useEffect(() => {
        if (item.expiry_date != null) {
            const newDate = new Date(item.expiry_date);
            setStartDate(newDate);
        }
    }, [])
    return (
        <>
            <tr>
                <td width={40} className="text-center">
                    <span>{index + 1}</span>
                </td>
                <td width={250}>
                    <span>{item.ingredient_name}</span>
                </td>
                <td width={80}>
                    <span><strong>{item.quantity}</strong> <small>{item.ingredient_unit}</small></span>
                </td>
                <td width={50}>
                    <span><strong>{item.part}</strong></span>
                </td>
                <td width={150}>
                    <div className={"datepicker-wrapper" + (item.expiry_date == null ? " required" : "")}>
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={startDate}
                            onChange={handleDateChange}
                            placeholderText="Hạn sử dụng"
                            locale="vi"
                            showMonthDropdown
                            showYearDropdown
                        />
                        {
                            startDate != null ?
                                <button className="datepicker-clear" onClick={clearDate}><FontAwesomeIcon icon={faClose} /></button>
                                : ''
                        }

                    </div>
                </td>
            </tr>
        </>
    );
}

export default ReceiptDetailItem;
