import classNames from 'classnames/bind';
import styles from '~/components/TicketList/TicketList.module.scss';
const cx = classNames.bind(styles);
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import * as ticketServices from '~/apiServices/ticketServices';
function TicketAdd({ onAddNew }) {
    const [number, setNumber] = useState(1);
    const [payment, setPayment] = useState('transfer');
    const [phone, setPhone] = useState('');
    const [showPhone, setShowPhone] = useState(false);

    const numberSelects = () => {
        const arrays = [];
        for (let i = 1; i <= 20; i++) {
            const n = 5 * i;
            arrays.push(n);
        }
        return arrays;
    };

    const handleNumberChange = (number) => {
        setNumber(number);
    };

    const handlePhoneChange = (phone) => {
        setPhone(phone);
    };

    const handleAddTicket = () => {
        const Add = async () => {
            const res = await ticketServices.addTicket({
                number: number,
                phone: phone,
                payment: payment,
            });
            if (res.status) {
                const data = res.data;
                onAddNew(data);
            } else {
                alert(res.message);
            }
        };
        Add();
    };

    const handleShowPhone = () => {
        setShowPhone(!showPhone);
    };

    const handlePaymentChange = (value) => {
        setPayment(value);
    };
    return (
        <div className={cx('form')}>
            <div className="form-row">
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faUsers} />
                        </span>
                        <span className={cx('input-label')}>Số lượng:</span>
                        <span className={cx('input-value')}>
                            <input
                                type="number"
                                min={1}
                                value={number}
                                onChange={(e) => handleNumberChange(e.target.value)}
                            />
                        </span>
                    </div>
                    <div className={cx('number-selects')}>
                        {numberSelects().map((n, index) => {
                            return (
                                <span key={index} className={cx('number-item')} onClick={() => setNumber(n)}>
                                    {n}
                                </span>
                            );
                        })}
                    </div>
                </label>
                <button className={cx('btn-show')} onClick={handleShowPhone}>
                    {showPhone ? 'Ẩn SĐT' : 'Hiện SĐT'}
                </button>
            </div>

            <div className={cx('form-row')} hidden={!showPhone}>
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faUsers} />
                        </span>
                        <span className={cx('input-label')}>SĐT:</span>
                        <span className={cx('input-value')}>
                            <input
                                type="text"
                                value={phone}
                                maxLength={10}
                                onChange={(e) => handlePhoneChange(e.target.value)}
                            />
                        </span>
                    </div>
                </label>
            </div>
            <div className="form-row">
                <div className="col">
                    <button className="btn-primary" onClick={handleAddTicket}>
                        Thêm vé
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TicketAdd;
