import classNames from 'classnames/bind';
import styles from './Head.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
const cx = classNames.bind(styles);
function Head() {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState('plan');

    useEffect(() => {
        setActive(location.pathname);
    }, ['/']);

    const handleChangeNav = (active) => {
        console.log(active);
        setActive(active);
        navigate(active);
    };
    return (
        <div className={cx('tabs')}>
            <button
                className={cx('tab-item', { active: active === '/camp-plan' })}
                onClick={() => handleChangeNav('/camp-plan')}
            >
                Sơ Đồ
            </button>
            <button
                className={cx('tab-item', { active: active === '/camp-order' })}
                onClick={() => handleChangeNav('/camp-order')}
            >
                Danh Sách
            </button>
            {/* <button
                className={cx('tab-item', { active: active === '/camp-calendar' })}
                onClick={() => handleChangeNav('/camp-calendar')}
            >
                Lịch
            </button> */}
        </div>
    );
}
export default Head;
