import classNames from 'classnames/bind';
import styles from './Bbq.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBurger } from '@fortawesome/free-solid-svg-icons';
import { dateNow } from '~/utils/filters';
const cx = classNames.bind(styles);
function Bbq() {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState('/bbq');

    useEffect(() => {
        setActive(location.pathname);
    }, ['/']);

    const handleChangeNav = (active) => {
        setActive(active);
        navigate(active);
    };
    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">
                    BBQ <span>{dateNow()}</span>
                </h2>
            </div>
            <div className={cx('admin-body')}>
                <div className={cx('row')}>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-bbqing', { active: active === '/super-admin/bbqing' })}
                            onClick={() => handleChangeNav('/super-admin/bbqing')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faAdd} />
                            </span>
                            <span>ĐẶT BBQ</span>
                        </button>
                    </div>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-bbqs', { active: active === '/super-admin/bbqs' })}
                            onClick={() => handleChangeNav('/super-admin/bbqs')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faBurger} />
                            </span>
                            <span>DANH SÁCH ĐẶT BBQ</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Bbq;
