import classNames from 'classnames/bind';
import styles from '../../../Bill.module.scss';
import { formatPrice } from '~/utils/filters';
import { Fragment } from 'react';
import moment from 'moment';
const cx = classNames.bind(styles);
function BillOrderCamp({ orders }) {
    return (
        <>
            {orders?.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr>
                            <td colSpan={4}>
                                <strong>- {item.table_name}</strong>
                            </td>
                        </tr>
                        {item.details?.map((d, i) => {
                            return (
                                <Fragment key={i}>
                                    <tr key={index}>
                                        <td>
                                            <span>
                                                {d.active ? (
                                                    <>
                                                        <strong>{d.product_name}</strong>
                                                        <span> ({moment(d.date).format('DD-MM-YYYY')})</span>
                                                    </>
                                                ) : (
                                                    <span> {d.product_name}</span>
                                                )}
                                            </span>
                                        </td>
                                        <td>
                                            <div className={cx('group-row')}>
                                                <span className={cx('price')}>{formatPrice(d.product_price)}</span>
                                                <span className={cx('mark')}>&nbsp; x</span>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <span className={cx('quantity')}>{d.quantity}</span>
                                        </td>
                                        <td className="text-right">
                                            <strong className={cx('price')}>
                                                {formatPrice(d.quantity * d.product_price)}
                                            </strong>
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </Fragment>
                );
            })}
        </>
    );
}

export default BillOrderCamp;
