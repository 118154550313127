import classNames from 'classnames/bind';
import styles from '../Camping.module.scss';
const cx = classNames.bind(styles);
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faPhone, faSignature } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
import moment from 'moment';
import { CampingContext } from '~/context/CampingContext';
import { getSurchargeFordate, getTentFordate, rangeInDates } from '~/utils/filters';
registerLocale('vi', vi);
function SelectCamping({ onDetail }) {
    const { camping, setStep, setInfo, setSelect, setDates } = useContext(CampingContext);
    const { tents, surcharges } = camping;
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const nowDate = new Date();
    const [checkIn, setCheckIn] = useState(nowDate);
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 1);
    const [checkOut, setCheckOut] = useState(nextDate);
    const [dataList, setDataList] = useState([]);

    const handleChangeCheckIn = (date) => {
        setCheckIn(date);
        setDataList([]);
    };
    const handleChangeCheckOut = (date) => {
        setCheckOut(date);
        setDataList([]);
    };
    const handleSearch = () => {
        if (checkIn === '' || checkOut === '' || null) return;
        const filterApi = async () => {
            const response = await adminServices.getCampings({
                params: {
                    check_in: moment(checkIn).format('YYYY-MM-DD'),
                    check_out: moment(checkOut).format('YYYY-MM-DD'),
                },
            });
            if (response) {
                const options = response.map((obj) => {
                    return {
                        ...obj,
                        is_checked: false,
                        adt: 2,
                        aris: 0,
                        chd: 0,
                        inf: 0,
                        orders: [],
                    };
                });
                setDataList(options);
            }
        };
        filterApi();
    };

    const handleChangeOption = (tableId) => {
        setDataList((prevOptions) => {
            const newOptions = prevOptions.map((obj) => {
                if (obj.table_id === tableId) {
                    return { ...obj, is_checked: !obj.is_checked };
                }
                return obj;
            });
            return newOptions;
        });
    };

    const handleContinues = () => {
        if (name.length === 0) {
            alert('Chưa nhập Họ và Tên');
            return;
        }

        if (phone.length === 0) {
            alert('Chưa nhập Số Điện Thoại');
            return;
        }

        if (phone.length < 10) {
            alert('Số Điện Thoại không đúng định dạng');
            return;
        }
        setInfo({
            name: name,
            phone: phone,
            check_in: moment(checkIn).format('YYYY-MM-DD'),
            check_out: moment(checkOut).format('YYYY-MM-DD'),
        });
        const dates = rangeInDates(checkIn, checkOut);
        setDates(dates);
        const datas = [];
        for (let i in dates) {
            let date = dates[i];
            const tent = getTentFordate(tents, date);
            const surcharge = getSurchargeFordate(surcharges, date);
            const item = {
                tent: tent,
                surcharge: surcharge,
                isHoliday: false,
                date: date,
            };
            datas.push(item);
        }

        const newDataList = dataList.filter((obj) => obj.is_checked === true);
        if (newDataList.length === 0) {
            alert('Chưa chọn lều');
            return;
        }
        const selectDatas = newDataList.map((obj) => {
            return { ...obj, datas: datas };
        });
        setSelect(selectDatas);
        setStep(2);
    };
    return (
        <>
            <div className={cx('campings-content')}>
                <div className={cx('form')}>
                    <div className="form-row">
                        <div className="col">
                            <div className={cx('input-field')}>
                                <span className={cx('input-icon')}>
                                    <FontAwesomeIcon icon={faCalendarDay} />
                                </span>
                                <span className={cx('input-label')}>Ngày nhận:</span>
                                <span className={cx('input-value')}>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={checkIn}
                                        onChange={(checkIn) => handleChangeCheckIn(checkIn)}
                                        placeholderText="Ngày nhận"
                                        locale="vi"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        shouldCloseOnSelect={true}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="col">
                            <div className={cx('input-field')}>
                                <span className={cx('input-icon')}>
                                    <FontAwesomeIcon icon={faCalendarDay} />
                                </span>
                                <span className={cx('input-label')}>Ngày trả:</span>
                                <span className={cx('input-value')}>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={checkOut}
                                        onChange={(checkOut) => handleChangeCheckOut(checkOut)}
                                        placeholderText="Ngày trả"
                                        locale="vi"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        shouldCloseOnSelect={true}
                                    />
                                </span>
                            </div>
                        </div>
                        <button className="btn-primary" onClick={handleSearch}>
                            Tìm
                        </button>
                    </div>
                </div>
                {dataList.length ? (
                    <>
                        <div className={cx('camping-map')}>
                            <div className={cx('camping-row')}>
                                {dataList?.map((item, index) => {
                                    return (
                                        <label
                                            key={index}
                                            className={cx('camping-item', { 'not-available': !item.available })}
                                        >
                                            <input
                                                type="checkbox"
                                                value={item.table_id}
                                                onChange={() => handleChangeOption(item.table_id)}
                                                checked={item.is_checked}
                                                disabled={!item.available}
                                            />
                                            <span> {item.table_name}</span>
                                        </label>
                                    );
                                })}
                            </div>
                            <div className="form-row">
                                <label className="col">
                                    <div className={cx('input-field', { unvalid: name.length === 0 })}>
                                        <span className={cx('input-icon')}>
                                            <FontAwesomeIcon icon={faSignature} />
                                        </span>
                                        <span className={cx('input-content')}>
                                            <span className={cx('input-label')}>Họ và Tên:</span>
                                            <span className={cx('input-value')}>
                                                <input
                                                    type="text"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </span>
                                        </span>
                                    </div>
                                </label>
                            </div>
                            <div className="form-row">
                                <label className="col">
                                    <div className={cx('input-field', { unvalid: phone.length < 10 })}>
                                        <span className={cx('input-icon')}>
                                            <FontAwesomeIcon icon={faPhone} />
                                        </span>
                                        <span className={cx('input-content')}>
                                            <span className={cx('input-label')}>Số điện thoại:</span>
                                            <span className={cx('input-value')}>
                                                <input
                                                    type="text"
                                                    value={phone}
                                                    maxLength={10}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </span>
                                        </span>
                                    </div>
                                </label>
                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <button className="btn-primary" onClick={handleContinues}>
                                        Tiếp Tục
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
            </div>
        </>
    );
}

export default SelectCamping;
