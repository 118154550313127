import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './AdminHeader.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faRotate, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '~/context/AuthContext';
const cx = classNames.bind(styles);
function AdminHeader() {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const handleClickReload = () => {
        window.location.reload(false);
    };

    const handleLogout = () => {
        logout();
    }
    return (
        <header className={cx('header')}>
            <button className={cx('btn-back')} onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <div className={cx('inner')}>
                <Link to="/super-admin" className={cx('page-title')}>
                    <h1>ADMINISTRATOR</h1>
                </Link>
            </div>
            <button className={cx('btn-reload')} onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOut} />
            </button>
        </header>
    );
}

export default AdminHeader;
