import { toast } from "react-toastify";

const options = {
    position: "top-right",
    autoClose: "5000",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    theme: "colored",
}

const error = (message) => {
    toast.error(message, options);
}

const success = (message) => {
    toast.success(message, options);
}

const info = (message) => {
    toast.info(message, options);
}

const warn = (message) => {
    toast.warn(message, options);
}

export default { error, success, info, warn }