import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import styles from '~/components/Order/Order.module.scss';
import { dateFormat, formatPrice, timeAgo } from '~/utils/filters';
import { ORDER_STATUS, PAYMENT_METHOD } from '~/utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as ticketServices from '~/apiServices/ticketServices';
import moment from 'moment';
import ChoiceButton from './ChoiceButton';
const cx = classNames.bind(styles);
function OrderItem({ order, onClickPrint, method, onPayment }) {
    const navigate = useNavigate();
    const { tableId, type, objId } = useParams();
    const [payment, setPayment] = useState('transfer');
    const [details, setDetails] = useState([]);
    useEffect(() => {
        setPayment(method);
    }, [method]);

    useEffect(() => {
        setDetails(order.details);
    }, []);

    const apiChangePayment = async (payment) => {
        await ticketServices.update({
            action: 'payment',
            ticket_id: Number(objId),
            payment: payment,
        });
    };

    const handlePaymentChange = (e) => {
        e.stopPropagation();
        setPayment(e.target.value);
        onPayment(e.target.value);
        apiChangePayment(e.target.value);
    };

    const handleBooking = (orderId) => {
        if (!order.disable) navigate(`/table-booking/${type}/${tableId}/${objId || 0}/${orderId}`);
    };

    const handleAddChild = (e, orderId, detailId) => {
        e.stopPropagation();
        if (order.active) navigate(`/table-booking/${type}/${tableId}/${objId}/${orderId}/${detailId}`);
    };

    const totalPrice = details?.reduce((total, item) => total + item.product_price * item.quantity, 0);

    const handleClickChange = (detailId, tent) => {
        console.log(tent);
        setDetails((prevDetails) => {
            const newDetails = prevDetails.map((obj) => {
                if (obj.detail_id === detailId) {
                    return {
                        ...obj,
                        detail_id: tent.detail_id,
                        product_name: tent.product_name,
                        product_price: tent.product_price,
                        display_price: tent.display_price,
                    };
                }
                return obj;
            });
            return newDetails;
        });
    };
    return (
        <>
            <div className={cx('order-item', { disable: order.disable }, { active: order.active })}>
                <span
                    className={cx('order-edit')}
                    onClick={() => {
                        handleBooking(order.order_id);
                    }}
                >
                    <FontAwesomeIcon icon={faEdit} /> #<strong>{order.order_id}</strong>
                </span>
                <div className={cx('date')}>
                    <span className={cx('date-at')}>{dateFormat(order.created_at)}</span>
                    <br />
                    <span className={cx('time-ago')}>{timeAgo(order.created_at)}</span>
                </div>
                <div className={cx('details')}>
                    <ul>
                        {details?.map((detail, index) => {
                            return (
                                <li key={index}>
                                    <div className={cx('detail-row', { 'li-head': detail.product_type === 'camp' })}>
                                        <div className={cx('detail-group')}>
                                            <strong className={cx('li-name')}>{detail.product_name}</strong>
                                            <br></br>
                                            {Object.keys(detail.discount).length ? (
                                                <div className={cx('li-sub')}>
                                                    <span>KH.TT</span> -{' '}
                                                    <span>
                                                        {detail.discount?.customer_phone} -{' '}
                                                        {detail.discount?.customer_name}
                                                    </span>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {detail.options ? (
                                                <div className={cx('li-sub')}>
                                                    {detail.options?.map((o) => o.option_name).join(' - ')}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {detail.note ? <p className={cx('li-sub')}>{detail.note}</p> : ''}
                                        </div>
                                        <div className={cx('price-group')}>
                                            <span className={cx('quantity')}>x {detail.quantity}</span>
                                            <strong className={cx('d-price')}>{detail.display_price}</strong>
                                        </div>
                                    </div>
                                    {order.active && detail.product_type === 'camp' ? (
                                        <>
                                            <div
                                                className={cx('detail-row', {
                                                    'li-bottom': detail.product_type === 'camp',
                                                })}
                                            >
                                                <span>
                                                    {moment(order.date).format('DD-MM-YYYY')}{' '}
                                                    {moment(order.date).format('dd')}
                                                </span>
                                                <ChoiceButton
                                                    item={{
                                                        detail_id: detail.detail_id,
                                                        product_id: detail.product_id,
                                                        product_name: detail.product_name,
                                                        display_price: detail.display_price,
                                                    }}
                                                    tents={detail.tents}
                                                    onChange={handleClickChange}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {order.active && detail.product_type === 'camp' ? (
                                        <button
                                            className={cx('btn-add-child')}
                                            onClick={(e) => handleAddChild(e, order.order_id, detail.detail_id)}
                                        >
                                            <FontAwesomeIcon icon={faPlus} /> <span>Dịch vụ lều</span>
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                    {detail.childs?.length ? (
                                        <ul>
                                            {detail.childs?.map((child, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className={cx('detail-row')}>
                                                            <div className={cx('detail-group')}>
                                                                <strong className={cx('li-name')}>
                                                                    - {child.product_name}
                                                                </strong>
                                                            </div>
                                                            <div className={cx('price-group')}>
                                                                <span className={cx('quantity')}>
                                                                    x {child.quantity}
                                                                </span>
                                                                <strong className={cx('d-price')}>
                                                                    {child.display_price}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    ) : (
                                        ''
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className={cx('order-group')}>
                    <span className={cx('status', `status-${order.order_status}`)}>
                        {ORDER_STATUS[order.order_status]}
                    </span>
                    {type === 'ticket' && order.active ? (
                        <>
                            <span className={cx('free')}>
                                <span>FREE </span>
                                <span>{order.sum_quantity}</span> <span>/ </span>
                                <span>{order.ticket_number}</span>
                            </span>
                        </>
                    ) : (
                        ''
                    )}
                    <strong className={cx('price')}>
                        <span> {formatPrice(totalPrice)}</span>
                    </strong>
                </div>
                {order.active && type === 'ticket' ? (
                    <div className={cx('order-group')}>
                        <div className={cx('payment-select')}>
                            <select
                                value={payment}
                                onChange={(e) => handlePaymentChange(e)}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {PAYMENT_METHOD.map((p, index) => {
                                    return (
                                        <option key={index} value={p.value}>
                                            {p.label}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <button className={cx('btn-print')} onClick={(e) => onClickPrint(e)}>
                            IN VÉ
                        </button>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    );
}

export default OrderItem;
