import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
import * as homeServices from '~/apiServices/homeServices';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ReceiptNav from './ReceiptNav';
import { inventoryStock } from '~/utils/filters';
const cx = classNames.bind(styles);
function IngredientList() {
    const [dataSource, setDataSource] = useState([]);
    const fetchApi = async () => {
        const response = await homeServices.getStores();
        if (response) {
            setDataSource(response);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);
    return (
        <div className={cx('product')}>
            <ReceiptNav />
            <div className={cx('product-list')}>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <table>
                            <thead>
                                <tr>
                                    <th className={cx('text-center')}>STT</th>
                                    <th>Tên Nguyên Liệu</th>
                                    <th className={cx('text-center')}>Tồn</th>
                                    <th className={cx('text-center')}>Trạng thái</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSource?.map((item, index) => {
                                    const inventory = item.stock;
                                    var stockClass = '';
                                    if (inventory <= 10 && inventory > 0) {
                                        stockClass = 'almost-stock';
                                    } else if (inventory <= 0) {
                                        stockClass = 'out-stock';
                                    }
                                    return (
                                        <tr key={index}>
                                            <td width={50} className="text-center">
                                                <span>{index + 1}</span>
                                            </td>
                                            <td width={250}>
                                                <strong className="name">{item.ingredient_name}</strong><br></br>
                                                <small>{item.ingredient_description}</small>
                                            </td>
                                            <td width={100} className={cx('text-center')}>
                                                <span><strong>{item.stock}</strong></span>
                                            </td>
                                            <td width={100} className="text-center">
                                                <div>{inventoryStock(inventory)}</div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IngredientList;
