import classNames from 'classnames/bind';
import styles from './Store.module.scss';
import { dateFormat, formatPrice, statusExpiry, statusStock } from '~/utils/filters';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { useNavigate, useParams } from 'react-router-dom';
import StoreNav from './StoreNav';
const cx = classNames.bind(styles);
function StockDetail() {
    const { ingredientId } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [details, setDetails] = useState([]);

    const fetchApis = async () => {
        const response = await adminServices.getStore({
            params: {
                ingredient_id: ingredientId,
            },
        });
        if (response) {
            let ingredientName = `${response.ingredient_name} (${response.ingredient_unit})`;
            setTitle(ingredientName);
            if (response.transactions.length > 0) {
                setDetails(response.transactions);
            }

        }
    };
    useEffect(() => {
        fetchApis();
    }, []);

    const totalQuantity = details.reduce((quantity, item) => quantity + item.quantity, 0);
    const totalPart = details.reduce((part, item) => {
        return item.type == 'in' ? part + item.part : part - item.part
    }, 0);
    return (
        <>
            <StoreNav title={title} />
            <div className={cx('admin-body')}>
                <div className={cx('stores')}>
                    <table>
                        <thead className="sticky">
                            <tr>
                                <th className="text-center">STT</th>
                                <th>Ngày</th>
                                <th className="text-center">Loại</th>
                                <th>S.Lượng</th>
                                <th>Phần</th>
                                <th>HSD</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td width={50} className="text-center">{index + 1}</td>
                                        <td>{dateFormat(item.date)}</td>
                                        <td className="text-center">{statusStock(item.type)}</td>
                                        <td width={80}><span><strong>{item.quantity}</strong> <small>{item.ingredient_unit}</small></span></td>
                                        <td><span><strong>{item.part}</strong> <small>phần</small></span></td>
                                        <td><span>{dateFormat(item.expiry_date)}</span></td>
                                        <td>{statusExpiry(item.expiry_date)}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td colSpan={3}>
                                    <b>Tổng Cộng:</b>
                                </td>
                                <td className="text-left">
                                    <strong className={cx('total')}>{totalQuantity}</strong>
                                </td>
                                <td className="text-left">
                                    <strong className={cx('total')}>{totalPart}</strong>
                                </td>
                                <td colSpan={2}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default StockDetail;
