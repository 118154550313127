import classNames from 'classnames/bind';
import styles from '../Admin.module.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import * as adminServices from '~/apiServices/adminServices';
import BillList from './BillList';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);

const cx = classNames.bind(styles);
function Bill() {
    const dateNow = new Date();
    const [startDate, setStartDate] = useState(dateNow);
    const [endDate, setEndDate] = useState(dateNow);
    const [datas, setDatas] = useState([]);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleFilter = () => {
        const filterApi = async () => {
            const response = await adminServices.getBills({
                params: {
                    start: moment(startDate).format('YYYY-MM-DD'),
                    end: moment(endDate).format('YYYY-MM-DD'),
                },
            });
            if (response) {
                setDatas(response);
            }
        };
        filterApi();
    };
    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">
                    Hóa đơn
                    <span>
                        {moment(startDate).format('DD/MM/YY')} - {moment(endDate).format('DD/MM/YY')}
                    </span>
                </h2>
            </div>
            <div className={cx('admin-head')}>
                <div className={cx('filter-row')}>
                    <div>
                        <div className={cx('input-field')}>
                            <span className={cx('filter-label')}>Từ ngày</span>
                            <div className={cx('input-value')}>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Ngày bắt đầu"
                                    locale="vi"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={cx('input-field')}>
                            <span className={cx('filter-label')}>Tới ngày</span>
                            <div className={cx('input-value')}>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Ngày kết thúc"
                                    locale="vi"
                                />
                            </div>
                        </div>
                    </div>
                    <button className={cx('btn-filter')} onClick={() => handleFilter()}>
                        <FontAwesomeIcon icon={faFilter} />
                        <span> Lọc</span>
                    </button>
                </div>
            </div>
            <div className={cx('admin-body')}>
                <BillList datas={datas} />
            </div>
        </>
    );
}

export default Bill;
