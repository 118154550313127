import classNames from 'classnames/bind';
import styles from './CategoryList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faClose, faSave } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);
function CategoryAdd() {
    const navigate = useNavigate();
    const [parentKey, setParentKey] = useState('');
    const [name, setName] = useState('');
    const [key, setKey] = useState('');
    const [order, setOrder] = useState(0);

    const cateOptions = [
        {
            value: 'ticket',
            label: 'Vé'
        },
        {
            value: 'water',
            label: 'Nước'
        },
        {
            value: 'food',
            label: 'BBQ'
        },
        {
            value: 'camp',
            label: 'Lều'
        },
        {
            value: 'service',
            label: 'Dịch vụ'
        },
    ]

    const handleSubmit = () => {
        if (parentKey == '') {
            return alert('Vui lòng chọn danh mục cha');
        }
        if (name == '') {
            return alert('Vui lòng nhập tên');
        }
        if (key == '') {
            return alert('Vui lòng nhập key');
        }

        const AddApi = async () => {
            const res = await adminServices.addCategory({
                parent_key: parentKey,
                name: name,
                key: key,
                order: Number(order),
            });
            if (res.status) {
                navigate(`/super-admin/categories`);
            } else {
                alert(res.message);
            }
        };
        AddApi();
    };

    return (
        <>
            <div className={cx('category')}>
                <div className={cx('header')}>
                    <h3>THÊM DANH MỤC CON</h3>
                </div>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Danh mục cha:</span>
                                    <span className={cx('input-value')}>
                                        <select value={parentKey} onChange={(e) => setParentKey(e.target.value)}>
                                            <option value="">Chọn danh mục cha</option>
                                            {
                                                cateOptions.map(o => (
                                                    <option key={o.value} value={o.value}>{o.label}</option>
                                                ))
                                            }
                                        </select>
                                    </span>
                                </div>
                            </label>
                            <label className="col-auto">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Sắp xếp:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="number"
                                            min={0}
                                            value={order}
                                            onChange={(e) => setOrder(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Tên danh mục:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </label>
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Key:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="text"
                                            value={key}
                                            onChange={(e) => setKey(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="form-row">
                            <label className="col">
                                <button className="btn-primary" onClick={handleSubmit}>
                                    Thêm
                                </button>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={() => navigate(-1)}>
                                <span>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                            </button>
                            <button className={cx('btn-save')} onClick={() => navigate(-1)}>
                                <span>
                                    <FontAwesomeIcon icon={faClose} />
                                </span>
                                <span>ĐÓNG</span>
                            </button>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryAdd;
