// Layouts
import { PrintLayout } from '~/components/Layout';
import Table from '~/pages/Table';
import TableDetail from '~/pages/Table/TableDetail';
import Booking from '~/pages/Table/TableDetail/Booking';
import PrintBill from '~/pages/Print/PrintBill';
import PrintProcessing from '~/pages/Print/PrintProcessing';
import BookingDetail from '~/pages/Table/TableDetail/Booking/BookingDetail';
import AdminStore from '~/pages/Admin/Store';

import Ticket from '~/pages/Ticket';
import PrintTicket from '~/pages/Print/PrintTicket';

import Product from '~/pages/Product';

import AdminBbq from '~/pages/Admin/Bbq';
import AdminBbqList from '~/pages/Admin/Bbq/BbqList';
import AdminBbqDetail from '~/pages/Admin/Bbq/BbqList/BbqDetail';
import AdminBbqEdit from '~/pages/Admin/Bbq/BbqList/BbqEdit';
import AdminBbqPrintProcessing from '~/pages/Admin/Bbq/BbqList/BbqDetail/BbqPrintProcessing';

import AdminSetting from '~/pages/Admin/Setting';
import AdminIngredient from '~/pages/Admin/Ingredient';
import AdminProductIngredient from '~/pages/Admin/ProductIngredient';
import AdminStatistic from '~/pages/Admin/Statistic';
import AdminBill from '~/pages/Admin/Bill';
import AdminNavMenu from '~/pages/Admin/NavMenu';
import AdminTicket from '~/pages/Admin/Ticket';


import AdminLoyalCustomer from '~/pages/Admin/LoyalCustomer';
import AdminWater from '~/pages/Admin/Water';
import AdminSellerCustomer from '~/pages/Admin/SellerCustomer';
import AdminCamp from '~/pages/Admin/Camp';
import AdminCampList from '~/pages/Admin/Camp/CampList';
import AdminCampDetail from '~/pages/Admin/Camp/CampList/CampDetail';
import AdminCamping from '~/pages/Admin/Camp/Camping';
import AdminCampingComplete from '~/pages/Admin/Camp/Camping/Complete';
import CampPlan from '~/pages/Camp/CampPlan';
import CampPlanDetail from '~/pages/Camp/CampPlan/CampPlanDetail';
import CampOrder from '~/pages/Camp/CampOrder';
import CampOrderDetail from '~/pages/Camp/CampOrder/CampOrderDetail';
import CampBill from '~/pages/Camp/CampBill';
import CampCalendar from '~/pages/Camp/CampCalendar';
import AdminBbqing from '~/pages/Admin/Bbq/Bbqing';
import AdminBbqFinish from '~/pages/Admin/Bbq/Bbqing/BbqFinish';

import AdminReceiptList from '~/pages/Admin/Store/ReceiptList';
import AdminReceiptDetail from '~/pages/Admin/Store/ReceiptDetail';
import AdminStockIn from '~/pages/Admin/Store/StockIn';
import AdminStockHistory from '~/pages/Admin/Store/StockHistory';
import AdminStockHistoryDetail from '~/pages/Admin/Store/StockHistoryDetail';
import AdminStockDetail from '~/pages/Admin/Store/StockDetail';
import AdminStockOut from '~/pages/Admin/Store/StockOut';

import AdminProduct from '~/pages/Admin/Product';
import AdminProductDetail from '~/pages/Admin/Product/ProductDetail';
import AdminCategoryList from '~/pages/Admin/Product/CategoryList';
import AdminCategoryAdd from '~/pages/Admin/Product/CategoryList/CategoryAdd';
import AdminCategoryDetail from '~/pages/Admin/Product/CategoryDetail';

import Bill from '~/pages/Bill';
import Receipt from '~/pages/Receipt';
import ReceiptList from '~/pages/Receipt/ReceiptList';
import ReceiptDetail from '~/pages/Receipt/ReceiptDetail';
import PrintReceipt from '~/pages/Receipt/ReceiptDetail/PrintReceipt';

import Login from '~/pages/User/Login';
import LoginLayout from '~/components/Layout/LoginLayout';
import IngredientList from '~/pages/Receipt/IngredientList';


const publicRoutes = [
    // LOGIN
    { path: '/login', component: Login, layout: LoginLayout },

    // TABLE
    { path: '/table-plan', component: Table },
    { path: '/table-detail/:type/:tableId/:objId?', component: TableDetail },
    { path: '/table-booking/:type/:tableId/:objId?/:orderId/:detailId?', component: Booking },
    { path: '/booking-detail/:tableId/:orderId', component: BookingDetail },
    { path: '/print-ticket/:ticketId', component: PrintTicket, layout: PrintLayout },
    { path: '/print-bill/:type/:tableId/:objId', component: PrintBill, layout: PrintLayout },
    { path: '/print-processing/:orderId', component: PrintProcessing, layout: PrintLayout },
    //TICKET
    { path: '/', component: Ticket },
    //PRODUCT
    { path: '/product', component: Product },
    //BILL
    { path: '/bill', component: Bill },
    //RECEIPT
    { path: '/ingredient-list', component: IngredientList },
    { path: '/receipt', component: Receipt },
    { path: '/receipt-list', component: ReceiptList },
    { path: '/receipt-list/:receiptId', component: ReceiptDetail },
    { path: '/print-receipt/:receiptId', component: PrintReceipt, layout: PrintLayout },

    //BBQ
    // { path: '/bbq', component: Bbq },
    // { path: '/bbq/:bbqId', component: BbqFinish },
    //CAMPING
    { path: '/camp-plan', component: CampPlan },
    { path: '/camp-plan/:tableId', component: CampPlanDetail },
    { path: '/camp-order', component: CampOrder },
    { path: '/camp-order/:campId', component: CampOrderDetail },
    { path: '/camp-calendar', component: CampCalendar },
    { path: '/camp-bill/:campId/:tableId?', component: CampBill, layout: PrintLayout },
];

const privateRoutes = [
    //private routes
    { path: '/super-admin', component: AdminStatistic },
    { path: '/super-admin/nav-menu', component: AdminNavMenu },
    { path: '/super-admin/bbq', component: AdminBbq },
    { path: '/super-admin/bbqs', component: AdminBbqList },
    { path: '/super-admin/bbq/:bbqId', component: AdminBbqDetail },
    { path: '/super-admin/bbq-edit/:bbqId', component: AdminBbqEdit },
    { path: '/super-admin/bbq-print-processing/:bbqId', component: AdminBbqPrintProcessing, layout: PrintLayout },
    { path: '/super-admin/bbqing', component: AdminBbqing },
    { path: '/super-admin/bbqing/:bbqId', component: AdminBbqFinish },

    { path: '/super-admin/products', component: AdminProduct },
    { path: '/super-admin/product/:catId/:type', component: AdminProductDetail },
    { path: '/super-admin/categories', component: AdminCategoryList },
    { path: '/super-admin/category/:catId/:type', component: AdminCategoryDetail },
    { path: '/super-admin/category-add', component: AdminCategoryAdd },
    { path: '/super-admin/bills', component: AdminBill },
    { path: '/super-admin/tickets', component: AdminTicket },
    { path: '/super-admin/loyal-customers', component: AdminLoyalCustomer },
    { path: '/super-admin/seller-customers', component: AdminSellerCustomer },
    { path: '/super-admin/waters', component: AdminWater },
    { path: '/super-admin/camp', component: AdminCamp },
    { path: '/super-admin/camps', component: AdminCampList },
    { path: '/super-admin/camp/:campId', component: AdminCampDetail },
    { path: '/super-admin/camping', component: AdminCamping },
    { path: '/super-admin/camping/:campId', component: AdminCampingComplete },

    { path: '/super-admin/stores', component: AdminStore },
    { path: '/super-admin/store/:ingredientId', component: AdminStockDetail },
    { path: '/super-admin/stock-in', component: AdminStockIn },
    { path: '/super-admin/stock-out', component: AdminStockOut },
    { path: '/super-admin/stock-history', component: AdminStockHistory },
    { path: '/super-admin/stock-history/:storeId', component: AdminStockHistoryDetail },
    { path: '/super-admin/receipts', component: AdminReceiptList },
    { path: '/super-admin/receipt/:receiptId', component: AdminReceiptDetail },
    { path: '/super-admin/settings', component: AdminSetting },
    { path: '/super-admin/ingredients', component: AdminIngredient },
    { path: '/super-admin/product-ingredients', component: AdminProductIngredient },
];

export { publicRoutes, privateRoutes };
