import { useEffect, useState } from 'react';
import * as campServices from '~/apiServices/campServices';
import * as orderServices from '~/apiServices/orderServices';
import classNames from 'classnames/bind';
import styles from './CampOrderDetail.module.scss';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { formatPrice } from '~/utils/filters';
import CampOrderItem from './CampOrderItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPrint, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import PaymentModal from '~/components/PaymentModal/PaymentModal';
const cx = classNames.bind(styles);

function CampOrderDetail() {
    const navigate = useNavigate();
    const { campId } = useParams();
    const [info, setInfo] = useState({});
    const [datas, setDatas] = useState([]);
    const [tableId, setTableId] = useState(0);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const fetchCamp = async () => {
            const response = await campServices.getOrder({
                params: {
                    camp_id: Number(campId),
                },
            });
            if (response) {
                setInfo(response.info);
                setDatas(response.datas);
            }
        };
        fetchCamp();
    }, []);

    const handlePrint = () => {
        const f = document.getElementById('iframe-bill');
        const w = f.contentWindow;
        w.postMessage({ action: 'print-bill' }, f.src);
    };

    const handleClickPrint = (tableId) => {
        setTableId(tableId);
        setTimeout(() => {
            handlePrint();
        }, 1200);
    };
    const handleClickPrintBill = () => {
        setTableId(0);
        setLoading(true);
        setTimeout(() => {
            handlePrint();
            setLoading(false);
        }, 1200);
    };

    const handleShowModal = (e) => {
        setShow(true);
    };

    const handleModalClose = () => {
        setShow(false);
    };

    const handleModalSubmit = (type) => {
        let transfer = 0;
        let cash = 0;
        if (type === 'transfer') {
            cash = 0;
            transfer = info.total;
        } else if (type === 'cash') {
            transfer = info.deposit;
            cash = info.total - info.deposit;
        }
        console.log(transfer, cash);
        setProcessing(true);
        const apiUpdate = async () => {
            const response = await orderServices.bookingEnd({
                type: 'camp',
                table_id: 0,
                obj_id: Number(campId),
                transfer: transfer,
                cash: cash,
            });
            if (response) navigate('/camp-order');
            setShow(false);
            setProcessing(false);
        };
        setTimeout(() => {
            apiUpdate();
        }, 1500);
    };
    return (
        <>
            {Object.keys(info).length ? (
                <>
                    <div className="page-title">
                        <div className={cx('group-title')}>
                            <h3 className={cx('title')}>
                                #{info.camp_id} - {info.name}
                            </h3>
                            <span className={cx('sub-title')}>{info.phone}</span>
                        </div>
                        <div className={cx('time')}>
                            <span>
                                <span>{moment().format('DD/MM/YYYY')}</span>
                            </span>
                        </div>
                    </div>
                    <div className={cx('camp-info')}>
                        <p>
                            <span>
                                Ngày nhận: <strong>{moment(info.check_in).format('DD-MM-YYYY')}</strong>
                            </span>
                            <span> </span>
                            <span>
                                Ngày Trả: <strong>{moment(info.check_out).format('DD-MM-YYYY')}</strong>
                            </span>
                        </p>
                        <p>
                            <span>
                                Cọc: <strong>{formatPrice(info.deposit)}</strong>
                            </span>
                        </p>
                        <div>
                            <h4>Chú thích: </h4>
                            <p>- {info.note}</p>
                            <table>
                                <tbody>
                                    {info.services?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.product_name}</td>
                                                <td>x {item.quantity}</td>
                                                <td className="text-right">{item.display_price}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={cx('page-content')}>
                        <div className={cx('orders')}>
                            {datas?.map((item, index) => {
                                return <CampOrderItem key={index} item={item} onClickPrint={handleClickPrint} />;
                            })}
                        </div>
                        <table>
                            <tfoot>
                                <tr>
                                    <td colSpan={3}>Tổng tiền:</td>
                                    <td className="text-right">
                                        <strong className={cx('camp-total')}>{formatPrice(info.total)}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>Đã Thanh toán:</td>
                                    <td className="text-right">
                                        <strong className={cx('total')}>{formatPrice(info.paid)}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <b>Còn lại:</b>
                                    </td>
                                    <td className="text-right">
                                        <strong className={cx('total')}>
                                            {formatPrice(info.total - info.paid - info.discount)}
                                        </strong>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <iframe
                            id="iframe-bill"
                            src={`/camp-bill/${campId}/${tableId}`}
                            style={{ display: 'none' }}
                            title="PRINT BILL"
                        />
                        <div className={cx('navbar')}>
                            <div className={cx('navbar-row')}>
                                <button className={cx('btn-back')} onClick={() => navigate('/camp-order')}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <button
                                    className={cx('btn-print-bill', { loading: loading })}
                                    onClick={handleClickPrintBill}
                                >
                                    {loading ? (
                                        <>
                                            <span>
                                                <FontAwesomeIcon spin={true} icon={faSpinner} />
                                            </span>
                                            <span>In Hóa Đơn Tổng</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>
                                                <FontAwesomeIcon icon={faPrint} />
                                            </span>
                                            <span>In Hóa Đơn Tổng</span>
                                        </>
                                    )}
                                </button>
                                <button className={cx('btn-booking-end')} onClick={handleShowModal}>
                                    <>
                                        <span>
                                            <FontAwesomeIcon icon={faSave} />
                                        </span>
                                        <span>Đóng lều</span>
                                    </>
                                </button>
                            </div>
                            <br></br>
                        </div>
                        <PaymentModal
                            open={show}
                            processing={processing}
                            setProcessing={setProcessing}
                            onClose={handleModalClose}
                            onSubmit={handleModalSubmit}
                        />
                        <br></br>
                        <br></br>
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    );
}
export default CampOrderDetail;
