import classNames from 'classnames/bind';
import styles from '../Receipt.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
const cx = classNames.bind(styles);

function ReceiptProcessing({ status }) {

    return (
        <>
            <div className={cx('receipt-processing')}>
                <div className={cx('processing-row')}>
                    <button className={cx('processing step-1', { active: status >= 0 })}>
                        <span className={cx('processing-number')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span className={cx('processing-text')}>Tạo phiếu</span>
                    </button>
                    <span className={cx('bar')}></span>
                    <button className={cx('processing processing-2', { active: status >= 1 })}>
                        <span className={cx('processing-number')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span className={cx('processing-text')}>Cập nhật HSD</span>
                    </button>
                    <span className={cx('bar')}></span>
                    <button className={cx('processing processing-3', { active: status >= 2 })}>
                        <span className={cx('processing-number')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span className={cx('processing-text')}>Báo quản lý duyệt</span>
                    </button>
                    <span className={cx('bar')}></span>
                    <button className={cx('processing processing-4', { active: status >= 3 })}>
                        <span className={cx('processing-number')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span className={cx('processing-text')}>Đã duyệt</span>
                    </button>
                </div>
                <br></br>
            </div>
        </>
    );
}

export default ReceiptProcessing;
