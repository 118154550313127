import classNames from 'classnames/bind';
import styles from '../TableModal.module.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faChildren, faPeopleGroup, faPhone, faSignature } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import * as tableServices from '~/apiServices/tableServices';
const cx = classNames.bind(styles);
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);
function FormCamping({ table, onClose }) {
    const naviate = useNavigate();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [adt, setAdt] = useState(1);
    const [chd, setChd] = useState(0);

    const dateNow = new Date();
    const [checkIn, setCheckIn] = useState(dateNow);
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 1);
    const [checkOut, setCheckOut] = useState(nextDate);

    const handleClickNewOrderCamping = () => {
        if (name.length === 0) {
            alert('Chưa nhập Họ và Tên');
            return;
        }

        if (phone.length === 0) {
            alert('Chưa nhập Số Điện Thoại');
            return;
        }

        if (phone.length < 10) {
            alert('Số Điện Thoại không đúng định dạng');
            return;
        }

        const apiAdd = async () => {
            const res = await tableServices.addCustomerBbq({
                table_id: Number(table.table_id),
                name: name,
                phone: phone,
                adt: adt,
                chd: chd,
            });
            if (res) {
                const bbqId = res.data;
                if (bbqId) naviate(`/table-detail/camping/${table.table_id}/${bbqId}`);
            } else {
                alert(res.message);
            }
        };
        apiAdd();
    };
    return (
        <>
            <div className={cx('popup-head')}>
                <h3>Khách mới - LỀU</h3>
                <p>{table.table_name}</p>
            </div>
            <div className={cx('popup-form')}>
                <div className="form-row">
                    <label className="col">
                        <div className={cx('input-field', { unvalid: name.length === 0 })}>
                            <span className={cx('input-icon')}>
                                <FontAwesomeIcon icon={faSignature} />
                            </span>
                            <span className={cx('input-label')}>Họ và Tên:</span>
                            <span className={cx('input-value')}>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </span>
                        </div>
                    </label>
                </div>
                <div className="form-row">
                    <label className="col">
                        <div className={cx('input-field', { unvalid: phone.length < 10 })}>
                            <span className={cx('input-icon')}>
                                <FontAwesomeIcon icon={faPhone} />
                            </span>
                            <span className={cx('input-label')}>Số điện thoại:</span>
                            <span className={cx('input-value')}>
                                <input
                                    type="text"
                                    value={phone}
                                    maxLength={10}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </span>
                        </div>
                    </label>
                </div>
                <div className="form-row">
                    <label className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('input-icon')}>
                                <FontAwesomeIcon icon={faPeopleGroup} />
                            </span>
                            <span className={cx('input-label')}>Người lớn:</span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <input type="text" value={adt} onChange={(e) => setAdt(e.target.value)} />
                                </div>
                            </span>
                        </div>
                    </label>
                    <label className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('input-icon')}>
                                <FontAwesomeIcon icon={faChildren} />
                            </span>
                            <span className={cx('input-label')}>Trẻ em:</span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <input type="text" value={chd} onChange={(e) => setChd(e.target.value)} />
                                </div>
                            </span>
                        </div>
                    </label>
                </div>
                <div className="form-row">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faCalendarDay} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>Ngày nhận:</span>
                            <span className={cx('input-value')}>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={checkIn}
                                    onChange={setCheckIn}
                                    selectsStart
                                    startDate={checkIn}
                                    placeholderText="Ngày đặt"
                                    locale="vi"
                                />
                            </span>
                        </span>
                    </div>
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faCalendarDay} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>Ngày trả:</span>
                            <span className={cx('input-value')}>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={checkOut}
                                    onChange={setCheckOut}
                                    selectsStart
                                    startDate={checkOut}
                                    placeholderText="Ngày trả"
                                    locale="vi"
                                />
                            </span>
                        </span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <button className="btn-primary" onClick={handleClickNewOrderCamping}>
                            Thêm
                        </button>
                    </div>
                    <div className="col">
                        <button className="btn-default" onClick={onClose}>
                            Hủy
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormCamping;
