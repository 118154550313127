import classNames from 'classnames/bind';
import styles from './Store.module.scss';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
import StoreNav from './StoreNav';
import moment from 'moment';
registerLocale('vi', vi);
const cx = classNames.bind(styles);
function StockOut() {
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const fetchStores = async () => {
        const response = await adminServices.getStores();
        if (response) {
            const newStores = response.map(s => {
                return { ...s, part: 0 }
            })
            setStores(newStores);
        }
    };
    useEffect(() => {
        fetchStores();
    }, []);

    const handlePartChange = (part, ingredient_id) => {
        setStores((prevStores) => {
            const newStores = prevStores.map((obj) => {
                if (obj.ingredient_id === ingredient_id) {
                    return { ...obj, part: Number(part) };
                }
                return obj;
            });
            return newStores;
        });
    };

    const handleNoteChange = (note, ingredient_id) => {
        setStores((prevStores) => {
            const newStores = prevStores.map((obj) => {
                if (obj.ingredient_id === ingredient_id) {
                    return { ...obj, note: note };
                }
                return obj;
            });
            return newStores;
        });
    };

    const handleSave = () => {
        const newStores = stores.filter(s => s.part !== 0);
        if (newStores.length == 0) {
            alert(`Chưa nhập dữ liệu`);
            return;
        }
        const AddApi = async () => {
            const res = await adminServices.addStockOut({
                datas: newStores
            });
            if (res.status) {
                navigate('/super-admin/stock-history');
            } else {
                alert(res.message);
            }
        };
        AddApi();
    }
    return (
        <>
            <StoreNav title="Xuất kho" />
            <div className={cx('admin-body')}>
                <div className={cx('stock-in')}>
                    <div className={cx('header')}>
                        <h3>XUẤT KHO</h3>
                    </div>
                    <div className={cx('body')}>
                        <div className={cx('form')}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Tên Nguyên Liệu</th>
                                        <th className={cx('text-center')}>Tồn</th>
                                        <th>S.Lượng</th>
                                        <th>Ghi chú</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stores?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td width={200}>{item.ingredient_name}</td>
                                                <td width={100} className={cx('text-center')}>
                                                    <span className={cx('store')}>{item.stock}</span>
                                                </td>
                                                <td width={150}>
                                                    <div className="table-row">
                                                        <input
                                                            id={`quantity-${index}`}
                                                            type="number"
                                                            min={0}
                                                            value={item.part}
                                                            onChange={(e) => handlePartChange(e.target.value, item.ingredient_id)}
                                                            style={{ width: '100px' }}
                                                        />
                                                        <small className={cx('available')}>Phần</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        id={`note-${index}`}
                                                        type="text"
                                                        value={item.note}
                                                        onChange={(e) => handleNoteChange(e.target.value, item.ingredient_id)}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={cx('footer')}>
                        <div className={cx('navbar')}>
                            <div className={cx('navbar-row')}>
                                <button className={cx('btn-back')} onClick={() => navigate(-1)}>
                                    <span>
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </span>
                                </button>
                                <button className={cx('btn-save')} onClick={handleSave}>
                                    <span>
                                        <FontAwesomeIcon icon={faArrowRightFromBracket} />
                                    </span>
                                    <span>XUẤT KHO</span>
                                </button>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StockOut;
