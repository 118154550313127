import classNames from 'classnames/bind';
import styles from './Store.module.scss';
import { dateFormat, formatPrice } from '~/utils/filters';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { useNavigate, useParams } from 'react-router-dom';
import StoreNav from './StoreNav';
const cx = classNames.bind(styles);
function StockHistoryDetail() {
    const { storeId } = useParams();
    const navigate = useNavigate();
    const [details, setDetails] = useState([]);

    const fetchApis = async () => {
        const response = await adminServices.getStockHistoryDetails({
            params: {
                store_id: storeId,
            },
        });
        if (response) {
            setDetails(response);
        }
    };
    useEffect(() => {
        fetchApis();
    }, []);

    const totalQuantity = details.reduce((quantity, item) => quantity + item.quantity, 0);
    const totalPart = details.reduce((part, item) => part + item.part, 0);
    return (
        <>
            <StoreNav title="Chi tiết lịch sử nhập kho" />
            <div className={cx('admin-body')}>
                <div className={cx('stores')}>
                    <table>
                        <thead className="sticky">
                            <tr>
                                <th className="text-center">ID</th>
                                <th>Tên Nguyên Liệu</th>
                                <th>D.Lượng</th>
                                <th>S.Lượng</th>
                                <th>Phần</th>
                                <th>HSD</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td width={50} className="text-center">{item.transaction_id}</td>
                                        <td>{item.ingredient_name}</td>
                                        <td>{item.ingredient_part}</td>
                                        <td>
                                            <span><strong>{item.quantity}</strong> <small>{item.ingredient_unit}</small></span>
                                        </td>
                                        <td>
                                            <span><strong>{item.part}</strong> <small>phần</small></span>
                                        </td>
                                        <td><span>{dateFormat(item.expiry_date)}</span></td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td colSpan={3}>
                                    <b>Tổng Cộng:</b>
                                </td>
                                <td className="text-left">
                                    <strong className={cx('total')}>{totalQuantity}</strong>
                                </td>
                                <td className="text-left">
                                    <strong className={cx('total')}>{totalPart}</strong>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default StockHistoryDetail;
