import classNames from 'classnames/bind';
import styles from '../Ingredient.module.scss';
import { formatPrice } from '~/utils/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import IngredientItem from './IngredientItem';
const cx = classNames.bind(styles);
function IngredientList({ datas, onUpdate, onRemove }) {
    return (
        <>
            <div className={cx('ingredients')}>
                <table>
                    <thead>
                        <tr>
                            <th>Tên Nguyên Liệu</th>
                            <th>Mô tả</th>
                            <th>Đơn Vị</th>
                            <th>DL</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas?.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <IngredientItem item={item} onUpdateItem={onUpdate} onRemoveItem={onRemove} />
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}
export default IngredientList;
