import { Fragment, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes, privateRoutes } from '~/routes';
import { DefaultLayout, AdminLayout } from '~/components/Layout';
import { AppProvider } from '~/context/AppContext';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './routes/ProtectedRoute';
function App() {
    console.log(process.env.REACT_APP_BASEURL);
    return (
        <>
            <AuthProvider>
                <Router>
                    <div className="App">
                        <Routes>
                            {publicRoutes.map((route, index) => {
                                let Layout = DefaultLayout;
                                if (route.layout) {
                                    Layout = route.layout;
                                } else if (route.layout === null) {
                                    Layout = Fragment;
                                }
                                const Page = route.component;
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={
                                            <AppProvider>
                                                <Layout>
                                                    <Page />
                                                </Layout>
                                            </AppProvider>
                                        }
                                    />
                                );
                            })}

                            {privateRoutes.map((route, index) => {
                                let Layout = AdminLayout;
                                if (route.layout) {
                                    Layout = route.layout;
                                } else if (route.layout === null) {
                                    Layout = Fragment;
                                }
                                const Page = route.component;
                                return (
                                    <Route key={index} element={<ProtectedRoute />}>
                                        <Route
                                            path={route.path}
                                            element={
                                                <Layout>
                                                    <Page />
                                                </Layout>
                                            }
                                        />
                                    </Route>
                                );
                            })}
                        </Routes>
                    </div>
                </Router>
            </AuthProvider>
        </>
    );
}

export default App;
