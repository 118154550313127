import NavAdmin from '~/components/NavAdmin';
import AdminHeader from '../components/AdminHeader';
function LoginLayout({ children }) {
    return (
        <div className="wrapper-login">
            <div className="container">
                <div className="content">{children}</div>
            </div>
        </div>
    );
}

export default LoginLayout;
