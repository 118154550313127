import classNames from 'classnames/bind';
import styles from './CategoryDetail.module.scss';
import * as adminServices from '~/apiServices/adminServices';
import { useEffect, useState } from 'react';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from '~/utils/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const cx = classNames.bind(styles);
function CategoryItem({ item, onSave, onRemove }) {
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState('');
    const [unit, setUnit] = useState('');
    const [order, setOrder] = useState(0);
    const [price, setPrice] = useState(0);
    const [displayPrice, setDisplayPrice] = useState('0k');
    useEffect(() => {
        setName(item.product_name);
        setUnit(item.product_unit);
        setOrder(item.product_order);
        setPrice(item.product_price);
        setDisplayPrice(item.display_price);
    }, []);
    const handleClickEdit = () => {
        setEdit(true);
    };
    const handleEditSave = () => {
        const apiSave = async () => {
            const res = await adminServices.updateProduct({
                action: 'product',
                id: item.product_id,
                name: name,
                unit: unit,
                order: order,
                price: price,
                display_price: displayPrice,
            });
            if (res.status) {
                const data = res.data;
                onSave(item.product_id, data);
                setEdit(false);
            } else {
                alert(res.message);
            }
        };
        apiSave();
    };
    const handleEditCancel = () => {
        setEdit(false);
    };

    const handleRemove = (item) => {
        if (confirm(`Bạn có chắc muốn xóa sản phẩm ${item.product_name} này?`)) {
            onRemove(item.product_id);
        }
    }
    return (
        <>
            {edit === true ? (
                <tr>
                    <td colSpan={6}>
                        <div className="edit-row">
                            <span className="col-auto">
                                <span className="small-label">Tên sản phẩm:</span>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </span>
                            <span className="col-auto">
                                <span className="small-label">Đơn vị:</span>
                                <input type="text" value={unit} onChange={(e) => setUnit(e.target.value)} />
                            </span>
                            <span className="col-auto">
                                <span className="small-label">Sắp xếp:</span>
                                <input type="number" value={order} onChange={(e) => setOrder(e.target.value)} />
                            </span>
                        </div>
                        <div className="edit-row">
                            <span className="col-auto">
                                <span className="small-label">Giá:</span>
                                <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
                            </span>
                            <span className="col-auto">
                                <span className="small-label">Giá hiển thị:</span>
                                <input
                                    type="text"
                                    value={displayPrice}
                                    onChange={(e) => setDisplayPrice(e.target.value)}
                                />
                            </span>
                            <button className="btn-save" onClick={handleEditSave}>
                                <span>Lưu</span>
                            </button>
                            <button className="btn-cancel" onClick={handleEditCancel}>
                                <span>Hủy</span>
                            </button>
                        </div>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td>
                        <strong className="name">{item.product_name}</strong>
                    </td>
                    <td className="text-center">
                        <span>{item.product_order}</span>
                    </td>
                    <td>
                        <span>{formatPrice(item.product_price)}</span>
                    </td>
                    <td>
                        <span>{item.display_price}</span>
                    </td>
                    <td className="text-center">
                        <span>{item.product_unit}</span>
                    </td>
                    <td>
                        <div className={cx('btn-wrapper')}>
                            <button
                                className={cx('btn-edit')}
                                onClick={handleClickEdit}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faEdit} />
                                </span>
                                <span> Chỉnh sửa</span>
                            </button>
                            <button
                                className={cx('danger')}
                                onClick={() => handleRemove(item)}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </span>
                                <span> Xóa</span>
                            </button>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}

export default CategoryItem;
