import classNames from 'classnames/bind';
import styles from '../../Camping.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import { CampingContext } from '~/context/CampingContext';

const cx = classNames.bind(styles);
function ServiceItem({ campIndex, item, onChange }) {
    const { camping, side } = useContext(CampingContext);
    const { select } = camping;

    const [quantity, setQuantity] = useState(item.quantity);

    useEffect(() => {
        if (select[campIndex]?.orders.some((obj) => obj.product_id === item.product_id)) {
            const p = select[campIndex]?.orders.find((obj) => obj.product_id === item.product_id);
            setQuantity(p.quantity);
        } else {
            setQuantity(0);
        }
    }, [side]);

    const handleMinus = (item) => {
        let newQuantity = quantity - 1;
        setQuantity(newQuantity);
        //item.quantity = newQuantity;
        onChange(item.product_id, newQuantity);
    };
    const handleAdd = (item) => {
        let newQuantity = quantity + 1;
        setQuantity(newQuantity);
        //item.quantity = newQuantity;
        onChange(item.product_id, newQuantity);
    };

    return (
        <>
            <tr className={cx('tr')} data-id={item.id}>
                <td width={150}>{item.product_name}</td>
                <td width={50} className="text-center">
                    <div className={cx('btn-group')}>
                        <button
                            onClick={() => handleMinus(item)}
                            className={cx('btn-minus', { show: quantity >= 0 })}
                            disabled={quantity === 0}
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <span className={cx('quantity', { show: quantity >= 0, red: quantity > 0 })}>{quantity}</span>
                        <button onClick={() => handleAdd(item)} className={cx('btn-add', { show: quantity >= 0 })}>
                            <FontAwesomeIcon icon={faAdd} />
                        </button>
                    </div>
                </td>
                <td width={150} className="text-right">
                    <span className={cx('price')}>{item.display_price}</span>
                </td>
            </tr>
        </>
    );
}

export default ServiceItem;
