import classNames from 'classnames/bind';
import styles from '../TableModal.module.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChildren, faPeopleGroup, faPhone, faSignature } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import * as tableServices from '~/apiServices/tableServices';
const cx = classNames.bind(styles);
function FormBbq({ table, onClose }) {
    const naviate = useNavigate();
    const [name, setName] = useState('Khách lẻ');
    const [phone, setPhone] = useState('0907891414');
    const [adt, setAdt] = useState(1);
    const [chd, setChd] = useState(0);

    const handleClickNewOrderBbq = () => {
        const apiAdd = async () => {
            const res = await tableServices.addCustomerBbq({
                table_id: Number(table.table_id),
                name: name,
                phone: phone,
                adt: adt,
                chd: chd,
            });
            if (res) {
                const bbqId = res.data;
                if (bbqId) naviate(`/table-detail/bbq/${table.table_id}/${bbqId}`);
            } else {
                alert(res.message);
            }
        };
        apiAdd();
    };
    return (
        <>
            <div className={cx('popup-head')}>
                <h3>Khách mới - BBQ</h3>
                <p>{table.table_name}</p>
            </div>
            <div className={cx('popup-form')}>
                <div className="form-row">
                    <label className="col">
                        <div className={cx('input-field', { unvalid: name.length === 0 })}>
                            <span className={cx('input-icon')}>
                                <FontAwesomeIcon icon={faSignature} />
                            </span>
                            <span className={cx('input-label')}>Họ và Tên:</span>
                            <span className={cx('input-value')}>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </span>
                        </div>
                    </label>
                </div>
                <div className="form-row">
                    <label className="col">
                        <div className={cx('input-field', { unvalid: phone.length < 10 })}>
                            <span className={cx('input-icon')}>
                                <FontAwesomeIcon icon={faPhone} />
                            </span>
                            <span className={cx('input-label')}>Số điện thoại:</span>
                            <span className={cx('input-value')}>
                                <input
                                    type="text"
                                    value={phone}
                                    maxLength={10}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </span>
                        </div>
                    </label>
                </div>
                <div className="form-row">
                    <label className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('input-icon')}>
                                <FontAwesomeIcon icon={faPeopleGroup} />
                            </span>
                            <span className={cx('input-label')}>Người lớn:</span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <input type="text" value={adt} onChange={(e) => setAdt(e.target.value)} />
                                </div>
                            </span>
                        </div>
                    </label>
                    <label className="col">
                        <div className={cx('input-field')}>
                            <span className={cx('input-icon')}>
                                <FontAwesomeIcon icon={faChildren} />
                            </span>
                            <span className={cx('input-label')}>Trẻ em:</span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <input type="text" value={chd} onChange={(e) => setChd(e.target.value)} />
                                </div>
                            </span>
                        </div>
                    </label>
                </div>
                <div className="form-row">
                    <div className="col">
                        <button className="btn-primary" onClick={handleClickNewOrderBbq}>
                            Thêm
                        </button>
                    </div>
                    <div className="col">
                        <button className="btn-default" onClick={onClose}>
                            Hủy
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormBbq;
