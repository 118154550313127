import classNames from 'classnames/bind';
import styles from './Ticket.module.scss';
import { dateNow, formatPrice, paymentName } from '~/utils/filters';
import {
    faCalendarDay,
    faMoneyBill1,
    faMoneyCheck,
    faTicket,
    faTrashCan,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as adminServices from '~/apiServices/adminServices';
import { useEffect, useState } from 'react';
import { TICKET_STATUS } from '~/utils/constants';
const cx = classNames.bind(styles);
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);
function Ticket() {
    const [date, setDate] = useState('');
    const [dataList, setDataList] = useState([]);
    const fetchApi = async () => {
        const response = await adminServices.getTickets();
        if (response) {
            setDataList(response);
        }
    };

    useEffect(() => {
        fetchApi();
    }, []);

    const handleDateChange = (date) => {
        setDate(date);
    };

    const handleSearch = () => {
        console.log(date);
        if (date === '' || null) return;
        const filterApi = async () => {
            const response = await adminServices.getTickets({
                params: {
                    date: moment(date).format('YYYY-MM-DD'),
                },
            });
            if (response) {
                setDataList(response);
            }
        };
        filterApi();
    };

    const removeTicket = (ticketId) => {
        const apiRemove = async () => {
            const res = await adminServices.removeTicket({
                ticket_id: ticketId,
            });
            if (res.status) {
                const newDataList = dataList.filter((obj) => {
                    return obj.ticket_id !== ticketId;
                });
                setDataList(newDataList);
            } else {
                alert(res.message);
            }
        };
        apiRemove();
    };

    const handleRemoveTicket = (ticketId) => {
        if (confirm(`Bạn có chắc muốn xóa vé ${ticketId} này?`)) {
            removeTicket(ticketId);
        }
    };
    return (
        <>
            <div className={cx('bbqs')}>
                <h2 className="page-title">
                    Danh sách vé <span>{dateNow()}</span>
                </h2>
                <div className={cx('bbq-content')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <div className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faCalendarDay} />
                                    </span>
                                    <span className={cx('input-label')}>Ngày mua vé:</span>
                                    <span className={cx('input-value')}>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={date}
                                            onChange={handleDateChange}
                                            placeholderText="Ngày mua vé"
                                            locale="vi"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            shouldCloseOnSelect={true}
                                        />
                                    </span>
                                </div>
                            </div>
                            <button className="btn-primary" onClick={handleSearch}>
                                Tìm
                            </button>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th className="text-center">Khách</th>
                                <th>Thông tin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList?.map((item, index) => {
                                return (
                                    <tr key={index} className={cx('tr-border')}>
                                        <td className="text-center">
                                            <div className={cx('ticket-number')}>
                                                <span className={cx('icon')}>
                                                    <FontAwesomeIcon icon={faUsers} />
                                                </span>
                                                <span className={cx('text')}>{item.ticket_number}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={cx('info')}>
                                                <div className={cx('data-group')}>
                                                    <div>
                                                        <strong>
                                                            {item.ticket_name} - {item.ticket_id}
                                                        </strong>
                                                        <p>{item.ticket_phone}</p>
                                                        <p>
                                                            <span className="label">
                                                                <FontAwesomeIcon icon={faMoneyBill1} />
                                                            </span>
                                                            <span style={{ color: '#ff5722' }}>
                                                                {formatPrice(item.ticket_price)}đ
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className="label">
                                                                <FontAwesomeIcon icon={faMoneyCheck} />
                                                            </span>
                                                            <span className={cx('payment', item.payment_method)}>
                                                                {paymentName(item.payment_method)}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className="label">
                                                                <FontAwesomeIcon icon={faTicket} />
                                                            </span>
                                                            <span className={cx('status', item.ticket_status)}>
                                                                {TICKET_STATUS[item.ticket_status]}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <span className={cx('datetime')}>
                                                        <span>{moment(item.created_at).format('DD-MM-YYYY')}</span>

                                                        <strong className={cx('price')}>
                                                            {formatPrice(item.ticket_number * item.ticket_price)}đ
                                                        </strong>
                                                        <button
                                                            className={cx('btn-remove')}
                                                            onClick={() => handleRemoveTicket(item.ticket_id)}
                                                        >
                                                            <span className="icon">
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </span>{' '}
                                                            Xóa
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Ticket;
