import classNames from 'classnames/bind';
import styles from './Store.module.scss';
import { dateFormat, statusReceipt, statusStock } from '~/utils/filters';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { useNavigate } from 'react-router-dom';
import StoreNav from './StoreNav';
const cx = classNames.bind(styles);
function ReceiptList() {
    const navigate = useNavigate();
    const [receipts, setReceipts] = useState([]);

    const fetchApis = async () => {
        const response = await adminServices.getReceipts();
        if (response) {
            setReceipts(response);
        }
    };
    useEffect(() => {
        fetchApis();
    }, []);

    const handleDetail = (detailId) => {
        navigate(`/super-admin/receipt/${detailId}`);
    }

    const totalPart = receipts.reduce((quantity, item) => quantity + item.quantity, 0);
    return (
        <>
            <StoreNav title="Phiếu nhập kho" />
            <div className={cx('admin-body')}>
                <div className={cx('header')}>
                    <h3>Phiếu nhập kho</h3>
                </div>
                <div className={cx('stores')}>
                    <table>
                        <thead className="sticky">
                            <tr>
                                <th className="text-center">STT</th>
                                <th>Ngày</th>
                                <th>Người đề xuất</th>
                                <th className="text-center">T.Thái</th>
                                <th className="text-center">S.Lượng</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {receipts?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td width={50} className="text-center">
                                            <span>{index + 1}</span>
                                        </td>
                                        <td width={150}>
                                            <span className="date">{dateFormat(item.date)}</span>
                                        </td>
                                        <td width={150}>
                                            <span>{item.proposer}</span>
                                        </td>
                                        <td width={150} className="text-center">
                                            <span><strong>{statusReceipt(item.status)}</strong></span>
                                        </td>
                                        <td width={150} className="text-center">
                                            <span><strong>{item.quantity}</strong></span>
                                        </td>
                                        <td>
                                            <div className="nav-row">
                                                <button className="btn btn-see-detail" onClick={() => handleDetail(item.receipt_id)}><span> Chi tiết</span></button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td colSpan={3}>
                                    <b>Tổng Cộng:</b>
                                </td>
                                <td className="text-center">
                                    <strong className={cx('total')}>{totalPart}</strong>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default ReceiptList;
