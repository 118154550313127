import * as request from '~/utils/request';

export const getCamps = async (params) => {
    try {
        const res = await request.get('camps/get_camps.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getCamp = async (params) => {
    try {
        const res = await request.get('camps/get_camp.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getOrders = async (params) => {
    try {
        const res = await request.get('camps/get_orders.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getOrder = async (params) => {
    try {
        const res = await request.get('camps/get_order.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const printBill = async (params) => {
    try {
        const res = await request.get('camps/print.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getCalendars = async (params) => {
    try {
        const res = await request.get('camps/get_calendars.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
