import classNames from 'classnames/bind';
import styles from '../../../Bill.module.scss';
import { formatPrice } from '~/utils/filters';
const cx = classNames.bind(styles);
function BillOrder({ orders }) {
    return (
        <>
            {orders?.map((order, index) => {
                const price =
                    order.bill_type === 'ticket' && order.customer_phone === null && order.active
                        ? order.ticket_price
                        : order.product_price;
                return (
                    <tr key={index}>
                        <td>
                            {order.product_name}
                            {order.active ? <> + vé</> : ''}
                            {order.customer_name ? (
                                <>
                                    <br></br>
                                    <span className={cx('name small')}>{order.customer_name}</span>
                                    {order.customer_phone ? (
                                        <>
                                            <span> - </span>
                                            <span className={cx('phone small')}>{order.customer_phone}</span>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </td>
                        <td>
                            <div className={cx('group-row')}>
                                <span className={cx('price')}>{formatPrice(price)}</span>
                                <span className={cx('mark')}>&nbsp; x</span>
                            </div>
                        </td>
                        <td className="text-center">
                            <span className={cx('quantity')}>{order.quantity}</span>
                        </td>
                        <td className="text-right">
                            <strong className={cx('price')}>{formatPrice(order.quantity * price)}</strong>
                        </td>
                    </tr>
                );
            })}
        </>
    );
}

export default BillOrder;
