import classNames from 'classnames/bind';
import styles from './Store.module.scss';
import { dateFormat, formatPrice, inventoryStock, statusExpiry } from '~/utils/filters';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import { useNavigate } from 'react-router-dom';
import StoreNav from './StoreNav';
const cx = classNames.bind(styles);
function Store() {
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);

    const fetchStores = async () => {
        const response = await adminServices.getStores();
        if (response) {
            setStores(response);
        }
    };
    useEffect(() => {
        fetchStores();
    }, []);

    const handleDetail = (detailId) => {
        navigate(`/super-admin/store/${detailId}`);
    }
    return (
        <>
            <StoreNav />
            <div className={cx('admin-body')}>
                <div className={cx('stores')}>
                    <table>
                        <thead className="sticky">
                            <tr>
                                <th className="text-center">STT</th>
                                <th>Tên Nguyên Liệu</th>
                                <th className="text-center">Đ.Lượng</th>
                                <th>Mô tả</th>
                                <th className="text-center">Tồn</th>
                                <th className="text-center">Trạng thái</th>
                                <th className="text-center">HSD</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stores?.map((item, index) => {
                                const inventory = item.stock;
                                var stockClass = '';
                                if (inventory <= 10 && inventory > 0) {
                                    stockClass = 'almost-stock';
                                } else if (inventory <= 0) {
                                    stockClass = 'out-stock';
                                }
                                return (
                                    <tr key={index} className={cx('stock', stockClass)}>
                                        <td width={50} className="text-center">{index + 1}</td>
                                        <td width={250}>{item.ingredient_name}</td>
                                        <td width={120}>
                                            <div className="table-row">
                                                <span>1 <small>{item.ingredient_unit}</small></span>
                                                <span>{item.ingredient_part}</span>
                                            </div>
                                        </td>
                                        <td>{item.ingredient_description}</td>
                                        <td width={100} className="text-center">
                                            <span><strong>{formatPrice(item.stock)}</strong></span>
                                        </td>
                                        <td width={100} className="text-center">
                                            <div>{inventoryStock(inventory)}</div>
                                        </td>
                                        <td width={100} className="text-center">
                                            <div>{dateFormat(item.expiry_date)}</div>
                                            <small>{statusExpiry(item.expiry_date)}</small>
                                        </td>
                                        <td width={150}>
                                            <div className="nav-row">
                                                <button className="btn btn-see-detail" onClick={() => handleDetail(item.ingredient_id)}><span> Chi tiết</span></button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Store;
