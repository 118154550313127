import classNames from 'classnames/bind';
import styles from './SellerCustomer.module.scss';
import { customerLabel, dateNow } from '~/utils/filters';
import { faCalendarDay, faSignature, faSquarePlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as adminServices from '~/apiServices/adminServices';
import { useEffect, useState } from 'react';
import Modal from '~/components/Modal';
const cx = classNames.bind(styles);
function SellerCustomer() {
    const [show, setShow] = useState(false);
    const [q, setQ] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [dataList, setDataList] = useState([]);
    const fetchApi = async () => {
        const response = await adminServices.getCustomers({
            params: {
                type: 'seller',
            },
        });
        if (response) {
            setDataList(response);
        }
    };

    useEffect(() => {
        fetchApi();
    }, []);

    const handleSearch = () => {
        if (q === '' || null) return;
        const filterApi = async () => {
            const response = await adminServices.getCustomers({
                params: {
                    type: 'seller',
                    q: q,
                },
            });
            if (response) {
                setDataList(response);
            }
        };
        filterApi();
    };

    const removeCustomer = (customerId) => {
        const removeApi = async () => {
            const res = await adminServices.removeCustomer({
                customer_id: customerId,
            });
            if (res.status) {
                const newDataList = dataList.filter((obj) => {
                    return obj.customer_id !== customerId;
                });
                setDataList(newDataList);
            } else {
                alert(res.message);
            }
        };
        removeApi();
    };

    const handleRemoveCustomer = (customer) => {
        if (confirm(`Bạn có chắc muốn xóa khách hàng ${customer.customer_name} - ${customer.customer_phone} này?`)) {
            removeCustomer(customer.customer_id);
        }
    };

    const handleShowModal = (e) => {
        setShow(true);
    };

    const handleAddSellerCustomer = () => {
        const addApi = async () => {
            const res = await adminServices.addCustomer({
                type: 'seller',
                name: name,
                phone: phone,
            });
            if (res.status) {
                const data = res.data;
                setDataList([...data, ...dataList]);
                setShow(false);
                setName('');
                setPhone('');
            } else {
                alert(res.message);
            }
        };
        addApi();
    };
    return (
        <>
            <div className={cx('customers')}>
                <h2 className="page-title">
                    <div>
                        <strong>Danh sách Seller</strong>
                        <button className={cx('btn-add-new')} onClick={handleShowModal}>
                            <FontAwesomeIcon icon={faSquarePlus} />
                        </button>
                    </div>
                    <span>{dateNow()}</span>
                </h2>
                <Modal title={`Thêm khách hàng`} show={show} setShow={setShow}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <div className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faSignature} />
                                    </span>
                                    <span className={cx('input-label')}>Họ và Tên:</span>
                                    <span className={cx('input-value')}>
                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faCalendarDay} />
                                    </span>
                                    <span className={cx('input-label')}>SĐT:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="text"
                                            value={phone}
                                            maxLength={10}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <button className="btn-primary" onClick={handleAddSellerCustomer}>
                                    Thêm Seller
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className={cx('bbq-content')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <div className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faCalendarDay} />
                                    </span>
                                    <span className={cx('input-label')}>Từ khóa:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="text"
                                            value={q}
                                            maxLength={10}
                                            onChange={(e) => setQ(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </div>
                            <button className="btn-primary" onClick={handleSearch}>
                                Tìm
                            </button>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th className="text-center">ID</th>
                                <th>TÊN</th>
                                <th>SĐT</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList?.map((item, index) => {
                                return (
                                    <tr key={index} className={cx('tr-border')}>
                                        <td className="text-center">{item.customer_id}</td>
                                        <td>
                                            <strong className={cx('name')}>{item.customer_name}</strong>

                                            <p className={cx('type')}>{customerLabel(item.customer_type)}</p>
                                        </td>
                                        <td>
                                            <strong className={cx('phone')}>{item.customer_phone}</strong>
                                        </td>
                                        <td>
                                            <button
                                                className={cx('btn-remove')}
                                                onClick={() => handleRemoveCustomer(item)}
                                            >
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </span>
                                                <span> Xóa</span>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default SellerCustomer;
