import classNames from 'classnames/bind';
import styles from './Receipt.module.scss';
const cx = classNames.bind(styles);
import { useContext } from 'react';
import SelectIngredient from './SelectIngredient';
import EnterQuantity from './EnterQuantity';
import ReceiptConfirm from './ReceiptConfirm';
import { ReceiptContext } from './Receipt';
import EnterInfo from './EnterInfo';

function ReceiptContent() {
    const { step } = useContext(ReceiptContext);

    const renderHtml = () => {
        if (step === 1) {
            return <SelectIngredient />;
        } else if (step === 2) {
            return (
                <>
                    <EnterQuantity />
                </>
            );
        } else if (step === 3) {
            return (
                <>
                    <EnterInfo />
                </>
            );
        } else if (step === 4) {
            return (
                <>
                    <ReceiptConfirm />
                </>
            );
        } else {
            return '';
        }
    };
    return (
        <>
            <div className={cx('bbq-content')}>{renderHtml()}</div>
        </>
    );
}

export default ReceiptContent;
